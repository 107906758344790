import API from "../services/index";
import axios from "axios";
// export const callServer = process.env.REACT_APP_API_URL;
export const callServer = "https://smartretailmedia.es/api/v1";
//export const callServer = "http://localhost:4000/api/v1"; 
export const postProfileSelector = (body) => {
  return postCall(`${callServer}/profile_selector`, body)
}
export const loginStatus = () => {
  return getCall(`${callServer}/login/status`);
};
export const registerUser = (form) => {
  return postCall(`${callServer}/access_request`, form);
};
export const getOverview = () => {
  return getCall(`${callServer}/overview`);
};
export const getRetailersUsers = ({ idRetailer }) => {
  return getCall(`${callServer}/retiler/${idRetailer}/users`);
};
export const getAllBrands = () => {
  return getCall(`${callServer}/brands`);
};
export const getBrand = (id) => {
  return getCall(`${callServer}/brand/${id}`);
};
export const postBrand = (body) => {
  return postCall(`${callServer}/brand`, body);
};
export const patchBrand = (id, body) => {
  return patchCall(`${callServer}/brand/${id}`, body);
};
export const patchCancelNegotiation = (id, body) => {
  return patchCall(`${callServer}/negotiation/${id}`, body);
};
// Unify this call with getBrand and account ?
export const getRetailer = (id) => {
  return getCall(`${callServer}/retailer/${id}`);
};
export const getRetailers = () => {
  return getCall(`${callServer}/retailers`);
};
// Implement this for two previous
export const getAccountOrRetailer = (type, id) => {
  return getCall(`${callServer}/${type}/${id}`);
};
export const getAllGroups = () => {
  return getCall(`${callServer}/group`);
};
export const getAllRoles = () => {
  return getCall(`${callServer}/roles`);
};
export const getNegotiation = (id) => {
  return getCall(`${callServer}/negotiation/${id}`);
}
export const getNegotiations = (type, id) => {
  return getCall(`${callServer}/${type}/${id}/negotiations`);
}
export const getReporting = (user_id, account_id = undefined, brand_id = undefined, retailer_id = undefined) => {
  let url = `${callServer}/reporting?user_id=${user_id}`
  if (account_id !== undefined) {
    url += `&account_id=${account_id}`
  }
  if (brand_id !== undefined) {
    url += `&brand_id=${brand_id}`
  }
  if (retailer_id !== undefined) {
    url += `&retailer_id=${retailer_id}`
  }
  return getCall(url);
}
export const postSendOauthLogin = (body) => {
  return postCall(`${callServer}/oauth/login`, body);
};
export const getBrandsByAccount = (accountId) => {
  return getCall(`${callServer}/account/${accountId}/brands`);
}
export const postSendOauthUrl = (body) => {
  return postCall(`${callServer}/oauth/auth_url`, body);
};
export const getInventory = (id) => {
  return getCall(`${callServer}/retailer/${id}/inventory`);
};
export const postInventory = (id) => {
  return postCall(`${callServer}/retailer/${id}/inventory`);
};
export const patchInventory = (id, body) => {
  return patchCall(`${callServer}/inventory/${id}`, body);
};
export const getExternalConnectionInventory = (id) => {
  return getCall(`${callServer}/external_connection/${id}/inventory`);
};
export const getConnections = (type, id) => {
  return getCall(`${callServer}/${type}/${id}/connections`);
};
export const getExternalUsers = (type, id) => {
  return getCall(`${callServer}/${type}/${id}/connections`);
};
export const getExternalConnectionUsers = (id) => {
  return getCall(`${callServer}/external_connection/${id}/external_users`);
};
export const getExternalConnectionAvailableIds = (id) => {
  return getCall(`${callServer}/external_connection/${id}/available_ids`);
};
export const getExternalConnections = (type, id) => {
  return getCall(`${callServer}/${type}/${id}/external_connections`);
};
export const postNegotiation = async (body) => {
  const externalConnection = await getExternalConnections("retailer", body.retailer_id)
  body.retailer_external_connection_id = externalConnection.data[0].id

  return postCall(`${callServer}/negotiation`, body);
};
export const patchExternalConnections = (id, body) => {
  return patchCall(`${callServer}/external_connection/${id}`, body);
};
export const getUser = (id) => {
  return getCall(`${callServer}/user/${id}`);
};
export const postUser = (body) => {
  return postCall(`${callServer}/user`, body);
};
export const patchUser = (id, body) => {
  return patchCall(`${callServer}/user/${id}`, body);
};
export const getUsers = (type, id) => {
  return getCall(`${callServer}/${type}/${id}/users`);
};
export const getGroups = (type, id) => {
  return getCall(`${callServer}/${type}/${id}/groups`);
};
export const getAccounts = () => {

  return getCall(`${callServer}/accounts`);
};
export const patchConnections = (id, new_status) => {
  return patchCall(`${callServer}/connection/${id}`, { status: new_status });
};
export const patchVersion = (negotiationId, versionId, newStatus) => {
  return patchCall(`${callServer}/negotiation/${negotiationId}/version/${versionId}`, { status: newStatus });
};
export const deleteConnection = (id) => {
  return deleteCall(`${callServer}/connection/${id}`);
};
export const postConnections = (connection) => {
  return postCall(`${callServer}/connection`, connection)
}

export const getNotifications = (userId, read = "") => {

  return getCall(`${callServer}/user/${userId}/notifications${read}`);
};
export const patchNegotiation = (id, body) => {
  return patchCall(`${callServer}/negotiation/${id}`, body);
};
export const patchNotification = (userId, body) => {
  return patchCall(`${callServer}/user/${userId}/notifications`, body);
};

export const deleteNotofications = (array) => {
  let params = "?ids=";
  array.forEach(x => {
    params += `${x}%2C`
  });

  return deleteCall(`${callServer}/notifications${params.slice(0, -3)}`);
};

export const postNegotiationForm = (negotiationId, form) => {
  let valid = true;
  // const formData = new FormData(form);

  // clean fields if necessary
  // const data = Object.fromEntries(formData)

  for (const property in form) {
    if (property !== "message" && (!form[property] || form[property].length === 0)) valid = false;
  }

  if (!valid) {
    for (const property in form) {
      if (property !== "message") form[property] = null
    }
  }

  const type = !!valid ? "PROPOSAL" : "MESSAGE";

  let payload;
  if (!!valid) {
    payload = {
      message: form.message,
      type: type,
      version: {
        status: "PENDING",
        start_date: form.start_date,
        end_date: form.end_date,
        rate: Number(form.rate),
        quantity: Number(form.quantity),
        budget: Number(form.budget),
        creative_sizes: form.creative_sizes,
        inventory: form.inventory
      }
    }
  } else {
    payload = {
      message: form.message,
      type: type,
      version: {
        status: "PENDING",
        start_date: "1990-04-11",
        end_date: "1990-04-11",
        rate: 0,
        quantity: 0,
        budget: 0,
        creative_sizes: [],
        inventory: []
      }
    }
  }

  return postCall(
    `${callServer}/negotiation/${negotiationId}/message`,
    payload
  );
};

export const getProposal = (id) => {
  return getCall(`${callServer}/proposal/${id}`)
};
export const getProposals = (type, id) => {
  return getCall(`${callServer}/${type}/${id}/proposals`)
};

export const postProposals = (proposal) => {
  return postCall(`${callServer}/proposal`, proposal)
};

const getAxios = (srmToken) => {
  return axios.create({
    headers: {
      "Content-Type": "application/json",
      'Authorization': `Bearer ${srmToken} ? ${srmToken} : ""}`,
      // "Cookie": "connect.sid=s%3AQjoQwp97Bh9vQFpqNDn-YA_OQcdkSKGk.DqiKyuZ2P3jK1l9qaqTPu5rhoZk1VrndR7b2yK4tPs4",
    },
  })
}

const getCall = async (url, config) => {
  try {
    const response = await getAxios(localStorage.getItem("srmToken")).get(url, config);
    return response;
  } catch (err) {
    throw new Error(err);
  }
};

const postCall = async (url, body) => {
  try {
    const response = await getAxios(localStorage.getItem("srmToken")).post(url, body);
    return response;
  } catch (err) {
    throw new Error(err);
  }
};

const patchCall = async (url, body) => {
  try {
    const response = await getAxios(localStorage.getItem("srmToken")).patch(url, body, {
      withCredentials: true
    });

    return response;
  } catch (err) {
    throw new Error(err);
  }
};

const deleteCall = async (url, params) => {
  try {
    const response = await getAxios(localStorage.getItem("srmToken")).delete(url, {
      params: params,
      withCredentials: true
    });
    return response;
  } catch (err) {
    throw new Error(err);
  }
};
