import { useState, useEffect } from "react";
import styles from './negotiation_final.module.css'
import { Button, Form, Row, Col, Spinner } from 'react-bootstrap';
import globals from "config/config";
import Select from 'react-select'
import { useNavigate, useParams } from "react-router-dom";
import { getExternalConnectionInventory, getNegotiation, getProposal } from "../../../services/services";
import { useSelector } from "react-redux";
import { formatDateForNegotiationFinalConfigurationPage } from "utils/utils";

const CurrentNegForm = () => {
  const { id } = useParams();
  let navigate = useNavigate();
  const user = useSelector(state => state.authUser);
  const [loading, setLoading] = useState(false);

  const [form, setForm] = useState({
    start_date: "oooooooooooooooooooooooooooooooo",
    end_date: "oooooooooooooooooooooooooooooooo",
    creatives: [],
    inventory: []
  });

  const fetchData = async () => {
    setLoading(true)

    try {
      let response = await getProposal(id);
      const proposal = response.data;
      response = await getNegotiation(proposal.negotiation_id);
      const negotiation = response.data;

      const approvedMessage = negotiation.messages.filter(x => x.version.status === "APPROVED")[0]

      const inventorySelector = approvedMessage.version.inventory.map(x => {
        return {
          value: x.inventory_id,
          label: x.inventory_name
        }
      })
      const creativesSelector = approvedMessage.version.creative_sizes.map(x => {
        return {
          value: x,
          label: x
        }
      })

      const initialForm = {
        negotiation_id: approvedMessage.negotiation_id,
        advertiser: negotiation.brand_name,
        retailer: negotiation.retailer_name,
        negotiation_version: approvedMessage.negotiation_version,
        title: user.type === "advertiser" ? negotiation.brand_title : negotiation.retailer_title,
        proposal_name: user.type === "advertiser" ? proposal.brand_proposal_name : proposal.retailer_proposal_name,
        status: approvedMessage.version.status,
        seller_contact_email: proposal.seller_contact_email,
        sales_person_email: proposal.sales_person_email,
        trafficker_email: proposal.trafficker_email,
        priority: proposal.priority,
        priority_value: proposal.priority_value,
        start_date: approvedMessage.version.start_date,
        end_date: approvedMessage.version.end_date,
        rate: approvedMessage.version.rate,
        quantity: approvedMessage.version.quantity,
        budget: approvedMessage.version.budget,
        creative_sizes: approvedMessage.version.creative_sizes,
        url: negotiation.url,
        retailer_internal_notes: negotiation.retailer_internal_notes,
        brand_internal_notes: negotiation.brand_internal_notes,
        external_id: negotiation.retailer_external_connection_id, // TODO define this
        inventory: inventorySelector,
        creatives: creativesSelector,
        gam_proposal_name: proposal.gam_proposal_name,
        gam_proposal_lineitem_name: proposal.gam_proposal_lineitem_name
      }

      setForm(initialForm);
      setLoading(false)
    } catch (error) {
      console.log(error);
    } 
  }

  useEffect(() => {
    fetchData();
  }, [user])

  return (
    <>
      {!!loading ? (
        <div className="spinner-container spinner-container-big">
          <Spinner className="spinner-big" />
        </div>
      ) : (
        <>
          <h1 className={styles.header_create}></h1>
          <Form className={styles.form}>
            <Row className="mb-3 mr-3">
              <Col className={styles.item} md={6}>
                <Form.Group controlId="validationCustom01">
                  <Form.Label className={styles.label_create} >RFP Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="title"
                    value={form?.title}
                    disabled
                  />
                </Form.Group>
              </Col>

              <Col className={styles.item_small} md={3} >

                <Form.Group controlId="validationCustom02">
                  <Form.Label className={styles.label_create} >Start Date</Form.Label>
                  <Form.Control
                    type="text"
                    name="version.start_date"
                    value={formatDateForNegotiationFinalConfigurationPage(form?.start_date)}
                    disabled
                  />
                </Form.Group>
              </Col>
              <Col className={styles.item_small} md={3} >
                <Form.Group controlId="validationCustom03">
                  <Form.Label className={styles.label_create} >End Date</Form.Label>
                  <Form.Control
                    type="text"
                    name="version.end_date"
                    value={formatDateForNegotiationFinalConfigurationPage(form?.end_date)}
                    disabled
                  />
                </Form.Group>
              </Col>

            </Row>

            <Row className="mb-3 mr-3">
              <Col className={styles.item} md={6}>
                <Form.Group controlId="validationCustom04">
                  <Form.Label className={styles.label_create} >Advertiser</Form.Label>
                  <Form.Control
                    type="text"
                    name="advertiser"
                    value={form?.advertiser}
                    disabled
                  />
                </Form.Group>
              </Col>
              <Col className={styles.item} md={6}>
                <Form.Group controlId="validationCustom05">
                  <Form.Label className={styles.label_create}>Inventario</Form.Label>
                  <Select
                    name="inventory"
                    value={form?.inventory}
                    isMulti
                    isDisabled={true}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-3 mr-3">
              <Col className={styles.item} md={6}>
                <Form.Group controlId="validationCustom06">
                  <Form.Label className={styles.label_create} >Retailer</Form.Label>
                  <Form.Control
                    type="text"
                    name="retailer"
                    value={form?.retailer}
                    disabled
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-3 mr-3">
              <Col className={styles.item} md={6}>
                {/* <Form.Group controlId="validationCustom07">
              <Form.Label className={styles.label_create} >Buyer</Form.Label>
              <Form.Select aria-label="Default select example"
                name="buyer"
                disabled
              >
                <option value="none" selected disabled>definir este dato</option>
              </Form.Select>
            </Form.Group> */}
              </Col>
              <Col className={styles.item_small} md={3}>
                <Form.Group controlId="validationCustom08">
                  <Form.Label className={styles.label_create} >Budget</Form.Label>
                  <Form.Control
                    type="text"
                    name="version.budget"
                    disabled
                    value={form?.budget}

                  />
                </Form.Group>
              </Col>
              <Col className={styles.item_small} md={3}>
                <Form.Group controlId="validationCustom09">
                  <Form.Label className={styles.label_create} >Rate(CPM - opcional)</Form.Label>
                  <Form.Control
                    type="text"
                    name="version.rate"
                    disabled
                    value={form?.rate}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-3 mr-3">
              <Col className={styles.item} md={6}>
                <Form.Group controlId="validationCustom13">
                  <Form.Label className={styles.label_create} >URL</Form.Label>
                  <Form.Control
                    type="text"
                    name="version.url"
                    value={form?.url}
                    disabled
                  />
                </Form.Group>
              </Col>
              <Col className={styles.item_small} md={3}>
                <Form.Group controlId="validationCustom11">
                  <Form.Label className={styles.label_create} >Quantity</Form.Label>
                  <Form.Control
                    type="text"
                    name="version.quantity"
                    value={form?.quantity}
                    disabled
                  />
                </Form.Group>
              </Col>
              <Col className={styles.item_small} md={3}>
                <Form.Group controlId="validationCustom12">
                  <Form.Label className={styles.label_create}>Creatives</Form.Label>
                  <Select
                    isDisabled
                    isMulti
                    isClearable={false}
                    name="version.creatives"
                    value={form?.creatives}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-3 mr-3">
              <Col className={styles.item} md={6}>
                <Form.Group controlId="validationCustom10">
                  <Form.Label className={styles.label_create} >Seller contact</Form.Label>
                  <Form.Control
                    type="text"
                    name="seller_contact"
                    disabled
                    value={form?.seller_contact_email}
                  />
                </Form.Group>
                <Form.Group style={{ 'margin-top': '1rem' }} controlId="validationCustom15">
                  <Form.Label className={styles.label_create} >Sales person</Form.Label>
                  <Form.Control
                    type="text"
                    name="sales_person"
                    value={form?.sales_person_email}
                    disabled
                  />
                </Form.Group>
              </Col>
              <Col className={styles.notes} md={6}>
                <Form.Group controlId="validationCustom14">
                  <Form.Label className={styles.label_create} >Notas internas (opcional)</Form.Label>
                  <textarea
                    class="form-control"
                    rows="6"
                    disabled
                    name={user.type === "advertiser" ? "brand_internal_notes" : "retailer_internal_notes"}
                    value={user.type === "advertiser" ? `${form?.brand_internal_notes}` : `${form?.retailer_internal_notes}`}
                  ></textarea>
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3 mr-3">
              <Col className={styles.item} md={6}>
                <Form.Group controlId="validationCustom15">
                  <Form.Label className={styles.label_create} >Trafficker</Form.Label>
                  <Form.Control
                    type="text"
                    name="trafficker"
                    value={form?.trafficker_email}
                    disabled
                  />
                </Form.Group>
              </Col>
            </Row>
            {user.type === "retailer" ? <>
              <Row className="mb-3 mr-3">
                <Col className={styles.item} md={6}>

                </Col>
                <Col className={styles.item} md={6}>
                  <Form.Group controlId="validationCustom18">
                    <Form.Label className={styles.label_create} >Proposal Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="proposal_name"
                      disabled
                      value={form?.gam_proposal_name}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3 mr-3">
                <Col className={styles.item} md={6}></Col>
                <Col className={styles.item} md={6}>
                  <Form.Group controlId="validationCustom20">
                    <Form.Label className={styles.label_create} >Proposal line item name (Opcional)</Form.Label>
                    <Form.Control
                      type="text"
                      name="gam_proposal_lineitem_name"
                      value={form?.gam_proposal_lineitem_name}
                      disabled
                    />
                    <Form.Control.Feedback type="invalid">
                      Por favor introduzca un valor
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3 mr-3">
                <Col className={styles.item} md={6}></Col>
                <Col className={styles.item} md={6}>
                  <Form.Group controlId="validationCustom20">
                    <Form.Label className={styles.label_create} >Line Item Priority</Form.Label>
                    <Form.Control
                      type="text"
                      name="line_item_priority"
                      value={form?.priority}
                      disabled
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3 mr-3">
                <Col className={styles.item} md={6}></Col>
                <Col className={styles.item} md={6}>
                  <Form.Group controlId="validationCustom21">
                    <Form.Label className={styles.label_create} >Line Item Priority value</Form.Label>
                    <Form.Control
                      type="text"
                      name="line_item_priority_value"
                      value={form?.priority_value}
                      disabled
                    />
                  </Form.Group>
                </Col>
              </Row>
              {/* <Row className="mb-3 mr-3">
            <Col className={styles.item} md={6}></Col>
            <Col className={styles.item} md={6}>
              <Form.Group controlId="validationCustom22">
                <Form.Label className={styles.label_create} >Labels de GAM</Form.Label>
                <Form.Select aria-label="Default select example"
                  name="GAM_labels"
                  disabled
                >
                  <option value="none" selected disabled hidden></option>
                  {GAM_labels.map((e, key) => {
                    return <option key={key} value={e.id}>{e.name}</option>;
                  })}
                </Form.Select>
              </Form.Group>
            </Col>
          </Row> */}

            </> : ""}
            <div className={styles.buttons}>
              <Button
                onClick={() => navigate("/operacion/propuestas/lista")}
                className={styles.button_start}
              >Volver</Button>

            </div>
          </Form>

        </>
      )
      }
    </>
  )
}

export default CurrentNegForm