import React, { useState, useEffect } from "react";
import TableList from "components/Table/TableList";
import NavBar from "../../../components/NavBar/NavBar";
import Sidebar from "../../../components/SideBar/SideBar"
import { AccessesSideBar } from "../../../config/sidebar_types";
import dataText from "../../../config/DataText";
import Search from "../../../components/Search/Search";
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';
import Swal from "sweetalert2";
import { getGroups } from "services/services";
import { useSelector } from "react-redux";

const ListPage = () => {
  const [listData, setListData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [loading, setLoading] = useState(false);
  const user = useSelector(state => state.authUser);

  let navigate = useNavigate();
  const menu = AccessesSideBar
  const fieldsToSearch = ["group", "rol", "usuarios", "creado"];

  const rowHeader = [
    {
      header: dataText.group,
      key: "group",
      type: "text",
    },
    {
      header: dataText.rol,
      key: "rol",
      type: "text",
    },
    {
      header: dataText.usuarios,
      key: "usuarios",
      type: "number",
    },
    {
      header: dataText.creado,
      key: "creado",
      type: "text",
    },
    {
      header: "",
      key: "actions",
      type: "actions",
      actions: ["delete", "edit"],
    },
  ];

  const fetchList = async () => {
    setLoading(true)
    const type =
      user.activeProfile.brand_id
        ? "brand"
        : user.activeProfile.account_id
          ? "account" : "retailer";

    const id =
      user.activeProfile.brand_id
        ? user.activeProfile.brand_id
        : user.activeProfile.account_id
          ? user.activeProfile.account_id : user.activeProfile.retailer_id;

    try {

      const { data } = await getGroups(type, id)

      setListData(data);
      setAllData(data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchList();
  }, [user])

  const handleEdit = (id) => {
    navigate(`/accesos/grupos/lista/editar_grupo/${id}`);
  };

  const handleDelete = (id) => {
    Swal.fire({
      title: "Eliminar grupo",
      text: "Esto eliminará el grupo, no los usaurios. Tendrá que definir especificamente los roles para cada usuario",
      icon: "",
      showCancelButton: true,
      allowEscapeKey: true,
      allowOutsideClick: true,

      cancelButtonColor: "#d20631",
      backdrop: true
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: `Usuario ${id} borrado`,
          text: "Your data has been deleted.",
          icon: "success",
          backdrop: true
        });
      } else {
        return
      }
    });
  };

  return (
    <div style={{ height: '100%' }}>
      <NavBar />

      <div style={{ display: 'flex', height: 'calc(100% - 98px)' }}>
        <Sidebar page={"Groups_List"} header={"Accesos"} content={menu} />


        <div style={{ 'overflowY': 'auto', backgroundColor: '#FFFFFF', width: '100%', margin: "52px 0" }}>
          <div className="new_element">
            <div className="d-flex justify-content-between">
              <Search
                allData={allData}
                setListData={setListData}
                fieldsToSearch={fieldsToSearch}
              />
              <Button onClick={() => navigate('/accesos/grupos/lista/crear_grupo')} style={{ background: "#001834", color: "white" }}>{dataText.crear_grupo}</Button>
            </div>
          </div>
          <TableList
            loading={loading}
            rowsData={listData}
            rowHeader={rowHeader}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
            fetchList={fetchList}
          />
        </div>
      </div>


    </div>
  )
}

export default ListPage