import { createSlice } from "@reduxjs/toolkit";
import { initialFormState } from "services/negotiationServices";
import { negotiation_mock } from "mocks/negotiation";

const initialState = {
  srmToken: null,
  authUser: null,
  // userProfile: null,
  // retailerId: null,
  // accountId: null,
  connections: [],
  // notifications: [],
  showNotificationsAlert: false,
  updateNotifications: true,
  isDv360Authenticated: false,
  isAdManagerAuthenticated: false,
  googleApiAuthenticated: false,
  // initial state form in negotiation chat
  chatNegotiationFormData: initialFormState,
  // TODO drop the mocks
  negotiation: negotiation_mock,
  // initial state for messages array in chat container
  chatMessages: [
    {
      id: Math.floor(Math.random() * 1000),
      message: "Loading messages..",
      type: "system",
      created_date: "",
      origin: "",
      version: {
        version: "1",
        status: "",
        start_date: "2023-04-01",
        end_date: "2023-07-01",
        rate: 0,
        quantity: 0,
        budget: 0,
        creative_sizes: ["320x480", "32x48"],
        url: "https://test.com",
        inventory: [
          {
            id: 0,
            inventory_name: "Yogurt"
          }
        ]
      }
    }
  ],
  paginationCurrentPage: 1,
  updateCheckAll: false,
  sidebarMenu: {}
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setSrmToken: (state, action) => {
      state.srmToken = action.payload;
    },
    setAuthUser: (state, action) => {
      state.authUser = action.payload;
    },
    // setUserProfile: (state, action) => {
    //   state.userProfile = action.payload;
    //   localStorage.setItem('userProfile', JSON.stringify(action.payload))
    // },
    // setRetailerId: (state, action) => {
    //   state.retailerId = action.payload;
    // },
    // setAccountId: (state, action) => {
    //   state.accountId = action.payload;
    // },
    setIsDv360Authenticated: (state, action) => {
      state.isDv360Authenticated = action.payload;
    },
    setIsAdManagerAuthenticated: (state, action) => {
      state.isAdManagerAuthenticated = action.payload;
    },
    setGoogleApiAuthenticated: (state, action) => {
      state.googleApiAuthenticated = action.payload;
    },
    setConnections: (state, action) => {
      state.connections = action.payload;
    },
    // setNotifications: (state, action) => {
    //   state.notifications = action.payload;
    // },
    setShowNotificationsAlert: (state, action) => {
      state.showNotificationsAlert = action.payload;
    },
    setUpdateNotifications: (state, action) => {
      state.updateNotifications = action.payload;
    },
    setNegotiation: (state, action) => {
      state.negotiation = action.payload;
    },
    setChatNegotiationFormData: (state, action) => {
      state.chatNegotiationFormData = action.payload;
    },
    setChatMessages: (state, action) => {
      state.chatMessages = action.payload;
    },
    setPaginationCurrentPage: (state, action) => {
      state.paginationCurrentPage = action.payload;
    },
    setUpdateCheckAll: (state, action) => {
      state.updateCheckAll = action.payload;
    },
    setSidebarMenu: (state, action) => {
      state.sidebarMenu = action.payload;
    },
  }
});

export const {
  setSrmToken,
  setAuthUser,
  // setUserProfile,
  // setRetailerId,
  // setAccountId,
  setIsDv360Authenticated,
  setIsAdManagerAuthenticated,
  setGoogleApiAuthenticated,
  setNegotiation,
  setConnections,
  // setNotifications,
  setShowNotificationsAlert,
  setUpdateNotifications,
  setChatNegotiationFormData,
  setChatMessages,
  setPaginationCurrentPage,
  setUpdateCheckAll,
  setSidebarMenu
} = appSlice.actions;

export default appSlice.reducer;