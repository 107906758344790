import { OperationsSideBar } from "../../../config/sidebar_types";
import React, { useState, useEffect } from "react";
import TableList from "components/Table/TableList";
import NavBar from "../../../components/NavBar/NavBar";
import Sidebar from "../../../components/SideBar/SideBar"
import dataText from "../../../config/DataText";
import Search from "../../../components/Search/Search";
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';
import { RxDownload } from "react-icons/rx";
import Swal from "sweetalert2";
import { getNegotiations } from "services/services";
import { useSelector } from "react-redux";
import { formatDate } from "utils/utils";

const ListPage = () => {
  const [listData, setListData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [loading, setLoading] = useState(false);
  const user = useSelector(state => state.authUser);

  let navigate = useNavigate()
  const fieldsToSearch = ["id", "brandName", "retailerName", "rfpName", "status", "volumen", "rate", "last_modified_date"];
  const menu = OperationsSideBar

  let rowHeader = [
    {
      header: dataText.negociaciones_id,
      key: "id",
      type: "number",
    },
    {
      header: dataText.brand_DV,
      key: "brandName",
      type: "text",
    },
    {
      header: dataText.retailer,
      key: "retailerName",
      type: "text",
    },
    {
      header: dataText.rfp_name,
      key: "rfpName",
      type: "text",
    },
    {
      header: dataText.status,
      key: "status",
      type: "text",
    },
    {
      header: dataText.volumen,
      key: "volumen",
      type: "number",
    },
    {
      header: dataText.rate_cmp,
      key: "rate",
      type: "number",
    },
    {
      header: dataText.last_modificacation,
      key: "last_modified_date",
      type: "text",
    },
    {
      header: ``,
      key: "actions",
      type: "actions",
      actions: ["edit"],
    },
  ];

  // hide retailer column in table when user.type === retailer
  if (user.type === "retailer") rowHeader = rowHeader.filter(x => x.key !== "retailerName");

  const fetchList = async () => {
    setLoading(true)
    const type = user.type === "retailer"
      ? 'retailer'
      : user.type === "advertiser"
        ? 'brand'
        : null;

    const id = user.activeProfile.brand_id ? user.activeProfile.brand_id : user.activeProfile.retailer_id;
    try {
      const response = await getNegotiations(type, id);

      const data = response.data.map(x => {
        const negotiation = {
          id: x.id,
          // TODO update this ids by name
          brandName: x.brand_name,
          retailerName: x.retailer_name,
          // TODO confirm this specification
          rfpName: x.brand_title ? x.brand_title : x.retailer_title,
          status: x.last_version.status,
          volumen: x.last_version.quantity,
          rate: x.last_version.rate,
          last_modified_date: formatDate(x.last_modified_date)
        };

        return negotiation
      });


      setListData(data);
      setAllData(data);
    } catch (error) {
      console.log(error);
    } finally {
      // setListData(res.data);
      // setAllData(res.data);
      setLoading(false)
    }

  }

  useEffect(() => {
    fetchList();
  }, [user])

  const handleEdit = (id) => {
    navigate(`/operacion/negociaciones/chat/${id}`);
  };

  return (
    <div style={{ height: '100%' }}>
      <NavBar />

      <div style={{ display: 'flex', height: 'calc(100% - 98px)' }}>
        <Sidebar page={"Negotiations_List"} header={"Operación"} content={menu} />


        <div style={{ 'overflowY': 'auto', backgroundColor: '#FFFFFF', width: '100%', margin: "52px 0" }}>
          <div className="new_element">
            <div className="d-flex justify-content-between">
              <Search
                allData={allData}
                setListData={setListData}
                fieldsToSearch={fieldsToSearch}
              />
              <Button onClick={() => navigate('/operacion/negociaciones/crear')} style={{ background: "#001834", color: "white" }}>{dataText.new_negociacion}</Button>
            </div>
          </div>
          <TableList
            loading={loading}
            rowsData={listData}
            rowHeader={rowHeader}
            handleEdit={handleEdit}
            fetchList={fetchList}
          />
        </div>
      </div>
    </div>
  )
}

export default ListPage