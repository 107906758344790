import React from 'react'
import dataText from "../../config/DataText";
import { RxCross1 } from 'react-icons/rx'
import { HiChevronDoubleRight } from 'react-icons/hi'

const Pending = ({ items, updateStatus, userType }) => {
    // TODO si es retiler supuestamente tiene que pasar a rejected. por ahora es tambien Available
    const status_cancelled = userType === "advertiser" ? "AVAILABLE" : "AVAILABLE"
    return (
        <div className={userType === "advertiser" ? "pending" : "pending retailer"}>
            <div className="header_container">
                <p className="header">{dataText.pendiente_aceptacion}</p>
            </div>
            {
                items && items.map(item => {
                    if (item && item.status === 'PENDING')                         // TODO update with retailer name or account name
                        return <div key={item.id} className="item_container"><p className="item" >{userType === "advertiser" ? item.retailer_name : item.account_name}</p><button className="move" onClick={() => { updateStatus(item.id, status_cancelled, item.status) }}>{<RxCross1 />}</button>
                            {userType !== "advertiser" ? <button className="move" onClick={() => { updateStatus(item.id, 'APPROVED', item.status) }}>{<HiChevronDoubleRight />}</button> : ""}
                        </div>
                })
            }

        </div>
    )
}
export default Pending