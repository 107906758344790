import { useState, useEffect } from "react";
import styles from './negotiation_final.module.css'
import { Button, Form, Row, Col } from 'react-bootstrap';
import globals from "config/config";
import Select from 'react-select'
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { patchCancelNegotiation, patchNegotiation, postProposals } from "../../../services/services";
import { formatDateForNegotiationFinalConfigurationPage } from "utils/utils";

const CreateNegotiation = ({ userType, userId, approvedMessage, negotiation }) => {
  const user = useSelector(state => state.authUser);
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [validatedMultiSalesPerson, setValidatedMultiSalesPerson] = useState(false);
  const [validatedMultiSellerContact, setValidatedMultiSellerContact] = useState(false);
  const [validatedMultiTrafficker, setValidatedMultiTrafficker] = useState(false);
  // disable fields when negotiation is accepted
  const [isDisabled, setIsDisabled] = useState(false);
  const [validated, setValidated] = useState(false);

  const cancelNegotiation = () => {
    Swal.fire({
      title: 'Confirmación requerida',
      icon: 'info',
      html:
        'Está cancelando la negociación</br>' +
        'Este paso es irreversible.',
      showCancelButton: true,
      cancelButtonColor: "#d20631",
      confirmButtonText: 'Cancelar negociación',
      cancelButtonText: `Volver`,
      backdrop: true,
    }).then((result) => {
      if (result.isConfirmed) {
        const body = userType === "advertiser"
          ? { brand_status: "CANCELLED", retailer_status: "ADVERTISER_CANCELLED" }
          : { brand_status: "RETAILER_CANCELLED", retailer_status: "CANCELLED" }

        patchCancelNegotiation(negotiation.id, body)
          .then(response => {

            let timerInterval
            Swal.fire({
              icon: 'success',
              html: 'La negociación ha sido cancelada!',
              showConfirmButton: false,
              timer: 1300,
              backdrop: false,
              willClose: () => {
                clearInterval(timerInterval)
              }
            }).then(result => {
              navigate("/operacion/negociaciones/lista")
            })

          })
          .catch(error => console.log("error"))
      }
      return result
    })
  }

  const initialForm = {
    negotiation_id: approvedMessage.negotiation_id,
    negotiation_version: approvedMessage.negotiation_version,
    retailer_proposal_name: negotiation.retailer_title,
    brand_proposal_name: negotiation.brand_title,
    status: approvedMessage.version.status,
    seller_contact_email: "",
    seller_contact_external_id: "",
    sales_person_email: "",
    sales_person_external_id: "",
    trafficker_external_id: "",
    trafficker_email: "",
    priority: "TODO define this",
    priority_value: "TODO define this",
    type: negotiation.type,
    country: negotiation.country,
    currency: negotiation.currency,
    start_date: approvedMessage.version.start_date,
    end_date: approvedMessage.version.end_date,
    rate: approvedMessage.version.rate,
    quantity: approvedMessage.version.quantity,
    budget: approvedMessage.version.budget,
    creative_sizes: approvedMessage.version.creative_sizes,
    url: negotiation.url,
    retailer_internal_notes: negotiation.retailer_internal_notes,
    brand_internal_notes: negotiation.brand_internal_notes,
    external_id: negotiation.retailer_external_connection_id, // TODO define this
    retailer_id: 1,
    brand_id: 2,
    retailer_external_connection_id: negotiation.retailer_external_connection_id,
    brand_external_connection_id: negotiation.brand_external_connection_id, // TODO define this
    retailer_external_connection_name: "TODO define this",
    // brand_external_connection_name: "TODO define this",
    // format: "",
    inventory: approvedMessage.version.inventory,
    tags: [], // TODO define this
    gam_proposal_name: "",
    gam_proposal_lineitem_name: "",
    request_user_acceptance: false
  }
  const [form, setForm] = useState(initialForm);

  //multi
  const inventorySelector = approvedMessage.version.inventory.map(x => {
    return {
      value: x.inventory_id,
      label: x.inventory_name
    }
  })
  const creativesSelector = approvedMessage.version.creative_sizes.map(x => {
    return {
      value: x,
      label: x
    }
  })

  const handleChange = e => {
    const value = e.target.value
    const index = e.target.name

    if (index === "sales_person") {
      const data = negotiation.externalConnectionUsers.find(x => x.id === Number(value))
      setForm(prevState => ({
        ...prevState,
        sales_person_external_id: String(data.id),
        sales_person_email: data.email
      }))
    } else if (index === "trafficker") {
      const data = negotiation.externalConnectionUsers.find(x => x.id === Number(value))
      setForm(prevState => ({
        ...prevState,
        trafficker_external_id: String(data.id),
        trafficker_email: data.email
      }))
    } else if (index === "seller_contact") {
      const data = negotiation.externalConnectionUsers.find(x => x.id === Number(value))
      setForm(prevState => ({
        ...prevState,
        seller_contact_external_id: String(data.id),
        seller_contact_email: data.email
      }))
    } else {
      setForm(prevState => ({
        ...prevState,
        [index]: value
      }))
    }
  };

  const handleSubmit = async e => {
    // TODO add specific call for patch negotiation
    e.preventDefault();
    const form_val = e.currentTarget;
    if (!form_val.checkValidity() /* || form.tags.length == 0 */) {
      
      e.stopPropagation();
      if (form.tags.length === 0) {
        // setValidatedMultiGAM(true)
      }
      setValidated(true)
    }
    else {
      if (user.type === "advertiser") {
        const text = "Usted queda a la espera de que el Retailer cierre por su parte este mismo acuerdo. Cuando esto suceda le llegará una notificación para que usted confirme la propuesta a través de su plataforma DV360."
        Swal.fire({
          text: text,
          icon: "",
          showCancelButton: true,
          allowEscapeKey: true,
          allowOutsideClick: false,
          confirmButtonText: "Aceptar",
          cancelButtonText: "Cancelar",
          backdrop: true,
          showClass: {
            popup: 'animated fadeInDown'
          },
          hideClass: {
            popup: 'animated fadeOutUp',
          },
        }).then((result) => {
          if (result.isConfirmed) {
            patchNegotiation(form.negotiation_id, { brand_status: "WAITING_FOR_RETAILER_FINAL_ACCEPTANCE", retailer_status: "FINAL_ACCEPTANCE_PENDING", brand_title: form.brand_proposal_name, retailer_title: form.retailer_proposal_name, url: form.url, retailer_internal_notes: form.retailer_internal_notes, brand_internal_notes: form.brand_internal_notes })
              .then(response2 => {
                navigate("/operacion/negociaciones/lista")
              })
              .catch(error => {
                console.log(error)
              })
          } else {
            return
          }
        }).catch(error => {
          console.log(error)
        })
      } else {
        const text = "Una vez que usted acepte este acuerdo esta plataforma montará en su AdManager el acuerdo cerrado y se dará aviso al Advertiser para que pueda finalizar la configuración en su DV360. ¿Quiere enviar una solicitud de aceptación?";
        const customSwal = Swal.mixin({
          customClass: {
            denyButton: `${styles.deny_button_swal}`,
          },
        })
        customSwal.fire({
          text: text,
          icon: "",
          showCancelButton: true,
          allowEscapeKey: true,
          allowOutsideClick: false,
          showDenyButton: true,
          // cancelButtonColor: "#d20631",
          confirmButtonText: "Aceptar",
          denyButtonText: 'Aceptar sin enviar',
          cancelButtonText: 'Cancelar',
          backdrop: true,
          // customClass: {
          //   denyButton: 'deny_button',
          //   confirmButton: 'deny_button',
          // },
          showClass: {
            popup: 'animated fadeInDown'
          },
          hideClass: {
            popup: 'animated fadeOutUp',
          },
        }).then((result) => {
          if (result.isConfirmed || result.isDenied) {
            let body = form
            body["request_user_acceptance"] = result.isConfirmed ? true : false

            postProposals(body).then(response => {
              patchNegotiation(form.negotiation_id, { brand_status: "APPROVED", retailer_status: "APPROVED", brand_title: form.brand_proposal_name, retailer_title: form.retailer_proposal_name, url: form.url, retailer_internal_notes: form.retailer_internal_notes, brand_internal_notes: form.brand_internal_notes })
                .then(response2 => {
                  navigate("/operacion/negociaciones/lista")
                })
                .catch(err => {
                  console.log(err)
                })

            }).catch(err => {
              console.log(err)
            })
          }
        }).catch(error => {
          console.log(error)
        })
      }
    }
  }

  useEffect(() => {
    setForm({
      negotiation_id: negotiation.id,
      negotiation_version: String(approvedMessage.negotiation_version),
      retailer_proposal_name: negotiation.retailer_title,
      brand_proposal_name: negotiation.brand_title,
      // status: approvedMessage.version.status,  // TODO fix this
      status: "READY",
      seller_contact_email: "",
      seller_contact_external_id: "",
      sales_person_email: "",
      sales_person_external_id: "",
      trafficker_external_id: "",
      trafficker_email: "",
      priority: "",
      priority_value: "",
      type: negotiation.type,
      country: "Spain",
      currency: "EUR",
      start_date: approvedMessage.version.start_date,
      end_date: approvedMessage.version.end_date,
      rate: approvedMessage.version.rate,
      quantity: approvedMessage.version.quantity,
      budget: approvedMessage.version.budget,
      creative_sizes: approvedMessage.version.creative_sizes,
      url: negotiation.url, // TODO define this
      retailer_internal_notes: negotiation.retailer_internal_notes,
      brand_internal_notes: negotiation.brand_internal_notes,
      external_id: String(negotiation.retailer_external_connection_id), // TODO define this
      retailer_id: negotiation.retailer_id,
      brand_id: negotiation.brand_id,
      retailer_external_connection_id: negotiation.retailer_external_connection_id,
      // brand_external_connection_id: negotiation.brand_external_connection_id, // TODO fix this
      brand_external_connection_id: 5, // TODO fix this
      // retailer_external_connection_name: "TODO define this",
      // brand_external_connection_name: "TODO define this",

      // TODO poner esto en la DB para poder añadirlo desde el form
      gam_proposal_name: "",
      gam_proposal_lineitem_name: "",
      inventory: approvedMessage.version.inventory,
      tags: [],
    })
    // disabled fields when negotiation is accepted
    if (user.type === "advertiser" &&
      ((negotiation.brand_status === "WAITING_FOR_RETAILER_FINAL_ACCEPTANCE" && negotiation.retailer_status === "FINAL_ACCEPTANCE_PENDING") ||
        (negotiation.brand_status === "WAITING_FOR_RETAILER_FINAL_CONFIGURATION" && negotiation.retailer_status === "FINAL_CONFIGURATION_PENDING") ||
        (negotiation.brand_status === "APPROVED" && negotiation.retailer_status === "APPROVED"))) {
      setIsDisabled(true)
    }
    if (user.type === "retailer" &&
      (
        // (negotiation.brand_status === "WAITING_FOR_RETAILER_FINAL_ACCEPTANCE" && negotiation.retailer_status === "FINAL_ACCEPTANCE_PENDING")
        // ||
        (negotiation.brand_status === "APPROVED" && negotiation.retailer_status === "APPROVED"))) {
      setIsDisabled(true)
    }
  }, [approvedMessage, negotiation, user])

  return (

    <>
      <h1 className={styles.header_create}></h1>
      <Form className={styles.form} onSubmit={handleSubmit} noValidate validated={validated}>
        <Row className="mb-3 mr-3">
          <Col className={styles.item} md={6}>
            <Form.Group controlId="validationCustom01">
              <Form.Label className={styles.label_create} >RFP Name</Form.Label>
              {user.type === "advertiser" ? (
                <Form.Control
                  // this proposal_name is the negotiation's title. Updated by this form submit to patchNegotiation
                  type="text"
                  name="brand_proposal_name"
                  placeholder={form?.brand_proposal_name || form?.retailer_proposal_name}
                  onChange={handleChange}
                  disabled={isDisabled}
                />
              ) : (
                <Form.Control
                  // this proposal_name is the negotiation's title. Updated by this form submit to patchNegotiation
                  type="text"
                  name="retailer_proposal_name"
                  placeholder={form?.retailer_proposal_name || form?.brand_proposal_name}
                  onChange={handleChange}
                  disabled={isDisabled}
                />
              )

              }
              <Form.Control.Feedback type="invalid">
                Por favor introduzca un valor
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col className={styles.item_small} md={3} >

            <Form.Group controlId="validationCustom02">
              <Form.Label className={styles.label_create} >Start Date</Form.Label>
              <Form.Control
                type="text"
                // name="start_date"
                value={formatDateForNegotiationFinalConfigurationPage(approvedMessage?.version?.start_date)}
                disabled={true}
              />
            </Form.Group>
          </Col>
          <Col className={styles.item_small} md={3} >
            <Form.Group controlId="validationCustom03">
              <Form.Label className={styles.label_create} >End Date</Form.Label>
              <Form.Control
                type="text"
                // name="end_date"
                value={formatDateForNegotiationFinalConfigurationPage(approvedMessage?.version?.end_date)}
                disabled={true}
              />
            </Form.Group>
          </Col>

        </Row>
        <Row className="mb-3 mr-3">
          <Col className={styles.item} md={6}>
            <Form.Group controlId="validationCustom04">
              <Form.Label className={styles.label_create} >Advertiser</Form.Label>
              <Form.Control
                type="text"
                // name="advertiser"
                value={negotiation?.brand.name}
                disabled={true}
              />
            </Form.Group>
          </Col>
          <Col className={styles.item} md={6}>
            <Form.Group controlId="validationCustom05">
              <Form.Label className={styles.label_create}>Inventario</Form.Label>
              <Select
                value={inventorySelector}
                options={inventorySelector}
                isMulti
                isDisabled={true}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3 mr-3">
          <Col className={styles.item} md={6}>
            <Form.Group controlId="validationCustom06">
              <Form.Label className={styles.label_create} >Retailer</Form.Label>
              <Form.Control
                type="text"
                // name="retailer"
                value={negotiation?.retailer.name}
                disabled={true}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3 mr-3">
          <Col className={styles.item} md={6}>
            {/* <Form.Group controlId="validationCustom07">
              <Form.Label className={styles.label_create} >Buyer</Form.Label>
              <Form.Select aria-label="Default select example"
                name="buyer"
                disabled
              >
                <option value="none" selected disabled>{negotiation.retailer.id}</option>
              </Form.Select>
            </Form.Group> */}
          </Col>
          <Col className={styles.item_small} md={3}>
            <Form.Group controlId="validationCustom08">
              <Form.Label className={styles.label_create} >Budget</Form.Label>
              <Form.Control
                type="text"
                // name="budget"
                value={approvedMessage?.version.budget}
                disabled={true}
              />
            </Form.Group>
          </Col>
          <Col className={styles.item_small} md={3}>
            <Form.Group controlId="validationCustom09">
              <Form.Label className={styles.label_create} >Rate(CPM - opcional)</Form.Label>
              <Form.Control
                type="text"
                // name="rate"
                value={approvedMessage?.version.rate}
                disabled={true}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3 mr-3">
          <Col className={styles.item} md={6}>

          </Col>
          <Col className={styles.item_small} md={3}>
            <Form.Group controlId="validationCustom11">
              <Form.Label className={styles.label_create} >Quantity</Form.Label>
              <Form.Control
                type="text"
                // name="quantity"
                value={approvedMessage?.version.quantity}
                disabled={true}
              />
            </Form.Group>
          </Col>
          <Col className={styles.item_small} md={3}>
            <Form.Group controlId="validationCustom12">
              <Form.Label className={styles.label_create} >Creatives</Form.Label>
              <Select
                // name="creative_sizes"
                value={creativesSelector}
                options={creativesSelector}
                isMulti
                isDisabled={true}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3 mr-3">
          <Col className={styles.item} md={6}>
            <Form.Group controlId="validationCustom13">
              <Form.Label className={styles.label_create} >URL</Form.Label>
              <Form.Control
                disabled={
                  user.type === "advertiser"
                    ? isDisabled
                      ? true
                      : false
                    : true
                }
                type="text"
                name="url"
                onChange={handleChange}
                value={form?.url}
                required
              />
              <Form.Control.Feedback type="invalid">
                Por favor introduzca un valor
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col className={styles.notes} md={6}>
            <Form.Group >
              <Form.Label className={styles.label_create} >Notas internas (opcional)</Form.Label>
              <textarea
                className="form-control"
                rows="6"
                name={userType === "advertiser" ? "brand_internal_notes" : "retailer_internal_notes"}
                value={userType === "advertiser" ? form?.brand_internal_notes : form?.retailer_internal_notes}
                onChange={handleChange}
                disabled={isDisabled}
              ></textarea>
            </Form.Group>
          </Col>
        </Row>
        {userType === "retailer" && (
          <>
            <Row className="mb-3 mr-3">
              <Col className={styles.item} md={6}>
                <Form.Group controlId="validateSalesPerson">
                  <Form.Label className={styles.label_create} >Sales Person</Form.Label>
                  <Form.Select aria-label="Default select example"
                    name="sales_person"
                    onChange={handleChange}
                    required
                    disabled={isDisabled}
                  >
                    <option value="" selected disabled hidden></option>
                    {negotiation?.externalConnectionUsers.map((x, key) => {
                      return <option key={key} value={x.id}>{x.email}</option>;
                    })}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Por favor seleccione una opción
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col className={styles.item} md={3}>
                {/* <Form.Group controlId="validationCustom18">
                <Form.Label className={styles.label_create} >Format</Form.Label>
                <Form.Control
                  type="text"
                  name="format"
                  onChange={handleChange}
                  required
                  disabled={isDisabled}
                />
                <Form.Control.Feedback type="invalid">
                  Por favor introduzca un valor
                </Form.Control.Feedback>
              </Form.Group> */}
              </Col>
              <Col className={styles.item} md={3}>
              </Col>
            </Row>
            <Row className="mb-3 mr-3">
              <Col className={styles.item} md={6}>
                <Form.Group controlId="validateTrafficker">
                  <Form.Label className={styles.label_create} >Trafficker</Form.Label>
                  <Form.Select aria-label="Default select example"
                    name="trafficker"
                    onChange={handleChange}
                    required
                    disabled={isDisabled}
                  >
                    <option value="" selected disabled hidden></option>
                    {negotiation?.externalConnectionUsers.map((x, key) => {
                      return <option key={key} value={x.id}>{x.email}</option>;
                    })}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Por favor seleccione una opción
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col className={styles.item} md={6}>
                <Form.Group controlId="validationCustom18">
                  <Form.Label className={styles.label_create} >Proposal Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="gam_proposal_name"
                    onChange={handleChange}
                    required
                    disabled={isDisabled}
                  />
                  <Form.Control.Feedback type="invalid">
                    Por favor introduzca un valor
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-3 mr-3">
              <Col className={styles.item} md={6}>
                <Form.Group controlId="validateSellerContact">
                  <Form.Label className={styles.label_create} >Seller contact</Form.Label>
                  <Form.Select aria-label="Default select example"
                    name="seller_contact"
                    onChange={handleChange}
                    required
                    disabled={isDisabled}
                  >
                    <option value="" selected disabled hidden></option>
                    {negotiation?.externalConnectionUsers.map((x, key) => {
                      return <option key={key} value={x.id}>{x.email}</option>;
                    })}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Por favor seleccione una opción
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col className={styles.item} md={6}>
                <Form.Group controlId="validationCustom20">
                  <Form.Label className={styles.label_create} >Proposal line item name (Opcional)</Form.Label>
                  <Form.Control
                    type="text"
                    name="gam_proposal_lineitem_name"
                    onChange={handleChange}
                    disabled={isDisabled}
                  />
                  <Form.Control.Feedback type="invalid">
                    Por favor introduzca un valor
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-3 mr-3">
              <Col className={styles.item} md={6}></Col>
              <Col className={styles.item} md={6}>
                <Form.Group controlId="validationCustom20">
                  <Form.Label className={styles.label_create} >Line item priority</Form.Label>
                  <Form.Control
                    type="text"
                    name="priority"
                    onChange={handleChange}
                    required
                    disabled={isDisabled}
                  />
                  <Form.Control.Feedback type="invalid">
                    Por favor introduzca un valor
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-3 mr-3">
              <Col className={styles.item} md={6}></Col>
              <Col className={styles.item} md={6}>
                <Form.Group controlId="validationCustom04">
                  <Form.Label className={styles.label_create} >Line item priority value</Form.Label>
                  <Form.Control aria-label="Default select example"
                    type="text"
                    name="priority_value"
                    onChange={handleChange}
                    required
                    disabled={isDisabled}
                  />
                  <Form.Control.Feedback type="invalid">
                    Por favor introduzca un valor
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
          </>
        )
        }

        <div className={styles.buttons}>
          <Button
            type="submit"
            className={styles.button_start}
            disabled={isDisabled}
          >Aceptar acuerdo final</Button>
          <Button
            type="button"
            className={styles.button_cancel}
            disabled={isDisabled}
            onClick={cancelNegotiation}
          >Cancelar</Button>
        </div>


      </Form >

    </>


  )
}

export default CreateNegotiation