import NavBar from "../../../components/NavBar/NavBar";
import Sidebar from "../../../components/SideBar/SideBar"
import CurrentNegForm from "./CurrentNegForm"
import HeaderNegotiation from "./HeaderConfNeg";
import { OperationsSideBar } from "../../../config/sidebar_types";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";


const CurrentNegotiationPage = () => {
  const { id } = useParams();

  const user = useSelector(state => state.authUser);
  const menu = OperationsSideBar
  return (
    <div style={{ height: '100%' }}>
      <NavBar />

      <div style={{ display: 'flex', height: 'calc(100% - 98px)' }}>
        <Sidebar page={"Negotiations_List"} header={"Operación"} content={menu} />


        <div style={{ 'overflowY': 'auto', backgroundColor: '#FFFFFF', width: '100%' }}>
          <div style={{ 'overflowY': 'auto', backgroundColor: '#FFFFFF', width: '100%' }}>
            <div style={{ 'display': 'flex', 'flex-direction': 'column', 'padding': '3rem 14rem 1rem 5.5rem', 'min-height': '200px' }}>
              <CurrentNegForm id={id} />
            </div>
          </div>
        </div>
      </div>


    </div>
  )
}

export default CurrentNegotiationPage
