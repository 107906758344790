import React, { useState, useEffect } from "react";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import NavBar from "../../../components/NavBar/NavBar";
import Sidebar from "../../../components/SideBar/SideBar"
import { AccessesSideBar } from "../../../config/sidebar_types";
import Select from 'react-select'
import dataText from "../../../config/DataText";
import { getAllBrands, getAllGroups, getAllRoles, postUser, patchUser, getUser } from "../../../services/services";
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from "react-redux";
import styles from './invitar.module.css'
import Spinner from "react-bootstrap/Spinner";

const InvitarEditarUsuario = () => {
  const [brands, setBrands] = useState([])
  const [defaultBrands, setDefaultBrands] = useState([])

  const [validated, setValidated] = useState(false);
  
  const [roles, setRoles] = useState([])
  const [validatedRoles, setValidatedRoles] = useState(false);
  const [defaultRoles, setDefaultRoles] = useState([])
  
  const [groups, setGroups] = useState([])
  

  const user = useSelector(state => state.authUser);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  let navigate = useNavigate()

  const initialForm = {
    "firstname": "",
    "lastname": "",
    "email": "",
    "phone": "",
    "groups": [
    ],
    "roles": [
    ],
    "brand": [
    ]
  }
  const [form, setForm] = useState(initialForm);

  const menu = AccessesSideBar

 
  const fetchList = async () => {
    setLoading(true)
    const type = user.type === "retailer"
      ? 'retailer'
      : user.type === "advertiser"
        ? 'account'
        : null;
    try {
      const allBrands = await getAllBrands()
      //const allGroups = await getAllGroups()
      const allRoles = await getAllRoles()
      const brands_clean = allBrands.data.map(x => {
        return {
          "value": x.id,
          "label": x.name,
        }
      })
      setBrands(brands_clean)
      const roles_clean = allRoles.data.map(x => {
        return {
          "value": x.id,
          "label": x.name,
        }
      })
      setRoles(roles_clean)
      /*
      const groups_clean = allGroups.data.map(x => {
        return {
          "value": x.id,
          "label": x.name,
        }
      })
      setGroups(groups_clean)
      */
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false)
    }
  }

  const fetchData = async e => {
    setLoading(true)
    try {
      const { data } = await getUser(id);
      const user = {
        "firstname": data.firstname,
        "lastname": data.lastname,
        "email": data.email,
        "phone": data.phone !== undefined ? data.phone : "",
        "groups": data.groups !== undefined ? data.groups : [],
        "roles": data.roles !== undefined ? data.roles : [],
        "brand": data.brand !== undefined ? data.brand : []
      }
      setForm(user)

      const active_roles = form.roles
      const active_brands = form.brands

      let default_roles_array = []
      let default_brands_array = []
      if(data.roles !== undefined && data.roles.length !== 0){
        roles.forEach(element => {
          if(active_roles.includes(element.value)){
            default_roles_array.push(element)
          }
        });
      }
      if(data.brands !== undefined && data.brands.length !== 0)
      brands.forEach(element => {
        if(active_brands.includes(element.value)){
          default_brands_array.push(element)
        }
      });
      setDefaultRoles(default_roles_array)
      setDefaultBrands(default_brands_array)
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchList();
    if(id){
      fetchData();
    }
  }, [user])

  const handleChange = e => {
    let value = e.target.value
    let index = e.target.name
    setForm(prevState => ({
      ...prevState,
      [index]: value
    }))
  }

  const handleMultiSelectChangeRol = (selectedOption) => {
    let selected = [selectedOption.value]
    /* TODO multiple rols
    selectedOption.forEach(element => {
      selected.push(element.value)
    });
    */
    setForm(prevState => ({
      ...prevState,
      roles: selected
    }))
    setValidatedRoles(false)
  }

  const handleMultiSelectChangeBrand = (selectedOption) => {
    let selected = []
    selectedOption.forEach(element => {
      selected.push(element.value)
    });
    setForm(prevState => ({
      ...prevState,
      brand: selected
    }))
    setValidatedRoles(false)
  }

  const handleSubmit = async e => {
    e.preventDefault();
    const form_val = e.currentTarget;
    if (!form_val.checkValidity() || form.roles.length === 0) {
      e.stopPropagation();
      if (form.roles.length === 0) {
        setValidatedRoles(true)
      }
      setValidated(true)
    }
    else{
      if(id){
        patchUser(id, form).then(response => {
          navigate('/accesos/usuarios/lista')
          }
        ).catch(err => {
          console.log(err);
        })
      }
      else{
        postUser(form).then(response => {
          navigate('/accesos/usuarios/lista')
          }
        ).catch(err => {
          console.log(err);
        })
      }
    }
    
  };


  return (
    <div style={{ height: '100%' }}>
      <NavBar />

      <div style={{ display: 'flex', height: 'calc(100% - 98px)' }}>
        <Sidebar page={"Connections_List"} header={"Configuración"} content={menu} />


        <div style={{ 'overflowY': 'auto', backgroundColor: '#FFFFFF', width: '100%' }}>
          <div className='container-fluid' style={{ padding: "3.5rem 3.5rem" }}>
            <div className="row">
              <div className="col-3">
                <Form
                  onSubmit={handleSubmit}  noValidate validated={validated}
                >
                  <h4>{id ? dataText.editar_usuario : dataText.invitar_usuario}</h4>
                  {loading && (
                    <div className="d-flex justify-content-center">
                      <Spinner animation="border" style={{ color: "#222e73" }} />
                    </div>
                  )}
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Email</Form.Label>
                    <Form.Control type="email" placeholder="Enter email" value={form.email} name="email" required onChange={handleChange} />
                  </Form.Group>
                  <Form.Group className="mb-3" >
                    <Form.Label>Nombre</Form.Label>
                    <Form.Control type="text" placeholder="Introduzca nombre" value={form.firstname} name="firstname" required onChange={handleChange}/>
                  </Form.Group>
                  <Form.Group className="mb-3" >
                    <Form.Label>Apellidos</Form.Label>
                    <Form.Control type="text" placeholder="Introduzca apellidos" value={form.lastname} name="lastname" required onChange={handleChange}/>
                  </Form.Group>
                  <Form.Group className="mb-3" >
                    <Form.Label>Número de teléfono</Form.Label>
                    <Form.Control type="number" placeholder="Introduzca el número de teléfono" value={form.phone} name="phone" required onChange={handleChange}/>
                  </Form.Group>
                  <Form.Group className="mb-3" >
                    <Form.Label>Rol</Form.Label>
                    <Select
                      isClearable
                      isSearchable
                      name="rol"
                      options={roles}
                      defaultValue={id ? defaultRoles : ""}
                      onChange={handleMultiSelectChangeRol}
                      required
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          borderColor: validatedRoles === true ? '#dc3545' : '#ced4da',
                        }),
                      }}
                      placeholder="Seleccione Rol"
                    />
                    {validatedRoles === true ? <div className={styles.invalid} styles={{ display: 'block' }}>Por favor seleccione una opción</div> : ""}
                  </Form.Group>
                  <Form.Group className="mb-3" >
                    <Form.Label>Grupo</Form.Label>
                    <Select
                      isClearable
                      isSearchable
                      isDisabled
                      name="colourOptions"
                      options={groups}
                      placeholder="Seleccione el grupo"
                    />
                  </Form.Group>
                  {user.type === "retailer" ? <></> :
                  <Form.Group className="mb-3" >
                    <Form.Label>Marca</Form.Label>
                    <Select
                      isMulti
                      name="brand"
                      defaultValue={id ? defaultBrands : ""}
                      isClearable
                      isSearchable
                      options={brands}
                      onChange={handleMultiSelectChangeBrand}
                      placeholder="Seleccione la marca"
                    />
                  </Form.Group>
                  }
                  <div className="d-flex justify-content-between">
                    <span></span>
                    <Button style={{ backgroundColor: "#001834", color: "white" }} type="submit">
                      {id ? dataText.guardar : dataText.invitar_usuario}
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>


    </div>
  )
}

export default InvitarEditarUsuario