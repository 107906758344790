import { useState } from "react";
import globals from "../../config/config";
import { useDispatch } from "react-redux";
import { setAuthUser } from "state/appSlice";
import { useNavigate } from "react-router-dom";
import { Button, Form, Container } from 'react-bootstrap';
import logo from '../../assets/images/logotipo-white-bmind-jakala-company 1.png';
import logo_footer from '../../assets/images/logotipo-white-bmind-jakala-company 4.png';
const { apiBaseUrl } = globals;

const Restablecer = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const intialFormState = {
    email: "",
  }

  const [form, setForm] = useState(intialFormState);

  const handleChange = e => {
    setForm(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value
    }))
  };

  const resetState = () => {
    navigate('/login')
  }

  const [validated, setValidated] = useState(false);
  const handleSubmit = async e => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);
    }
    else{

    }
  }

  return (
    
    <div className="background-login">
    <Container className="d-flex align-item justify-content-center aligns-items-center mt-0">
      <Form onSubmit={handleSubmit} noValidate validated={validated} className="form_box _login">
        <img src={logo} className ="logo _login" />
        <div className = "fields _login">
          <p>Te enviaremos un email para seguir las instrucciones para recuperar la contraseña</p>
          <Form.Group controlId="validationCustom01">
          <Form.Label className = "label _login">E-mail</Form.Label>
          <Form.Control
            /*className="text_field _custom"*/
            type="email"
            name="email"
            placeholder= "Introduzca su correo electrónico"
            onChange={handleChange}
            required
            />
            <Form.Control.Feedback type="invalid">
              { form.email === "" ? "Introduzca un E-mail" : "El formato del E-mail no es adecuado."}
            </Form.Control.Feedback>
          </Form.Group>
           
          {
            <Button
              type="submit"
              style={{cursor: 'pointer'}}
              className="button _login"
            >
              Enviar email
            </Button>
          
          }
      </div>
          
      
      </Form>
      </Container>
      <div className="register _login">
      <p> Volver a <b><a
       style={{cursor: 'pointer'}}
       onClick={resetState}>Inicia sesión</a></b></p>
      </div >
      <div className ="logo _footer">
      <img src={logo_footer}  />
      </div>
      </div>
    
  )
}

export default Restablecer