import React, { useState } from "react";
import { useEffect } from "react";
import Search from "components/Search/Search";
import TableList from "components/Table/TableList";
import NavBar from "../../components/NavBar/NavBar";
import dataText from "../../config/DataText";
import Card from 'react-bootstrap/Card';
import { getOverview } from "../../services/services";
import { useSelector } from "react-redux";

import "./overview.css"

const Overview = () => {
  const [listData, setListData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [loading, setLoading] = useState(false);

  const user = useSelector(state => state.authUser);

  const fieldsToSearch = ["brand"];

    const rowHeader = [
        {
          header: dataText.marcas,
          key: "brand",
          type: "text",
        },
        {
          header: dataText.negocacionesCurso,
          key: "ongoing_negotiations",
          type: "number",
        },
        {
          header: dataText.negocacionesDenegadas,
          key: "cancelled_negotiations",
          type: "number",
        },
        {
          header: dataText.propuestasAceptadas,
          key: "accepted_negotiations",
          type: "number",
        },
        {
          header: dataText.progress,
          key: "progress_bar",
          type: "progress",
        },
        {
          header: dataText.campaingsOn,
          key: "active_campaigns",
          type: "number",
        },
        {
          header: dataText.activactionRate,
          key: "activation_rate",
          type: "number",
        },
      ];



      const data = [
        {
          "accepted_negociations"
          : 
          15,
          "activation_rate"
          : 
          13,
          "active_campaigns"
          : 
          13,
          "brand"
          : 
          "Loreal",
          "cancelled_negociations"
          : 
          19,
          id
          : 
          1,
          "ongoing_negociations": 13,
          "progress_bar":[25,50,25],
        },
        // {
        //   "id":2,
        //   "marca":"Nenuco",
        //   "negociaciones_curso":10,
        //   "negociaciones_denegadas":17,
        //   "negociaciones_aceptadas":9,
        //   "progress_bar":38,
        //   "camp_activas":13,
        //   "activation_rate":13,
        // },
        // {
        //   "id":3,
        //   "marca":"Colgate",
        //   "negociaciones_curso":17,
        //   "negociaciones_denegadas":17,
        //   "negociaciones_aceptadas":26,
        //   "progress_bar":38,
        //   "camp_activas":34,
        //   "activation_rate":1,
        // },
        // {
        //   "id":4,
        //   "marca":"Dodot",
        //   "negociaciones_curso":42,
        //   "negociaciones_denegadas":7,
        //   "negociaciones_aceptadas":9,
        //   "progress_bar":8,
        //   "camp_activas":22,
        //   "activation_rate":32,
        // },
        // {
        //   "id":5,
        //   "marca":"Mahou",
        //   "negociaciones_curso":16,
        //   "negociaciones_denegadas":29,
        //   "negociaciones_aceptadas":32,
        //   "progress_bar":38,
        //   "camp_activas":31,
        //   "activation_rate":10,
        // },
        // {
        //   "id":6,
        //   "marca":"Cocacola",
        //   "negociaciones_curso":24,
        //   "negociaciones_denegadas":65,
        //   "negociaciones_aceptadas":9,
        //   "progress_bar":38,
        //   "camp_activas":13,
        //   "activation_rate":13,
        // },
        // {
        //   "id":7,
        //   "marca":"Estrella Galicia",
        //   "negociaciones_curso":76,
        //   "negociaciones_denegadas":12,
        //   "negociaciones_aceptadas":64,
        //   "progress_bar":73,
        //   "camp_activas":43,
        //   "activation_rate":24,
        // },
        // {
        //   "id":8,
        //   "marca":"Adidas",
        //   "negociaciones_curso":54,
        //   "negociaciones_denegadas":45,
        //   "negociaciones_aceptadas":23,
        //   "progress_bar":78,
        //   "camp_activas":13,
        //   "activation_rate":13,
        // },
        // {
        //   "id":9,
        //   "marca":"Nike",
        //   "negociaciones_curso":45,
        //   "negociaciones_denegadas":34,
        //   "negociaciones_aceptadas":84,
        //   "progress_bar":43,
        //   "camp_activas":13,
        //   "activation_rate":13,
        // },
        // {
        //   "id":10,
        //   "marca":"Samsung",
        //   "negociaciones_curso":37,
        //   "negociaciones_denegadas":54,
        //   "negociaciones_aceptadas":23,
        //   "progress_bar":39,
        //   "camp_activas":43,
        //   "activation_rate":32,
        // }
      ]
    
  const fetchList = async() => {
    setLoading(true)
    try {
      const overviewApi = await getOverview()
      setListData(overviewApi.data);
      setAllData(overviewApi.data);
    }  catch(err) {
          console.log(err);
          // alert("Email o contraseña incorrectos!")
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchList();
  }, [user])
  
      
  return (
    <div className="bg-color" style={{height: '100%'}}> 
      <NavBar />
      <div className="bg-color">
        <div className="pt-3 mt-4 d-flex justify-content-center">
          <h3>{dataText.overView}</h3>
        </div>
        <div className="pt-3 mt-4 d-flex justify-content-center">
          {/* <div className="container"> */}
          <div className="row ">
            <div className="col-3">
            <Card className='card_overview'   >
              <Card.Body>
                <Card.Title className="pb-5 text-center" >Conexiones</Card.Title>
                <Card.Text>
                  <h3>5/10</h3>
                </Card.Text>
              </Card.Body>
            </Card>
            </div>
            <div className="col-3">
            <Card className='card_overview' >
              <Card.Body>
                <Card.Title  className="pb-5 text-center">Negociaciones</Card.Title>
                <Card.Text>
                  <h3>5/10</h3>
                </Card.Text>
              </Card.Body>
            </Card>
            </div>
            <div className="col-3">
            <Card className='card_overview'  >
              <Card.Body>
                <Card.Title  className="pb-5 text-center">Propuestas</Card.Title>      
                <Card.Text>
                  <h3>5/10</h3>
                </Card.Text>
              </Card.Body>
            </Card>
            </div>
            <div className="col-3">
            <Card className='card_overview' >
              <Card.Body >
                <Card.Title  className="pb-5 text-center">Campañas activas</Card.Title>
                <Card.Text>
                  <h3>5/10</h3>
                </Card.Text>
              </Card.Body>   
            </Card>
            </div>
          </div>
          </div>
        {/* </div> */}
        <div className="new_element">
          <div className="d-flex justify-content-between">
          <Search 
            allData={allData}
            setListData={setListData}
            fieldsToSearch={fieldsToSearch}
          />            
          </div>
        </div>
        <div className="pb-3">
        <TableList
          loading={loading}
          rowsData={listData}
          rowHeader={rowHeader}
          fetchList={fetchList}
        />
        </div>
      </div>
    </div>
  )
}

export default Overview