import NavBar from "../../../components/NavBar/NavBar";
import Sidebar from "../../../components/SideBar/SideBar"
import Table from "../../../components/ConnectionsTable/Table"
import { OperationsSideBar } from "../../../config/sidebar_types";
import { useState, useEffect } from "react";
import { getConnections } from "../../../services/services";
import { useSelector } from "react-redux";


const ConnectionsPage = () => {
  const user = useSelector((state) => state.authUser);
  const [items, setItems] = useState([]);

  const fetchData = async () => {
    const type = user.type === "retailer"
      ? 'retailer'
      : user.type === "advertiser"
        ? 'account'
        : null;

    const id = user.activeProfile.account_id ? user.activeProfile.account_id : user.activeProfile.retailer_id;

    getConnections(type, id).then((connections) => {
      setItems(connections.data)
    });
  }

  useEffect(() => {
    fetchData()
  }, [user])

  const menu = OperationsSideBar
  return (
    <div style={{ height: '100%' }}>
      <NavBar />

      <div style={{ display: 'flex', height: 'calc(100% - 98px)' }}>

        <Sidebar page={"Retailers_Connections"} header={"Operación"} content={menu} />

        <div style={{ 'overflowY': 'auto', backgroundColor: '#FFFFFF', width: '100%' }}>
          <Table userType={user.type} items={items} setItems={setItems} userId={user.id} />
        </div>
      </div>
    </div>
  )
}

export default ConnectionsPage