import { useSelector } from "react-redux";
import styles from "./chat.module.css";
import Messages from "../Messages/Messages";
import { useEffect, useState } from "react";
import { dateForChatHeader } from "utils/utils";

const Chat = ({ negotiation, updateNegotiation, setUpdateNegotiation, loading }) => {
  const user = useSelector(state => state.authUser);

  return (
    <div className={styles.chat}>
      <div className={styles.header}>
        {!loading && (
          <>
            <div className={styles.image_container}>
              <img src={user.type === "retailer" ? negotiation?.brand.logo : negotiation?.retailer.logo} alt={user.type === "retailer" ? negotiation?.brand.name : negotiation?.retailer.name} />
            </div>
            <div className={styles.header_text}>
              {user.type === "advertiser"
                ? <div>{`${negotiation?.created_date ? dateForChatHeader(negotiation?.created_date) : ""}_${negotiation?.brand_title ? negotiation?.brand_title : negotiation?.retailer_title}_${negotiation?.brand_name}_${negotiation?.retailer_name}`}</div>
                : <div>{`${negotiation?.created_date ? dateForChatHeader(negotiation?.created_date) : ""}_${negotiation?.retailer_title ? negotiation?.retailer_title : negotiation?.brand_title}_${negotiation?.brand_name}_${negotiation?.retailer_name}`}</div>
              }
            </div>
          </>
        )

        }
      </div>

      {/* {
        loadingNegotiation ? (
          <GoogleSpinner />
        ) : ( */}
      <Messages updateNegotiation={updateNegotiation} setUpdateNegotiation={setUpdateNegotiation} loading={loading} />
      {/* )
      } */}
    </div>
  )
}

export default Chat