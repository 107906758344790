import { useState } from "react";
import axios from "axios";
import globals from "../../config/config";
import { useDispatch } from "react-redux";
import { setAuthUser, setSrmToken } from "state/appSlice";
import { useNavigate } from 'react-router-dom';

import { Button, Form, ButtonGroup, ToggleButton, Container } from 'react-bootstrap';


import logo from '../../assets/images/logotipo-white-bmind-jakala-company 1.png';
import logo_footer from '../../assets/images/logotipo-white-bmind-jakala-company 4.png';
import { registerUser } from "services/services";
import Swal from "sweetalert2";

const Register_boot = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const radios = [
    { name: 'Retailer', value: 'retailer' },
    { name: 'Advertiser', value: 'advertiser' },
  ];

  const intialFormState = {
    email: "",
    emailConfirm: "",
    accountName: "",
    contact: "",
    contactPhone: "",
    cif: "",
    address: "",
    pcode: "",
    city: "",
    accountType: "retailer",
    terms: false
  }

  const [form, setForm] = useState(intialFormState);

  const handleChange = e => {
    const value = e.target.name !== "terms" ? e.target.value : e.target.checked
    setForm(prevState => ({
      ...prevState,
      [e.target.name]: value
    }))
  };

  const resetState = () => {
    navigate('/login')
  }

  const confirmErrors = {
    emailError: form.email !== form.emailConfirm,
    passwordError: form.password !== form.confirmPassword
  }
  const [validated, setValidated] = useState(false);

  const handleSubmit = async e => {
    e.preventDefault();
    const checkForm = e.currentTarget;
    if (!checkForm.checkValidity()) {
      e.stopPropagation();
      setValidated(true);
    }
    else {
      setValidated(true);
      try {
        const response = await registerUser({...form})

        navigate('/login');
      } catch (err) {
        console.log(err);
        dispatch(setSrmToken(null));
        dispatch(setAuthUser(null));
        Swal.fire({
          text: "Error en el registro!",
          icon: "error",
          allowEscapeKey: true,
          allowOutsideClick: true,
          backdrop: false
        }).then(() => {
          window.close();
        });
      }
    }
  }

  return (

    <div className="background-register">
      <Container className="d-flex align-item justify-content-center aligns-items-center mt-0">
        <Form onSubmit={handleSubmit} noValidate validated={validated} className="form_box _login">
          <img src={logo} className="logo _login" />
          <div className="fields _login">
            <p className="header _register">Crear cuenta</p>
            <Form.Group controlId="validationCustom01">
              <Form.Label className="label _login" >Nombre Cuenta</Form.Label>
              <Form.Control
                type="text"
                name="accountName"
                placeholder="Introduzca el nombre de la cuenta"
                onChange={handleChange}
                required
              />
              <Form.Control.Feedback type="invalid">
                Por favor introduzca el nombre de la cuenta
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="validationCustom02">
              <Form.Label className="label _login">E-mail</Form.Label>
              <Form.Control
                type="email"
                name="email"
                placeholder="Introduzca su correo electrónico"
                onChange={handleChange}
                required
              />
              <Form.Control.Feedback type="invalid">
                {form.email === "" ? "Introduzca un E-mail" : "El formato del E-mail no es adecuado."}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="validationCustom03">
              <Form.Label className="label _login" >Introduzca de nuevo E-mail</Form.Label>
              <Form.Control
                type="email"
                name="emailConfirm"
                isInvalid={confirmErrors.emailError}
                placeholder="Introduzca de nuevo su correo electrónico"
                onChange={handleChange}
                required
              />
              <Form.Control.Feedback type="invalid">
                {confirmErrors.emailError ? "Los E-mail no coinciden" : (form.emailConfirm === "" ? "Por favor vuelva a introducir el E-mail" : "El formato del email no es el adecuado")}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="validationCustom04">
              <Form.Label className="label _login" >Nombre persona de contacto</Form.Label>
              <Form.Control
                type="text"
                name="contact"
                placeholder="Introduzca el nombre de la persona de contacto"
                onChange={handleChange}
                required
              />
              <Form.Control.Feedback type="invalid">
                Por favor introduzca el nombre de la persona de contacto
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="validationCustom05">
              <Form.Label className="label _login" >Teléfono de contacto</Form.Label>
              <Form.Control
                type="tel"
                name="contactPhone"
                placeholder="Introduzca el teléfono de contacto"
                onChange={handleChange}
                required
              />
              <Form.Control.Feedback type="invalid">
                Por favor introduzca el teléfono de contacto
              </Form.Control.Feedback>
            </Form.Group>
            <hr width="50%" style={{
              alignSelf: "center"
            }}></hr>

            <Form.Group controlId="validationCustom06">
              <Form.Label className="label _login" style={{ margin: '0 0 0.25rem' }}>CIF</Form.Label>
              <Form.Control
                type="text"
                name="cif"
                placeholder="Introduzca el CIF"
                onChange={handleChange}
                required
              />
              <Form.Control.Feedback type="invalid">
                Por favor introduzca el CIF
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="validationCustom07">
              <Form.Label className="label _login">Dirección</Form.Label>
              <Form.Control
                type="text"
                name="address"
                placeholder="Introduzca la dirección"
                onChange={handleChange}
                required
              />
              <Form.Control.Feedback type="invalid">
                Por favor introduzca la dirección
              </Form.Control.Feedback>
            </Form.Group>

            <div className="row _register" >
              <Form.Group className="text_field _register _row" controlId="validationCustom08" >
                <Form.Label className="label _login">PC</Form.Label>
                <Form.Control
                  type="text"
                  name="pcode"
                  placeholder="Introduzca el CP"
                  onChange={handleChange}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Por favor introduzca el CP
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="text_field _register _row" controlId="validationCustom09">
                <Form.Label className="label _login">Ciudad</Form.Label>
                <Form.Control
                  type="text"
                  name="city"
                  placeholder="Introduzca la ciudad"
                  onChange={handleChange}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Por favor introduzca la ciudad
                </Form.Control.Feedback>
              </Form.Group>

            </div>
            <hr width="50%" style={{
              alignSelf: "center"
            }}></hr>
            <div className="row _register" >
              <p style={{ width: 'max-content', margin: '0.5rem 0 0.5rem', 'text-align': 'center' }}>Tipo de cuenta</p>

              <ButtonGroup style={{ width: 'max-content', height: 'max-content' }}>
                {radios.map((radio, idx) => (
                  <ToggleButton
                    key={idx}
                    id={`radio-${idx}`}
                    type="radio"
                    variant={idx % 2 ? 'outline-secondary' : 'outline-primary'}
                    name="accountType"
                    value={radio.value}
                    checked={form.accountType === radio.value}
                    onChange={handleChange}
                  >
                    {radio.name}
                  </ToggleButton>
                ))}
              </ButtonGroup>
            </div>
            <hr width="50%" style={{
              alignSelf: "center"
            }}></hr>
            <div className="row _register" >
              <Form.Group controlId="validationCustom11" style={{ width: 'fit-content', padding: '0 0 0 1rem' }}>

                <Form.Check
                  required
                  name="terms"
                  feedback="Por favor acepte las condiciones del servicio"
                  feedbackType="invalid"
                  type="checkbox"
                  onChange={handleChange}
                  label={""}
                /><Form.Control.Feedback type="invalid">
                  Por favor acepte las condiciones del servicio
                </Form.Control.Feedback>
              </Form.Group>
              <a style={{ cursor: "pointer", padding: '2px 0 0', display: 'inline-block', width: 'inherit', 'textDecorationLine': 'underline', verticalAlign: 'middle' }} onClick={() => window.open('/terms', '_blank')
              }>Aceptar condiciones del servicio</a>

            </div>
            <Button
              type="submit"

              style={{ cursor: 'pointer' }}
              className="button _login"
            >
              Registrar Cuenta
            </Button>

          </div>
        </Form>
      </Container>
      <div className="register _login">

        <p> Si ya tienes una cuenta <b><a
          style={{ cursor: 'pointer' }}
          onClick={resetState}>inicia sesión</a></b></p>
      </div >

      <div className="logo _register _footer" style={{ margin: '10% 0 0' }} >
        <img src={logo_footer} />
      </div>

    </div>

  )
}

export default Register_boot