import React, { useState, useEffect } from "react";
import TableList from "components/Table/TableList";
import NavBar from "../../../components/NavBar/NavBar";
import Sidebar from "../../../components/SideBar/SideBar"
import { AccessesSideBar } from "../../../config/sidebar_types";
import dataText from "../../../config/DataText";
import Search from "../../../components/Search/Search";
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';
import Swal from "sweetalert2";
import { getUsers } from "services/services";
import { useSelector } from "react-redux";

const ListPage = () => {
  const [listData, setListData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [loading, setLoading] = useState(false);
  const user = useSelector(state => state.authUser);

  let navigate = useNavigate()
  const menu = AccessesSideBar
  const fieldsToSearch = ["state", "email", "brand", "rol", "group", "created_date", "last_access"];

  const rowHeader = [
    {
      header: dataText.state,
      key: "state",
      type: "text",
    },
    {
      header: dataText.email,
      key: "email",
      type: "text",
    },
    {
      header: dataText.brand,
      key: "brand",
      type: "text",
    },
    {
      header: dataText.rol,
      key: "rol",
      type: "text",
    },
    {
      header: dataText.group,
      key: "group",
      type: "text",
    },
    {
      header: dataText.created_date,
      key: "created_date",
      type: "text", // cambiar a number
    },
    {
      header: dataText.last_access,
      key: "last_access",
      type: "text", // cambiar a number
    },
    {
      header: "",
      key: "actions",
      type: "actions",
      actions: ["delete", "edit"],
    },
  ];

  const fetchList = async () => {
    setLoading(true)
    const type =
      user.activeProfile.account_id
        ? "account"
        : "retailer"

    const id =
      user.activeProfile.account_id
        ? user.activeProfile.account_id
        : user.activeProfile.retailer_id;

    try {

      const { data } = await getUsers(type, id)
      const data_adapted = data.map(x => {
        const user = {
          id: x.id,
          email: x.email,
          state: x.active ? "Activo" : "Inactivo",
          brand: x.brand?.name,
          group: x.group?.name,
          rol: x.roles?.name,
          created_date: x.created_date !== undefined ? x.created_date : "",
          last_login: x.last_login !== undefined ? x.last_login : ""
        }
        return user
      })
      
      setListData(data_adapted);
      setAllData(data_adapted);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchList();
  }, [user])

  const handleEdit = (id) => {
    navigate(`/accesos/usuarios/lista/editar_usuario/${id}`);
  };

  const handleDelete = (id) => {
    Swal.fire({
      title: "Eliminar usuario",
      text: "Esto eliminará el usuario de esta plataforma pero se mantendrán todas las acciones que haya realizado",
      icon: "",
      showCancelButton: true,
      allowEscapeKey: true,
      allowOutsideClick: true,

      cancelButtonColor: "#d20631",
      backdrop: true
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: `Grupo ${id} borrado`,
          text: "Your data has been deleted.",
          icon: "success",
          backdrop: true
        });
      } else {
        return
      }
    });
  };


  return (
    <div style={{ height: '100%' }}>
      <NavBar />

      <div style={{ display: 'flex', height: 'calc(100% - 98px)' }}>
        <Sidebar page={"Users_List"} header={"Accesos"} content={menu} />


        <div style={{ 'overflowY': 'auto', backgroundColor: '#FFFFFF', width: '100%', margin: "52px 0" }}>
          <div className="new_element">
            <div className="d-flex justify-content-between">
              <Search
                allData={allData}
                setListData={setListData}
                fieldsToSearch={fieldsToSearch}
              />
              <Button onClick={() => navigate('/accesos/usuarios/lista/invitar_usuario')} style={{ background: "#001834", color: "white" }}>Invitar usuario</Button>
            </div>
          </div>
          <TableList
            loading={loading}
            rowsData={listData}
            rowHeader={rowHeader}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
            fetchList={fetchList}
          />
        </div>
      </div>


    </div>
  )
}

export default ListPage