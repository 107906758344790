import styles from "./googleSpinner.module.css";

const GoogleSpinner = () => {
  return (
    <div className={styles.spinner}>
      <div className={styles.wrapper}>
        <span className={styles.dot}></span>
        <span className={styles.dot}></span>
        <span className={styles.dot}></span>
        <span className={styles.dot}></span>
      </div>
    </div>
  )
}

export default GoogleSpinner