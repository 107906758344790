import { OperationsSideBar } from "../../../config/sidebar_types";
import React, { useState, useEffect } from "react";
import TableList from "components/Table/TableList";
import NavBar from "../../../components/NavBar/NavBar";
import Sidebar from "../../../components/SideBar/SideBar"
import dataText from "../../../config/DataText";
import Search from "../../../components/Search/Search";
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';
import { RxDownload } from "react-icons/rx";
import Swal from "sweetalert2";
import { getProposals } from "services/services";
import { useSelector } from "react-redux";
import { formatDate } from "utils/utils";

const ListPage = () => {
  const user = useSelector(state => state.authUser);
  const [listData, setListData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [loading, setLoading] = useState(false);

  let navigate = useNavigate()
  const fieldsToSearch = ["id", "brandName", "retailerName", "rfpName", "status", "volumen", "rate", "lastModifiedDate"];

  let rowHeader = [
    {
      header: dataText.proposal_id,
      key: "id",
      type: "text",
    },
    {
      header: dataText.brand_DV,
      key: "brandName",
      type: "text",
    },
    {
      header: dataText.retailer,
      key: "retailerName",
      type: "text",
    },
    {
      header: dataText.rfp_name,
      key: "rfpName",
      type: "text",
    },
    {
      header: dataText.status,
      key: "status",
      type: "text",
    },
    {
      header: dataText.volumen,
      key: "volumen",
      type: "number",
    },
    {
      header: dataText.rate_cmp,
      key: "rate",
      type: "number",
    },
    {
      header: dataText.last_modificacation,
      key: "lastModifiedDate",
      type: "text",
    },
    {
      header: ``,
      key: "actions",
      type: "actions",
      actions: ["edit"],
    },
  ];

  // hide retailer column in table when user.type === retailer
  if(user.type === "retailer") rowHeader = rowHeader.filter(x => x.key !== "retailerName");

  const fetchList = async () => {
    setLoading(true)
    const type = user.type === "retailer"
      ? 'retailer'
      : user.type === "advertiser"
        ? 'brand'
        : null;

    const id = user.activeProfile.brand_id ? user.activeProfile.brand_id : user.activeProfile.retailer_id;
    try {
      const { data } = await getProposals(type, id);
      const mappedData = data.map(x => {
        return {
          id: x.id,
          brandName: x.brand_name,
          retailerName: x.retailer_name,
          rfpName: user.type === "advertiser"
            ? x.brand_proposal_name ? x.brand_proposal_name : x.retailer_proposal_name
            : x.retailer_proposal_name ? x.retailer_proposal_name : x.brand_proposal_name,
          status: x.status.toLowerCase(),
          volumen: x.quantity,
          rate: x.rate,
          lastModifiedDate: x.last_modified_date ? formatDate(x.last_modified_date) : "no record"
        }
      });
      setListData(mappedData);
      setAllData(mappedData);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchList();
  }, [])

  const handleEdit = (id) => {
    navigate(`/operacion/propuestas/${id}`);
  };

  const menu = OperationsSideBar
  return (
    <div style={{ height: '100%' }}>
      <NavBar />

      <div style={{ display: 'flex', height: 'calc(100% - 98px)' }}>
        <Sidebar page={"Proposals_List"} header={"Operación"} content={menu} />


        <div style={{ 'overflowY': 'auto', backgroundColor: '#FFFFFF', width: '100%', margin: "52px 0" }}>
          <div className="new_element">
            <div className="d-flex justify-content-between">
              <Search
                allData={allData}
                setListData={setListData}
                fieldsToSearch={fieldsToSearch}
              />
              {/* <Button onClick={() => navigate('/accesos/usuarios/lista/invitar_usuario')} style={{background:"#001834",color:"white"}}>{dataText.new_negociacion}</Button>         */}
            </div>
          </div>
          <TableList
            loading={loading}
            rowsData={listData}
            rowHeader={rowHeader}
            handleEdit={handleEdit}
            fetchList={fetchList}
          />
        </div>
      </div>



    </div>
  )
}

export default ListPage