import { ReportingSideBar } from "../../config/sidebar_types";
import React, { useState, useEffect } from "react";
import TableList from "components/Table/TableList";
import NavBar from "../../components/NavBar/NavBar";
import Sidebar from "../../components/SideBar/SideBar"
import dataText from "../../config/DataText";
import Search from "../../components/Search/Search";
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';
import { RxDownload } from "react-icons/rx";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { getReporting } from "services/services";



const ListPage = () => {
  const [listData, setListData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [loading, setLoading] = useState(false);
  const user = useSelector(state => state.authUser);


  let navigate = useNavigate()
  const menu = ReportingSideBar
  const fieldsToSearch = ["retailer", "brand", "deal_id", "order", "impressions", "clicks", "crt", "progress"];

  let rowHeader = [
    {
      header: dataText.retailer,
      key: "retailer",
      type: "text",
    },
    {
      header: dataText.brand,
      key: "brand",
      type: "text",
    },
    {
      header: dataText.deal_id,
      key: "deal_id",
      type: "text",
    },
    {
      header: dataText.order,
      key: "order",
      type: "number",
    },
    {
      header: dataText.impressions,
      key: "impressions",
      type: "number",
    },
    {
      header: dataText.clicks,
      key: "clicks",
      type: "number",
    },
    {
      header: dataText.crt,
      key: "crt",
      type: "number",
    },
    {
      header: dataText.progress,
      key: "progress",
      type: "number",
    },
    {
      header: ``,
      key: "actions",
      type: "actions",
      actions: [],
    },
  ];

   // hide retailer column in table when user.type === retailer
   if(user.type === "retailer") rowHeader = rowHeader.filter(x => x.key !== "retailer");

  const data = [
    {
      "id": 1,
      "retailer": 27,
      "brand": "Loreal(135215)",
      "deal_id": "ECI",
      "order": 15,
      "impressions": 0.12,
      "clicks": 300,
      "crt": 2.1,
      "progress": 13,
    },
    {
      "id": 2,
      "retailer": 27,
      "brand": "Loreal(135215)",
      "deal_id": "ECI",
      "order": 15,
      "impressions": 0.12,
      "clicks": 300,
      "crt": 2.1,
      "progress": 13,
    },
    {
      "id": 3,
      "retailer": 27,
      "brand": "Loreal(135215)",
      "deal_id": "ECI",
      "order": 15,
      "impressions": 0.12,
      "clicks": 300,
      "crt": 2.1,
      "progress": 13,
    },
    {
      "id": 4,
      "retailer": 27,
      "brand": "Loreal(135215)",
      "deal_id": "ECI",
      "order": 15,
      "impressions": 0.12,
      "clicks": 300,
      "crt": 2.1,
      "progress": 13,
    },
    {
      "id": 5,
      "retailer": 27,
      "brand": "Loreal(135215)",
      "deal_id": "ECI",
      "order": 15,
      "impressions": 0.12,
      "clicks": 300,
      "crt": 2.1,
      "progress": 13,
    },
    {
      "id": 6,
      "retailer": 27,
      "brand": "Loreal(135215)",
      "deal_id": "ECI",
      "order": 15,
      "impressions": 0.12,
      "clicks": 300,
      "crt": 2.1,
      "progress": 13,
    },
    {
      "id": 7,
      "retailer": 27,
      "brand": "Loreal(135215)",
      "deal_id": "ECI",
      "order": 15,
      "impressions": 0.12,
      "clicks": 300,
      "crt": 2.1,
      "progress": 13,
    },
    {
      "id": 8,
      "retailer": 27,
      "brand": "Loreal(135215)",
      "deal_id": "ECI",
      "order": 15,
      "impressions": 0.12,
      "clicks": 300,
      "crt": 2.1,
      "progress": 13,
    },
    {
      "id": 9,
      "retailer": 27,
      "brand": "Loreal(135215)",
      "deal_id": "ECI",
      "order": 15,
      "impressions": 0.12,
      "clicks": 300,
      "crt": 2.1,
      "progress": 13,
    }
  ]


  const fetchList = async () => {
    setLoading(true)
    try {
      const userid = user.id
      const accountid = user.activeProfile.account_id
      const type = user.type === "retailer"
      ? 'retailer'
      : user.type === "advertiser"
        ? 'brand'
        : null;
      const id = user.activeProfile.brand_id ? user.activeProfile.brand_id : user.activeProfile.retailer_id;
      const response = await getReporting(userid, type === "brand" ? accountid : undefined , type === "brand" ? id : undefined, type === "retailer" ? id : undefined);
      // TODO update with response.data
      const data = response.data.map(x => {
        const negotiation = {
          // Check all fields
          id: x.order_id,
          retailer: x.retailer_name,
          brand: x.brand_name,
          deal_id: x.programmatic_deal_id,
          order: x.order_name,
          impressions: x.total_line_item_level_impressions,
          clicks: x.total_line_item_level_clicks,
          crt: x.total_line_item_level_clicks_ctr,
          progress: x.progress+"%",
        };

        return negotiation
      });
      setListData(data);
      setAllData(data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchList();
  }, [user])

  const handleEdit = (id) => {
    navigate(`/accesos/usuarios/lista/editar_usuario/${id}`);
  };


  return (
    <div style={{ height: '100%' }}>
      <NavBar />

      <div style={{ display: 'flex', height: 'calc(100% - 98px)' }}>
        <Sidebar page={"Reporting_List"} header={"Reporting"} content={menu} />


        <div style={{ 'overflowY': 'auto', backgroundColor: '#FFFFFF', width: '100%' }}>
          <div className="new_element">
            <div className="d-flex justify-content-between">
              <Search
                allData={allData}
                setListData={setListData}
                fieldsToSearch={fieldsToSearch}
              />
            </div>
          </div>
          <TableList
            loading={loading}
            rowsData={listData}
            rowHeader={rowHeader}
            handleEdit={handleEdit}
            //   handleDelete={handleDelete}
            fetchList={fetchList}
          />
        </div>
      </div>


    </div>
  )
}

export default ListPage