import { useEffect, useState } from "react";
import './search.css'

export default function Search({
  allData,
  setListData,
  fieldsToSearch,
}) {
  const [search, setSearch] = useState("");

  const handleChange = (e) => {
    setSearch(e.target.value);
  };

  const filtered = () => {
    const tempData = allData.filter((data) => {
      let value = false;
      // eslint-disable-next-line array-callback-return
      fieldsToSearch.map((field) => {
        if (field === "messageInNotificationsListPage") {
          if (data[field].props.children[0] &&
            data[field].props.children[0].toLowerCase().includes(search.toLowerCase()))
            value = true;
        } else {
          if (data[field] &&
            data[field].toString().toLowerCase().includes(search.toLowerCase()))
            value = true;
        }
      });
      return value;
    });
    setListData(tempData);
  };

  useEffect(() => {
    filtered();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  return (
    <div className="position-relative ">
      <input
        type="search"
        className="form-control ps-4"
        placeholder="Buscar..."
        value={search}
        onChange={handleChange}
      />
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="input-icon"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" />
      </svg>
    </div>
  );
}
