import { useState } from "react";
import axios from "axios";
import jwtDecode from "jwt-decode";
import { useDispatch } from "react-redux";
import { setAuthUser, setRetailerId, setAccountId, setSrmToken } from "state/appSlice";
import { useNavigate } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import { Button, Form, Container } from 'react-bootstrap';
import logo from '../../assets/images/logotipo-white-bmind-jakala-company 1.png';
import logo_footer from '../../assets/images/logotipo-white-bmind-jakala-company 4.png';
import { callServer, getBrand, getRetailer } from "services/services";
import Swal from "sweetalert2";
import { socket } from "services/socket"
import styles from "./login.module.css";

const Login = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const intialFormState = {
    email: "",
    password: ""
  }

  const [form, setForm] = useState(intialFormState);
  const [loading, setLoading] = useState(false);

  const handleChange = e => {
    setForm(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value
    }))
  };

  const resetState = () => {
    navigate('/register')
  }

  const [validated, setValidated] = useState(false);

  const handleSubmit = async e => {
    setLoading(true);
    e.preventDefault();
    const form_check = e.currentTarget;
    if (!form_check.checkValidity()) {
      e.stopPropagation();
      // TODO review this validated
      setValidated(true);
    }
    else {

      const response = await axios
        .post(`${callServer}/login`, {
          headers: { 'content-type': 'application/json' },
          withCredentials: true,
          ...form
        })
        .catch(err => {
          setLoading(false)
          setValidated(false);
          console.log(err);
          dispatch(setAuthUser(null));

          Swal.fire({
            text: "Email o contraseña incorrectos!",
            icon: "error",
            allowEscapeKey: true,
            allowOutsideClick: true,
            backdrop: false
          }).then(() => {
            window.close();
          });
        })

      if (response?.request?.status === 200) {
        // TODO obtener info del token
        const decoded = jwtDecode(response.data.token);
        // setAuthToken(response.data.token); // set token for axios calls
        localStorage.setItem("srmToken", response.data.token);

        dispatch(setSrmToken(response.data.token));

        const info = response.data.info;
        const profiles = [...info];

        for (let i = 0; i < profiles.length; i++) {
          const elem = profiles[i];
          if (elem.brand_id) {
            const res = await getBrand(elem.brand_id).catch()
            elem.logo = res?.data?.logo || null;
          }
          // TODO retailer endpoint is not ok, returns 404
          else if(elem.retailer_id){
            const res = await getRetailer(elem.retailer_id).catch()
            elem.logo = res?.data?.logo || null;
          }
        }
        dispatch(setAuthUser({
          id: response.data.user_id,
          email: decoded.email,
          profiles: profiles,
          activeProfile: profiles[0],
          type: profiles[0].retailer_id ? 'retailer' : 'advertiser',
          roleName: response.data.info[0].role_name
        }));
        
        navigate('/home');
      }

    }
  }


  return (
    <div className="background-login">
      <Container className="d-flex align-item justify-content-center aligns-items-center mt-0">

        <Form onSubmit={handleSubmit} noValidate validated={validated} className="form_box _login">
          <img src={logo} className="logo _login" />
          <div className="fields _login">
            <Form.Group controlId="validationCustom01" className="field _login">
              <Form.Label className="label _login">E-mail</Form.Label>
              <Form.Control
                /*className="text_field _custom"*/
                type="email"
                name="email"
                placeholder="Introduzca su correo electrónico"
                onChange={handleChange}
                required
              />
              <Form.Control.Feedback type="invalid">
                {form.email === "" ? "Introduzca un E-mail." : "El formato del E-mail no es adecuado."}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="field _login">
              <Form.Label className="label _login">Contraseña</Form.Label>
              <Form.Control
                type="password"
                name="password"
                placeholder="Introduzca su contraseña"
                onChange={handleChange}
                required
              />
              <Form.Control.Feedback type="invalid">
                {"Introduzca una contraseña."}
              </Form.Control.Feedback>
            </Form.Group>
            <a
              style={{ cursor: 'pointer', margin: '0.5rem 0 0 0', 'textDecorationLine': 'underline', width: 'max-content' }}
              onClick={() => navigate('/restablecer')
              }>Recuperar contraseña.</a>
            {
              <Button
                type="submit"
                style={{ cursor: 'pointer' }}
                className="button _login"
              >
                {!!loading ? (
                  <Spinner className={styles.spinner}/>
                ) : (
                  <>Log In</>
                )

                }
              </Button>

            }
          </div>


        </Form>
      </Container>
      {/* 
      <div className="register _login">
        <p> ¿No tienes cuenta? <b><a
          style={{ cursor: 'pointer' }}
          onClick={resetState}>Crear cuenta</a></b></p>
      </div >
      */}
      <div className="logo  _footer">
        <img src={logo_footer} />
      </div>


    </div>

  )
}

export default Login