const Dropdown = ({ open, trigger, menu }) => {
return (
  <div className="dropdown">
    {trigger}
    {open ? (
      <ul style={{padding: '0 0 0 1rem'}}>
        {menu.map((menuItem) => (
          menuItem
        ))}
      </ul>
    ) : null}
  </div>
);
};

export default Dropdown;