import { BrowserRouter, Navigate, Routes, Route, useNavigate } from "react-router-dom";
import HomePage from "./pages/HomePage/HomePage";
import OverviewPage from "pages/OverView/Overview";
// Operation
import RetailersConnectionsPage from "pages/Operation/Retailers/ConnectionsPage";
import NegotiationsListPage from "pages/Operation/Negotiations/ListPage";
import NegotiationsCreatePage from "pages/Operation/Negotiations/CreatePage";
import NegotiationsFinalConfigPage from "pages/Operation/Negotiations/FinalConfigPage";
import NegotiationsChatPage from "pages/Operation/Negotiations/Negotiation";
import ProposalsListPage from "pages/Operation/Proposals/ListPage"
import CurrentNegotiationPage from "pages/Operation/Negotiations/CurrentNegotiationPage";
import TagsListPage from "pages/Operation/Tags/ListPage"
import TagsNewPage from "pages/Operation/Tags/NewPage"
import NotificationsListPage from "pages/Operation/Notifications/ListPage"
//Reporting
import ReportingListPage from "pages/Reporting/ListPage"
// Configuration
import BrandsListPage from "pages/Configuration/Brands/ListPage"
import BrandsNewPage from "pages/Configuration/Brands/NewPage"
import ConnectionsListPage from "pages/Configuration/Connections/ListPage"
import EditConnection from "pages/Configuration/Connections/EditConnection";
import InventoryListPage from "pages/Configuration/Inventory/ListPage"
import Placements from "pages/Configuration/Inventory/Placements";
import SubscriptionFDataPage from "pages/Configuration/Subscription/FDataPage"

// Accesos
import UsersListPage from "pages/Accesses/Users/ListPage"
import GroupsListPage from "pages/Accesses/Groups/ListPage"
import InvitarEditarUsuario from "pages/Accesses/Users/InvitarEditarUsuario"
import CrearEditarGrupo from "pages/Accesses/Groups/CrearEditarGrupo";
// Admin
import AccountsListPage from "pages/Admin/Accounts/ListPage"
import AccountsCreatePage from "pages/Admin/Accounts/CreatePage"

import LoginSuccessApis from "pages/LoginSuccessApis/LoginSuccessApis";
import Login from "pages/Login/Login_boot";
import Register from "pages/Login/Register_boot";
import Restablecer from "pages/Login/Restablecer";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { verifySuperUserRole } from "utils/utils";
import { socket } from "services/socket"
function App() {
  const user = useSelector((state) => state.authUser)
  const dispatch = useDispatch();
  const isSU = verifySuperUserRole(user)
  useEffect(() => {
    function onConnect() {
      console.log(`I'm connected with the back-end`);
      socket.emit('join', "user_"+user.id)
      socket.emit('join', user.type === "advertiser" ? "brand_"+user.activeProfile.brand_id :  "ret_"+user.activeProfile.retailer_id)
    }

    function onDisconnect() {
      console.log("disconected")
    }

    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);
    //socket.on('new message', (message) => {
    //  console.log("Received: " + message)
    //})

    if (user) {
      socket.connect()
      
    }

    return () => {
      socket.off('connect', onConnect);
      socket.off('disconnect', onDisconnect);
    };
  }, [user]);

  return (
    <div className="app">
      <BrowserRouter>
          <Routes>
            <Route path="/overview" element={user ? isSU ? <Navigate to="/admin/cuentas/lista"/> : <OverviewPage /> : <Navigate to="/login" />} />
            <Route path="/operacion/retailers/conexiones" element={user ? isSU ? <Navigate to="/admin/cuentas/lista"/> : <RetailersConnectionsPage /> : <Navigate to="/login" />} />
            <Route path="/operacion/negociaciones/lista" element={user ? isSU ? <Navigate to="/admin/cuentas/lista"/> : <NegotiationsListPage /> : <Navigate to="/login" />} />
            <Route path="/operacion/negociaciones/crear" element={user ? isSU ? <Navigate to="/admin/cuentas/lista"/> : <NegotiationsCreatePage /> : <Navigate to="/login" />} />
            <Route path="/operacion/negociaciones/aceptacion/:id" element={user ? isSU ? <Navigate to="/admin/cuentas/lista"/> : <NegotiationsFinalConfigPage /> : <Navigate to="/login" />} />
            <Route path="/operacion/negociaciones/chat/:id" element={user ? isSU ? <Navigate to="/admin/cuentas/lista"/> : <NegotiationsChatPage /> : <Navigate to="/login" />} />
            <Route path="/operacion/propuestas/lista" element={user ? isSU ? <Navigate to="/admin/cuentas/lista"/> : <ProposalsListPage /> : <Navigate to="/login" />} />
            <Route path="/operacion/propuestas/:id" element={user ? isSU ? <Navigate to="/admin/cuentas/lista"/> : <CurrentNegotiationPage /> : <Navigate to="/login" />} />
            <Route path="/operacion/tags/lista" element={user ? isSU ? <Navigate to="/admin/cuentas/lista"/> : <TagsListPage /> : <Navigate to="/login" />} />
            <Route path="/operacion/tags/nuevo" element={user ? isSU ? <Navigate to="/admin/cuentas/lista"/> : <TagsNewPage /> : <Navigate to="/login" />} />
            <Route path="/operacion/notificaciones/lista" element={user ? isSU ? <Navigate to="/admin/cuentas/lista"/> : <NotificationsListPage /> : <Navigate to="/login" />} />
            <Route path="/reporting/lista" element={user ? isSU ? <Navigate to="/admin/cuentas/lista"/> : <ReportingListPage /> : <Navigate to="/login" />} />
            <Route path="/configuracion/marcas/lista" element={user ? isSU ? <Navigate to="/admin/cuentas/lista"/> : <BrandsListPage /> : <Navigate to="/login" />} />
            <Route path="/configuracion/marcas/nueva" element={user ? isSU ? <Navigate to="/admin/cuentas/lista"/> : <BrandsNewPage /> : <Navigate to="/login" />} />
            <Route path="/configuracion/marcas/editar/:id" element={user ? isSU ? <Navigate to="/admin/cuentas/lista"/> : <BrandsNewPage /> : <Navigate to="/login" />} />
            <Route path="/configuracion/conexiones/lista" element={user ? isSU ? <Navigate to="/admin/cuentas/lista"/> : <ConnectionsListPage /> : <Navigate to="/login" />} />
            <Route path="/configuracion/conexiones/editar/:id" element={user ? isSU ? <Navigate to="/admin/cuentas/lista"/> : <EditConnection /> : <Navigate to="/login" />} />
            <Route path="/configuracion/inventario/lista" element={user ? isSU ? <Navigate to="/admin/cuentas/lista"/> : <InventoryListPage /> : <Navigate to="/login" />} />
            <Route path="/configuracion/inventario/lista/placement/:id" element={user ? isSU ? <Navigate to="/admin/cuentas/lista"/> : <Placements /> : <Navigate to="/login" />} />
            <Route path="/configuracion/suscripcion/datos_fiscales" element={user ? isSU ? <Navigate to="/admin/cuentas/lista"/> : <SubscriptionFDataPage /> : <Navigate to="/login" />} />
            <Route path="/accesos/usuarios/lista" element={user ? isSU ? <Navigate to="/admin/cuentas/lista"/> : <UsersListPage /> : <Navigate to="/login" />} />
            <Route path="/accesos/usuarios/lista/invitar_usuario" element={user ? isSU ? <Navigate to="/admin/cuentas/lista"/> : <InvitarEditarUsuario /> : <Navigate to="/login" />} />
            <Route path="/accesos/usuarios/lista/editar_usuario/:id" element={user ? isSU ? <Navigate to="/admin/cuentas/lista"/> : <InvitarEditarUsuario /> : <Navigate to="/login" />} />
            <Route path="/accesos/grupos/lista" element={user ? <GroupsListPage /> : isSU ? <Navigate to="/admin/cuentas/lista"/> : <Navigate to="/login" />} />
            <Route path="/accesos/grupos/lista/crear_grupo" element={user ? isSU ? <Navigate to="/admin/cuentas/lista"/> : <CrearEditarGrupo /> : <Navigate to="/login" />} />
            <Route path="/accesos/grupos/lista/editar_grupo/:id" element={user ? isSU ? <Navigate to="/admin/cuentas/lista"/> : <CrearEditarGrupo /> : <Navigate to="/login" />} />
            <Route path="/admin/cuentas/lista" element={user ? <AccountsListPage /> : <Navigate to="/login" />} />
            <Route path="/admin/cuentas/crear" element={user ? <AccountsCreatePage /> : <Navigate to="/login" />} />
            <Route path="/login" element={user ? <Navigate to="/overview" /> : <Login />} />
            <Route path="/register" element={user ? <Navigate to="/overview" /> :<Register />} />
            <Route path="/restablecer" element={user ? <Navigate to="/overview" /> :<Restablecer />} />
            <Route path="/apis-login-success" element={<LoginSuccessApis />} />
            <Route path="*" element={user ? isSU ? <Navigate to="/admin/cuentas/lista"/> : <Navigate to="/overview" /> : <Navigate to="/login" />} />
          </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
