import React, { useState, useEffect } from "react";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import NavBar from "../../../components/NavBar/NavBar";
import Sidebar from "../../../components/SideBar/SideBar"
import { AccessesSideBar } from "../../../config/sidebar_types";
import Select from 'react-select';
import dataText from "../../../config/DataText";
import { getAllBrands, getAllGroups } from "../../../services/services";
import { useNavigate, useParams } from 'react-router-dom';


const CrearEditarGrupo = () => {
  const [brands, setBrands] = useState([])
  const [roles, setRoles] = useState([])
  const [groups, setGroups] = useState([])
  const { id } = useParams();
  let navigate = useNavigate()

  const menu = AccessesSideBar

  const colourOptions = [
    { value: 'ocean', label: 'Ocean', color: '#00B8D9', isFixed: true },
    { value: 'blue', label: 'Blue', color: '#0052CC', isDisabled: true },
    { value: 'purple', label: 'Purple', color: '#5243AA' },
    { value: 'red', label: 'Red', color: '#FF5630', isFixed: true },
    { value: 'orange', label: 'Orange', color: '#FF8B00' },
    { value: 'yellow', label: 'Yellow', color: '#FFC400' },
    { value: 'green', label: 'Green', color: '#36B37E' },
    { value: 'forest', label: 'Forest', color: '#00875A' },
    { value: 'slate', label: 'Slate', color: '#253858' },
    { value: 'silver', label: 'Silver', color: '#666666' },
  ];


  const fetchList = async () => {
    try {
      const allBrands = await getAllBrands()
      const allGroups = await getAllGroups()
      setBrands(allBrands)
      setGroups(allGroups)
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    fetchList();
  }, [])

  const onSubmit = (data, e) => {
    e.preventDefault();
    navigate('/accesos/grupos/lista')
  };


  return (
    <div style={{ height: '100%' }}>
      <NavBar />

      <div style={{ display: 'flex', height: 'calc(100% - 98px)' }}>
        <Sidebar page={"Connections_List"} header={"Configuración"} content={menu} />


        <div style={{ 'overflowY': 'auto', backgroundColor: '#FFFFFF', width: '100%' }}>
          <div className='container-fluid' style={{ padding: "3.5rem 3.5rem" }}>
            <div className="row">
              <div className="col-3">
                <Form
                  onSubmit={onSubmit}
                >
                  <h4>{id ? dataText.editar_grupo : dataText.crear_grupo}</h4>
                  <Form.Group className="mb-3">
                    <Form.Label>Nombre de grupo</Form.Label>
                    <Form.Control type="text" placeholder="Introduzca nombre de grupo" />
                  </Form.Group>
                  <Form.Group className="mb-3" >
                    <Form.Label>Rol de grupo</Form.Label>
                    <Select
                      isClearable
                      isSearchable
                      name="colourOptions"
                      options={colourOptions}
                      placeholder="Seleccione Rol"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" >
                    <Form.Label>Usuarios</Form.Label>
                    <Select
                      isMulti
                      isClearable
                      isSearchable
                      name="colourOptions"
                      options={colourOptions}
                      placeholder="Seleccione el grupo"
                    />
                  </Form.Group>
                  <div className="d-flex justify-content-between">
                    <span></span>
                    <Button style={{ backgroundColor: "#001834", color: "white" }} type="submit">
                      {id ? dataText.guardar : dataText.crear_grupo}
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CrearEditarGrupo