import React from 'react'
import NavBar from "../../../components/NavBar/NavBar";
import Sidebar from "../../../components/SideBar/SideBar"
import { ConfigurationSideBarRetailer, ConfigurationSideBarAdvertiser } from "../../../config/sidebar_types";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import dataText from "config/DataText";
import { useSelector } from 'react-redux';
import Spinner from "react-bootstrap/Spinner";
import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { getInventory, patchInventory } from 'services/services';
import { useNavigate } from 'react-router-dom';

const Placements = () => {
  const { id } = useParams();
  const user = useSelector(state => state.authUser);
  const [loading, setLoading] = useState(false);
  const menu = user.type === "advertiser" ? ConfigurationSideBarAdvertiser : ConfigurationSideBarRetailer
  const [placement, setPlacement] = useState({})
  const navigate = useNavigate();
  const [validated, setValidated] = useState(false)

  const fetchData = async e => {
    setLoading(true)
    
    try {
      const { data } = await getInventory(user.activeProfile.retailer_id);
      const data_filtered = data.filter((item) => id == item.id)[0]
      const data_placement = {
        placement_id: data_filtered.external_id,
        placement_name: data_filtered.external_name,
        alias: data_filtered.alias,
        active: data_filtered.active,
      }
      setPlacement(data_placement)
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false)
    }
    }

  useEffect(() => {
    fetchData();
  }, [user])

  const handleSubmit = async e => {
    e.preventDefault();
    const form_val = e.currentTarget;
    if (form_val.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true)
    }
    else {
      const form_values = {"alias": placement.alias, "active": placement.active};
      patchInventory(id, form_values).then(result => {
          navigate("/configuracion/inventario/lista")
        }).catch(error => {
          console.log(error)
        })
    }
  }


  return (
    <div style={{ height: '100%' }}>
      <NavBar />

      <div style={{ display: 'flex', height: 'calc(100% - 98px)' }}>
        <Sidebar page={"Inventory_List"} header={"Configuración"} content={menu} />


        <div style={{ 'overflowY': 'auto', backgroundColor: '#FFFFFF', width: '100%' }}>
          <div className='container-fluid' style={{ padding: "3.5rem 3.5rem" }}>
            <div className="row">
              <div className="col-6">
                <Form
                onSubmit={handleSubmit}
                noValidate validated={validated}
                >
                  <h4>Placement</h4>
                  {loading && (
                    <div className="d-flex justify-content-center">
                      <Spinner animation="border" style={{ color: "#222e73" }} />
                    </div>
                  )}
                  <Form.Group className="mb-3" >
                    <Form.Label>{dataText.placement_id}</Form.Label>
                    <Form.Control type="text" disabled value={placement.placement_id}/>
                  </Form.Group>
                  <Form.Group className="mb-3" >
                    <Form.Label>{dataText.placement_name}</Form.Label>
                    <Form.Control type="text" disabled value={placement.placement_name}/>
                  </Form.Group>
                  <Form.Group className="mb-3" >
                    <Form.Label>SRM Alias</Form.Label>
                    <Form.Control type="text" value={placement.alias}
                    onChange={(e)=>setPlacement( (prevState) => (
                      {
                      ...prevState,
                      alias: e.target.value
                      }))
                    }/>
                  </Form.Group>
                  <Form.Group className="mb-3" >
                    <Form.Label>Habilitar</Form.Label>
                    <Form.Check
                      type="checkbox"
                      id="default"
                      label="Esto habilita a los Advertisers poder ver este placement bajo el nombre que usted haya especificado en SRM Alias"
                      checked={placement.active}
                      onChange={()=>setPlacement( (prevState) => (
                        {
                        ...prevState,
                        active: (!placement.active)
                        }))
                      }
                    />
                  </Form.Group>
                  <div className="d-flex justify-content-between">
                    <span></span>
                    <Button style={{ backgroundColor: "#001834", color: "white" }} type="submit">
                      {dataText.guardar}
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>


    </div>
  )
}

export default Placements