const OperationsSideBar = [{ title: "Retailers", title_text: "Retailers", items: [{ page: "Retailers_Connections", text: "Conexiones", navigation: "/operacion/retailers/conexiones" }] },
{ title: "Negotiations", title_text: "Negociaciones", items: [{ page: "Negotiations_List", text: "Lista", navigation: "/operacion/negociaciones/lista" }, { page: "Negotiations_Create", text: "Crear", navigation: "/operacion/negociaciones/crear" }] },
{ title: "Proposals", title_text: "Propuestas", items: [{ page: "Proposals_List", text: "Lista", navigation: "/operacion/propuestas/lista" }] },
// it's commented till a new order
// {title: "Tags", title_text: "Tags", items: [{page: "Tags_List", text: "Lista", navigation: "/operacion/tags/lista"}, {page: "Tags_New", text: "Nuevo", navigation: "/operacion/tags/Nuevo"}]},                              
{ title: "Notifications", title_text: "Notificaciones", items: [{ page: "Notifications_List", text: "Lista", navigation: "/operacion/notificaciones/lista" }] }
]

const OperationsSideBarSuperUser = [                             
{ title: "Notifications", title_text: "Notificaciones", items: [{ page: "Notifications_List", text: "Lista", navigation: "/operacion/notificaciones/lista" }] }
]

const ConfigurationSideBarRetailer = [
  { title: "Connections", title_text: "Conexiones", items: [{ page: "Connections_List", text: "Lista", navigation: "/configuracion/conexiones/lista" }] },
  { title: "Inventory", title_text: "Inventario", items: [{ page: "Inventory_List", text: "Lista", navigation: "/configuracion/inventario/lista" }] },
  { title: "Subscription", title_text: "Suscripción", items: [{ page: "Subscription_FData", text: "Datos Fiscales", navigation: "/configuracion/suscripcion/datos_fiscales" }] },
]

const ConfigurationSideBarAdvertiser = [{ title: "Brands", title_text: "Marcas", items: [{ page: "Brands_List", text: "Lista", navigation: "/configuracion/marcas/lista" }, { page: "Brands_New", text: "Nueva", navigation: "/configuracion/marcas/nueva" }] },
{ title: "Connections", title_text: "Conexiones", items: [{ page: "Connections_List", text: "Lista", navigation: "/configuracion/conexiones/lista" }] },
{ title: "Subscription", title_text: "Suscripción", items: [{ page: "Subscription_FData", text: "Datos Fiscales", navigation: "/configuracion/suscripcion/datos_fiscales" }] },
]

const AccessesSideBar = [{ title: "Users", title_text: "Usuarios", items: [{ page: "Users_List", text: "Lista", navigation: "/accesos/usuarios/lista" }] },
{ title: "Groups", title_text: "Grupos", items: [{ page: "Groups_List", text: "Lista", navigation: "/accesos/grupos/lista" }] }
]

const AdminSideBar = [{ title: "Accounts", title_text: "Cuentas", items: [{ page: "Accounts_List", text: "Lista", navigation: "/admin/cuentas/lista" }, { page: "Accounts_Create", text: "Crear", navigation: "/admin/cuentas/crear" }] }]

const ReportingSideBar = [{ title: "Reporting", title_text: "Reporting", items: [{ page: "Reporting_List", text: "Lista", navigation: "/reporting/lista" }] }]

export { OperationsSideBar, ConfigurationSideBarRetailer, ConfigurationSideBarAdvertiser, AccessesSideBar, AdminSideBar, ReportingSideBar, OperationsSideBarSuperUser }