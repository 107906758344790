import React, { useState } from "react";
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Image from "react-bootstrap/Image";
import Button from 'react-bootstrap/Button';
import NavBar from "../../../components/NavBar/NavBar";
import { ButtonGroup, ToggleButton } from 'react-bootstrap';
import Sidebar from "../../../components/SideBar/SideBar"
import { AdminSideBar } from "../../../config/sidebar_types";
import dataText from "../../../config/DataText";
import { useNavigate, useParams } from 'react-router-dom';
import { registerUser } from "services/services";
import Swal from "sweetalert2";
import styles from "./createpage.module.css";


const ListPage = () => {
  const [img, setImg] = useState('')
  const { id } = useParams();
  let navigate = useNavigate()
  const radios = [
    { name: 'Retailer', value: 'RETAILER' },
    { name: 'Advertiser', value: 'ADVERTISER' },
  ];

  const menu = AdminSideBar

  const intialFormState = {
    email: "",
    emailConfirm: "",
    name: "",
    contact_person: "",
    phone: "",
    cif: "",
    address: "",
    postal_code: "",
    city: "",
    type: "RETAILER",
    conditions_accepted: false,
    status: "PENDING",
    logo: ""
  }

  const [form, setForm] = useState(intialFormState);
  const uploadFileHanlder = (e) => {
    if(e.target.files.length === 0 || e.target.files[0].type === ""){
      setImg("")
      setForm(prevState => ({
        ...prevState,
        ["logo"]: ""
      }))
      e.target.value = null
      return
    }
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      const image_result = reader.result
      setImg(image_result);
      setForm(prevState => ({
        ...prevState,
        ["logo"]: image_result
      }))
    });
    reader.readAsDataURL(e.target.files[0]);
  }
  const handleChange = e => {
    console.log(e.target.checked)
    const value = e.target.name !== "conditions_accepted" ? e.target.value : e.target.checked
    setForm(prevState => ({
      ...prevState,
      [e.target.name]: value
    }))
  };

  const confirmErrors = {
    emailError: form.email !== form.emailConfirm,
  }
  const [validated, setValidated] = useState(false);

  const handleSubmit = async e => {
    e.preventDefault();
    const checkForm = e.currentTarget;
    if (!checkForm.checkValidity() || confirmErrors.emailError) {
      e.stopPropagation();
      setValidated(true);
    }
    else {
      setValidated(true);
      try {
        const { emailConfirm, ...body } = form;
        console.log(body)
        const response = await registerUser({...body})
        if (response?.request?.status === 201) {
            navigate('/accesos/usuarios/lista');
        }
        else{
          Swal.fire({
            text: "Error en el registro!",
            icon: "error",
            allowEscapeKey: true,
            allowOutsideClick: true,
            backdrop: false
          })
        }
      } catch (err) {
        console.log(err);
        
        Swal.fire({
          text: "Error en el registro!",
          icon: "error",
          allowEscapeKey: true,
          allowOutsideClick: true,
          backdrop: false
        })
      }
    }
  }

  return (
    <div style={{ height: '100%' }}>
      <NavBar />

      <div style={{ display: 'flex', height: 'calc(100% - 98px)' }}>
        <Sidebar page={"Accounts_Create"} header={"Administración"} content={menu} />


        <div style={{ 'overflowY': 'auto', backgroundColor: '#FFFFFF', width: '100%' }}>
          <div className='container-fluid' style={{ padding: "3.5rem 3.5rem" }}>
            <div className="row">
              <div className="col-3">
                <Form
                  onSubmit={handleSubmit} noValidate validated={validated}
                >
                  <h4>Crear Cuenta</h4>
                  <Form.Group className="mb-3" >
                    <Form.Label>Nombre de cuenta</Form.Label>
                    <Form.Control type="text"
                    name="name"
                    placeholder="Introduzca el nombre de la cuenta"
                    onChange={handleChange}
                    required />
                    <Form.Control.Feedback type="invalid">
                      Por favor introduzca el nombre de la cuenta
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>E-mail</Form.Label>
                    <Form.Control
                    type="email"
                    name="email"
                    placeholder="Introduzca su correo electrónico"
                    onChange={handleChange}
                    required
                    />
                    <Form.Control.Feedback type="invalid">
                      {form.email === "" ? "Introduzca un E-mail" : "El formato del E-mail no es adecuado."}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Introduzca de nuevo el E-mail</Form.Label>
                    <Form.Control type="email"
                      name="emailConfirm"
                      isInvalid={confirmErrors.emailError}
                      placeholder="Introduzca de nuevo su correo electrónico"
                      onChange={handleChange}
                      required/>
                    <Form.Control.Feedback type="invalid">
                    {confirmErrors.emailError ? "Los E-mail no coinciden" : (form.emailConfirm === "" ? "Por favor vuelva a introducir el E-mail" : "El formato del email no es el adecuado")}
                  </Form.Control.Feedback>
                  </Form.Group>
                  
                  <Form.Group className="mb-3" >
                    <Form.Label>Nombre persona de contacto</Form.Label>
                    <Form.Control type="text"
                    name="contact_person"
                    placeholder="Introduzca el nombre de la persona de contacto"
                    onChange={handleChange}
                    required/>
                  </Form.Group>
                  <Form.Group className="mb-3" >
                    <Form.Label>Teléfono de contacto</Form.Label>
                    <Form.Control
                      type="tel"
                      name="phone"
                      placeholder="Introduzca el teléfono de contacto"
                      onChange={handleChange}
                      required/>
                  </Form.Group>
                  <Form.Control.Feedback type="invalid">
                    Por favor introduzca el teléfono de contacto
                  </Form.Control.Feedback>
                  <hr></hr>
                  <Form.Group className="mb-3" >
                    <Form.Label>CIF</Form.Label>
                    <Form.Control
                      type="text"
                      name="cif"
                      placeholder="Introduzca el CIF"
                      onChange={handleChange}
                      required/>
                  <Form.Control.Feedback type="invalid">
                    Por favor introduzca el CIF
                  </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-3" >
                    <Form.Label>Direccion</Form.Label>
                    <Form.Control
                      type="text"
                      name="address"
                      placeholder="Introduzca la dirección"
                      onChange={handleChange}
                      required/>
                    <Form.Control.Feedback type="invalid">
                      Por favor introduzca la dirección
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-3" >
                    <Form.Label>País</Form.Label>
                    <Form.Control
                      type="text"
                      name="country"
                      placeholder="Introduzca el país"
                      onChange={handleChange}
                      required/>
                    <Form.Control.Feedback type="invalid">
                      Por favor introduzca el país
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Row>
                    <Col>
                      <Form.Group className="mb-3" >
                        <Form.Label>CP</Form.Label>
                        <Form.Control
                          type="text"
                          name="postal_code"
                          placeholder="Introduzca el Código Postal"
                          onChange={handleChange}
                          required/>
                        <Form.Control.Feedback type="invalid">
                          Por favor introduzca el Código Postal
                        </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col>
                    <Form.Group className="mb-3" controlId="validationCustom09">
                    <Form.Label>Ciudad</Form.Label>
                    <Form.Control
                      type="text"
                      name="city"
                      placeholder="Introduzca la ciudad"
                      onChange={handleChange}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Por favor introduzca la ciudad
                    </Form.Control.Feedback>
                  </Form.Group>
                    </Col>
                  </Row>
                  <hr></hr>
                  <p style={{ width: 'max-content', margin: '0.5rem 0 0.5rem', 'text-align': 'center' }}>Tipo de cuenta</p>
                  <ButtonGroup style={{ width: 'max-content', height: 'max-content' }}>
                    {radios.map((radio, idx) => (
                      <ToggleButton
                        key={idx}
                        id={`radio-${idx}`}
                        type="radio"
                        variant={idx % 2 ? 'outline-secondary' : 'outline-primary'}
                        name="type"
                        value={radio.value}
                        checked={form.type === radio.value}
                        onChange={handleChange}
                      >
                        {radio.name}
                      </ToggleButton>
                    ))}
                  </ButtonGroup>
                  <hr></hr>
                  <Form.Group controlId="formFile" className="mb-3" >
                    <Form.Label>{dataText.upload_logo}</Form.Label>
                    <Form.Control
                      type="file"
                      onChange={uploadFileHanlder}
                      required={form.logo === ""}
                      accept=".png, .jpeg"
                    />
                    {img !== "" ? <Image className={styles.img_file} style={{marginTop: '0.25rem'}}src={img} roundedCircle /> : ""}
                  </Form.Group>
                  <Form.Group className="mb-3" >
                    <Form.Check
                      id="default"
                      required
                      name="conditions_accepted"
                      feedback="Por favor acepte las condiciones del servicio"
                      feedbackType="invalid"
                      type="checkbox"
                      onChange={handleChange}
                      label="Acepto las condiciones del servicio"
                    />
                    <Form.Control.Feedback type="invalid">
                      Por favor acepte las condiciones del servicio
                    </Form.Control.Feedback>
                  </Form.Group>
                  <div className="d-flex justify-content-between">
                    <span></span>
                    <Button style={{ backgroundColor: "#001834", color: "white" }} type="submit">
                      Registrar cuenta
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>


    </div>
  )
}

export default ListPage