import { useDispatch } from "react-redux";
import { setIsDv360Authenticated, setIsAdManagerAuthenticated, setGoogleApiAuthenticated } from "state/appSlice";
import { useSelector } from "react-redux";
import { callServer, postSendOauthLogin } from "services/services";
import { useState } from "react";
import { Button } from "react-bootstrap";
import styles from "./loginSuccess.module.css";
import Swal from "sweetalert2";
import GoogleSpinner from "components/GoogleSpinner/GoogleSpinner";

const LoginSuccessApis = () => {
  const url = window.location.href; // get api authentication origin
  const googleApi = url.includes("display-video") ? "dv360" : "admanager";
  const renderApiName = url.includes("display-video") ? "Display Video" : "Ad Manager";
  const dispatch = useDispatch();
  const accountId = useSelector(state => state.accountId);
  const retailerId = useSelector(state => state.retailerId);
  const user = useSelector(state => state.authUser);
  const [msg, setMsg] = useState(<div className={styles.spinner_container}><GoogleSpinner /></div>);

  const showAlert = (text, icon) => {
    Swal.fire({
      text,
      icon: icon,
      allowEscapeKey: true,
      allowOutsideClick: true,

      backdrop: false
    }).then(() => {
      window.close();
    });
    if (icon === "success") setTimeout(() => {
      window.close()
    }, 3000);
  };

  const queryString = window.location.search; // get auth code from Google
  const urlParams = new URLSearchParams(queryString);
  const code = urlParams.get('code')
  const authorization_response = window.location.href

  const [send, setSend] = useState(true); // force to send call just once

  if (code && !!send) {
    postSendOauthLogin({
      authorization_response,
      code,
      googleApi,
      accountId,
      retailerId,
      id: String(user.id)
    })
      .then(() => {
        setSend(false);
        dispatch(setGoogleApiAuthenticated(true));
        localStorage.setItem("google", true)
        googleApi === "dv360" ? dispatch(setIsDv360Authenticated(true)) : dispatch(setIsAdManagerAuthenticated(true));
        setMsg(() => showAlert(`Conexión exitosa a ${renderApiName}`, 'success'));
      })
      .catch(() => {
        setMsg(() => showAlert(`Se ha producido un error de conexión. Por favor vuelva a intentarlo más tarde.`, 'error'))
      })
  }

  return (
    <div>
      {msg}
    </div>
  )
}

export default LoginSuccessApis