import NavBar from "../../../components/NavBar/NavBar";
import Sidebar from "../../../components/SideBar/SideBar"
import { OperationsSideBar } from "../../../config/sidebar_types";

const NewPage = () => {
  const menu = OperationsSideBar
  return (
    <div style={{ height: '100%' }}>
      <NavBar />

      <div style={{ display: 'flex', height: 'calc(100% - 98px)' }}>

        <Sidebar page={"Tags_New"} header={"Operación"} content={menu} />


        <div style={{ 'overflowY': 'auto', backgroundColor: '#FFFFFF', width: '100%' }}>
          <p>Tags New Page</p>
        </div>
      </div>


    </div>
  )
}

export default NewPage