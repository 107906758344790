import { AdminSideBar } from "../../../config/sidebar_types";
import React, { useState, useEffect } from "react";
import TableList from "components/Table/TableList";
import NavBar from "../../../components/NavBar/NavBar";
import Sidebar from "../../../components/SideBar/SideBar"
import dataText from "../../../config/DataText";
import Search from "../../../components/Search/Search";
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';
import Swal from "sweetalert2";
import { getAccounts, getRetailers } from "services/services";


const ListPage = () => {

  const [listData, setListData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [loading, setLoading] = useState(false);

  let navigate = useNavigate()
  const menu = AdminSideBar
  const fieldsToSearch = ["tipo"];

  const rowHeader = [
    {
      header: dataText.tipo,
      key: "tipo",
      type: "text",
    },
    {
      header: dataText.marcas,
      key: "marcas",
      type: "number",
    },
    {
      header: dataText.usuarios,
      key: "usuarios",
      type: "number",
    },
    {
      header: dataText.negociaciones,
      key: "negociaciones",
      type: "number",
    },
    {
      header: dataText.proposals,
      key: "proposals",
      type: "number",
    },
    {
      header: dataText.created_date,
      key: "created_date",
      type: "text",
    },
    {
      header: dataText.created_by,
      key: "created_by",
      type: "text",
    },
    {
      header: "",
      key: "actions",
      type: "actions",
      actions: ["delete", "edit"],
    },
  ];

  const data = [
    {
      "id": 1,
      "tipo": "Loreal",
      "marcas": 23,
      "usuarios": 5,
      "negociaciones": 45,
      "proposals": 17,
      "created_date": "25-01-2022 8:43",
      "created_by": "t.sanz@jakala.es",
    },
    {
      "id": 2,
      "tipo": "Loreal",
      "marcas": 23,
      "usuarios": 5,
      "negociaciones": 45,
      "proposals": 17,
      "created_date": "25-01-2022 8:43",
      "created_by": "t.sanz@jakala.es",
    },
    {
      "id": 3,
      "tipo": "Loreal",
      "marcas": 23,
      "usuarios": 5,
      "negociaciones": 45,
      "proposals": 17,
      "created_date": "25-01-2022 8:43",
      "created_by": "t.sanz@jakala.es",
    },
    {
      "id": 4,
      "tipo": "Loreal",
      "marcas": 23,
      "usuarios": 5,
      "negociaciones": 45,
      "proposals": 17,
      "created_date": "25-01-2022 8:43",
      "created_by": "t.sanz@jakala.es",
    }
  ]

  const fetchList = async () => {
    setLoading(true)
    try {
      // TODO receive and object, must be an array
      const { data } = await getAccounts()
      const account_adapted = data.map(x => {
        const account = {
          id: "a_"+x.id,
          tipo: "Advertiser",
          marcas: x.total_brands,
          usuarios: x.total_users,
          negociaciones: x.total_negotiations,
          proposals: x.total_proposals,
          created_date: x.created_date,
          created_by: x.created_by_email
        }
        return account
      })
      const response = await getRetailers()
      const retailers_adapted = response.data.map(x => {
        const ret = {
          id: "r_"+x.id,
          tipo: "Retailer",
          marcas: "",
          usuarios: x.total_users,
          negociaciones: x.total_negotiations,
          proposals: x.total_proposals,
          created_date: x.created_date,
          created_by: x.created_by_email
        }
        return ret
      })
      const data_adapted = [...retailers_adapted, ...account_adapted].sort(function(a,b){
        return  new Date(b.created_date) - new Date(a.created_date)
      })
      
      setListData(data_adapted);
      setAllData(data_adapted);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchList();
  }, [])

  const handleEdit = (id) => {
    navigate(`/configuracion/marcas/editar/${id}`);
  };

  const handleDelete = (id) => {
    Swal.fire({
      title: "Eliminar grupo",
      text: "Esto eliminará el grupo, no los usaurios. Tendrá que definir especificamente los roles para cada usuario",
      icon: "",
      showCancelButton: true,
      allowEscapeKey: true,
      allowOutsideClick: true,

      cancelButtonColor: "#d20631",
      backdrop: true
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: `Usuario ${id} borrado`,
          text: "Your data has been deleted.",
          icon: "success",
          backdrop: true
        });
      } else {
        return
      }
    });
  };

  return (
    <div style={{ height: '100%' }}>
      <NavBar />

      <div style={{ display: 'flex', height: 'calc(100% - 98px)' }}>
        <Sidebar page={"Accounts_List"} header={"Administración"} content={menu} />


        <div style={{ 'overflowY': 'auto', backgroundColor: '#FFFFFF', width: '100%' }}>
          <div className="new_element">
            <div className="d-flex justify-content-between">
              <Search
                allData={allData}
                setListData={setListData}
                fieldsToSearch={fieldsToSearch}
              />
              <Button onClick={() => navigate('/admin/cuentas/crear')} style={{ background: "#001834", color: "white" }}>{dataText.crear_cuenta}</Button>
            </div>
          </div>
          <TableList
            loading={loading}
            rowsData={listData}
            rowHeader={rowHeader}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
            fetchList={fetchList}
          />
        </div>
      </div>


    </div>
  )
}

export default ListPage