import { OperationsSideBar, OperationsSideBarSuperUser } from "../../../config/sidebar_types";
import React, { useState, useEffect } from "react";
import TableList from "components/Table/TableList";
import NavBar from "../../../components/NavBar/NavBar";
import Sidebar from "../../../components/SideBar/SideBar"
import dataText from "../../../config/DataText";
import Search from "../../../components/Search/Search";
import Button from 'react-bootstrap/Button';
import { Link, useNavigate } from 'react-router-dom';
import Swal from "sweetalert2";
import { element } from "prop-types";
import { deleteNotofications, getNotifications, patchNotification } from "services/services";
import { useDispatch, useSelector } from "react-redux";
import { formatDate, verifySuperUserRole } from "utils/utils";
import { setShowNotificationsAlert, setUpdateCheckAll, setUpdateNotifications } from "state/appSlice";
import styles from "./listPage.module.css";

const ListPage = () => {
  const [listData, setListData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [checkedAll, setCheckedAll] = useState(false);
  const [notificationsForDelete, setNotificationsForDelete] = useState([]);
  const user = useSelector(state => state.authUser);
  const updateNotifications = useSelector(state => state.updateNotifications);
  const dispatch = useDispatch();

  let navigate = useNavigate()
  const menu = !verifySuperUserRole(user) ? OperationsSideBar : OperationsSideBarSuperUser
  const fieldsToSearch = ["id", "status", "date", "messageInNotificationsListPage"];
  const perPage = 10;
  const page = useSelector(state => state.paginationCurrentPage);
  const updateCheckAll = useSelector(state => state.updateCheckAll);

  const handleCheckAll = (e) => {
    setCheckedAll(!checkedAll)
    if (!!e.target.checked) {
      const selected = listData.slice((page - 1) * perPage, (page - 1) * perPage + perPage);

      const newListData = listData.map((item) => {
        if (selected.some(x => x.id === item.id)) {
          return { ...item, checked: true };
        } else return { ...item, checked: false }
      });

      setListData(newListData);

      const newForDelete = selected.map(x => {
        return {
          notification_id: x.id,
          read: true
        }
      })
      setNotificationsForDelete(newForDelete)
    } else {
      const newListData = listData.map(x => {
        return { ...x, checked: false }
      })
      setListData(newListData);
      setNotificationsForDelete([])
    }
  }

  const handleCheckbox = (e) => {
    const targetId = e.target.id
    if (!!e.target.checked) {

      const newListData = listData.map(x => x.id === Number(targetId) ? { ...x, checked: true } : x)

      setListData(newListData);

      setNotificationsForDelete(prevState => ([...prevState, {
        notification_id: Number(targetId),
        read: true
      }]))

    } else {
      const newListData = listData.map(x => x.id === Number(targetId) ? { ...x, checked: false } : x)

      setListData(newListData);

      const newForDelete = notificationsForDelete.filter(x => Number(x.notification_id) !== Number(targetId))
      setNotificationsForDelete(newForDelete);
    }
  };

  const setReadNotifications = async () => {
    if (notificationsForDelete.length > 0) {
      const body = notificationsForDelete.map(x => {
        return {
          notification_id: Number(x.notification_id),
          read: true
        }
      })

      try {
        await patchNotification(user.id, body);
        const notifications = await getNotifications(user.id) || [];

        dispatch(setUpdateNotifications(!updateNotifications));
        if (notifications.data.some(x => x.read === false)) {
          dispatch(setShowNotificationsAlert({ show: true, length: notifications.data.filter(x => x.read === false).length }));
        }
        setCheckedAll(false);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleLink = async (e, notification) => {

    try {
      await patchNotification(user.id, [
        {
          notification_id: notification.id,
          read: true
        }
      ]);

      const notifications = await getNotifications(user.id);

      // setTriangles(notifications.data)
      dispatch(setUpdateNotifications(!updateNotifications))
    } catch (error) {
      console.log(error);
    }
  }

  const deleteNotifications = async () => {
    const params = notificationsForDelete.map(x => Number(x.notification_id))
    try {
      await deleteNotofications(params);

      const notifications = await getNotifications(user.id) || [];

      dispatch(setUpdateNotifications(!updateNotifications));
      if (notifications.data.some(x => x.read === false)) {
        dispatch(setShowNotificationsAlert({ show: true, length: notifications.data.filter(x => x.read === false).length }));
      }

      setCheckedAll(false);
    } catch (error) {
      console.log(error);
    }
  }

  const fetchList = async () => {
    setLoading(true)
    try {
      const response = await getNotifications(user.id)
      const data = response.data.map(x => {
        const notification = {
          id: x.id,
          checked: false,
          status: x.read ? "Leída" : "Sin leer",
          date: formatDate(x.last_modified_date),
          messageInNotificationsListPage: <>{x.message}&nbsp;&nbsp;&nbsp;&nbsp;<Link onClick={(e) => handleLink(e, x)} className={styles.link} to={x.url}>Ir</Link></>
        }
        return notification;
      })

      setListData(data);
      setAllData(data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false)
    }
  }

  const rowHeader = [
    {
      header: <input type='checkbox' /* onClick={handleCheckAll} */ onChange={handleCheckAll} checked={checkedAll} />,
      key: "id",
      type: "checkbox",
    },
    {
      header: dataText.id,
      key: "id",
      type: "text",
    },
    {
      header: dataText.status,
      key: "status",
      type: "text",
    },
    {
      header: dataText.date,
      key: "date",
      type: "text",
    },
    {
      header: dataText.message,
      key: "messageInNotificationsListPage",
      type: "text",
    }
  ];

  useEffect(() => {
    fetchList();
    
    setTimeout(() => {
      dispatch(setShowNotificationsAlert({ show: false, length: 0 }));
    }, 500);
  }, [updateNotifications, user])

  useEffect(() => {
    setCheckedAll(false)
    setNotificationsForDelete([])

    const newListData = listData.map(x => {
      return { ...x, checked: false }
    })
    setListData(newListData);

  }, [updateCheckAll])

  return (
    <div style={{ height: '100%' }}>
      <NavBar />

      <div style={{ display: 'flex', height: 'calc(100% - 98px)' }}>

        <Sidebar page={"Notifications_List"} header={"Operación"} content={menu} />


        <div style={{ 'overflowY': 'auto', backgroundColor: '#FFFFFF', width: '100%', margin: "52px 0" }}>
          <div className="new_element">
            <div className="d-flex justify-content-between">
              <div >
                <Search
                  allData={allData}
                  setListData={setListData}
                  fieldsToSearch={fieldsToSearch}
                />
              </div>
              <div className={styles.header_buttons}>
                <Button onClick={setReadNotifications} style={{ background: "#001834", color: "white", border: "var(--bs-btn-border-width) solid #ffffff" }}>{dataText.marcar_leido}</Button>
                <Button onClick={deleteNotifications} style={{ background: "#001834", color: "white", border: "var(--bs-btn-border-width) solid #ffffff" }}>{dataText.eliminar}</Button>
              </div>
            </div>
          </div>

          <TableList
            loading={loading}
            rowsData={listData}
            rowHeader={rowHeader}
            handleCheckbox={handleCheckbox}
            handleCheckAll={handleCheckAll}
            perPage={perPage}
          />
        </div>
      </div>


    </div>
  )
}

export default ListPage