import dataText from "config/DataText";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import NavBar from "../../../components/NavBar/NavBar";
import Sidebar from "../../../components/SideBar/SideBar"
import { ConfigurationSideBarRetailer, ConfigurationSideBarAdvertiser } from "../../../config/sidebar_types";
import Swal from "sweetalert2";
import Search from "components/Search/Search";
import TableList from "components/Table/TableList";
import styles from "./listPage.module.css";
import { getExternalConnections, patchExternalConnections, postSendOauthUrl, getExternalConnectionAvailableIds } from "services/services";
import { useDispatch, useSelector } from "react-redux";
import { setGoogleApiAuthenticated } from "state/appSlice";
import userEvent from "@testing-library/user-event";
import { formatDate } from "utils/utils";
import { Form } from "react-bootstrap";

const ListPage = () => {
  const [listData, setListData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [allDataFetched, setAllDataFetched] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  //const googleApiAuthenticated = useSelector(state => state.googleApiAuthenticated);
  const user = useSelector(state => state.authUser);

  const menu = user.type === "advertiser" ? ConfigurationSideBarAdvertiser : ConfigurationSideBarRetailer
  const fieldsToSearch = ["status", "platform", "connection_name", "fecha_conexion"];

  const rowHeader = [
    {
      header: dataText.status,
      key: "status",
      type: "text",
    },
    {
      header: dataText.platform,
      key: "platform",
      type: "text",
    },
    {
      header: dataText.connection_name,
      key: "connection_name",
      type: "text",
    },
    {
      header: dataText.fecha_conexion,
      key: "fecha_conexion",
      type: "text",
    },
    {
      header: "",
      key: "actions",
      type: "actions",
      actions: ["delete", "edit"],
    },
  ];

  const [available_network_partner_ids, setAvailable_network_partner_ids] = useState([])

  const [network_partner, setNetwork_partner] = useState("")
  const [network_partner_name, setNetwork_partner_name] = useState("")
  const [validated, setValidated] = useState(false);
  const handleSubmit = async e => {
    setLoading(true)
    e.preventDefault();
    const form_val = e.currentTarget;

    if (!form_val.checkValidity()){
      e.stopPropagation();
      setValidated("true")
    }
    else{
      let body = [{}]
      if(user.type === "retailer"){
        body = [{"network_id": network_partner, "name": network_partner_name}]
      }
      else{
        body = [{"partner_id": network_partner, "name": network_partner_name}]
      }
      patchExternalConnections(listData[0].id, body).then(result => {
          fetchList()
      }).catch(error => {
          console.log(error)
      })
    }
    setLoading(false)
  }

  const handleChange = e => {
    let name = ""
    available_network_partner_ids.forEach(element => {
      if(element.id == e.target.value){
        name = element.displayName
      }
    });
    setNetwork_partner(e.target.value)
    setNetwork_partner_name(name)
  }

  const fetchList = async () => {
    setLoading(true)
    const type = user.type === "retailer"
      ? 'retailer'
      : user.type === "advertiser"
        ? 'account'
        : null;

    const id = user.activeProfile.account_id ? user.activeProfile.account_id : user.activeProfile.retailer_id;
    try {

      const { data } = await getExternalConnections(type, id)
      const data_adapted = data.map(x => {
        const connection = {
          id: x.id,
          network_partner_id: type === 'retailer' ? x.network_id : x.partner_id,
          status: x.active ? "Active" : "Inactive",
          platform: x.platform_name,
          connection_name: x.name,
          fecha_conexion: x.created_date ? formatDate(x.created_date) : "",
        };
        return connection
      })
      setListData(data_adapted);
      setAllData(data_adapted);
      setAllDataFetched(true);

      const response = await getExternalConnectionAvailableIds(data_adapted[0].id)

      if(response !== null){
        if(Array.isArray(response.data)){
          setAvailable_network_partner_ids(response.data)
        }
        else{
          setAvailable_network_partner_ids([response.data])
        }
      }
      else{
        setAvailable_network_partner_ids([])
      }
      
      
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false)
    }
  }

  const handleEdit = (id) => {
    navigate(`/configuracion/conexiones/editar/${id}`);
  };

  const handleDelete = (id) => {
    Swal.fire({
      title: "Eliminar conexión",
      text: "Si Acepta eliminará la conexión y se interrumpirán las negociaciones en curso.",
      icon: "",
      showCancelButton: true,
      allowEscapeKey: true,
      allowOutsideClick: true,

      cancelButtonColor: "#d20631",
      backdrop: false,showClass: {
        popup: 'animated fadeInDown'
      },
      hideClass: {
          popup: 'animated fadeOutUp',
      },
      backdrop: true
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: `Usuario ${id} borrado`,
          text: "Your data has been deleted.",
          icon: "success",
          backdrop: true
        });
      } else {
        return
      }
    });
  };

  const authApis = async (googleApi) => {

    const response = await postSendOauthUrl({ product: googleApi })
      .catch(err => {
        console.log(err);
      })

    if (response?.data) {
      const newWindow = window.open(response.data.authUrl, "_blank", "width=500,height=600");

      if (newWindow) {
        if (newWindow.closed) {
          console.log('ok')
        }
      }

      const interval = setInterval(() => {
        if (localStorage.getItem("google")) {
          fetchList()
          //dispatch(setGoogleApiAuthenticated(true))
          localStorage.removeItem("google")
          clearInterval(interval)
        }
      }, 1000);

      interval();
    }
  }

  useEffect(() => {
    fetchList();
  }, [user])

  return (
    <div style={{ height: '100%' }}>
      <NavBar />
      <div style={{ display: 'flex', height: 'calc(100% - 98px)' }}>
        <Sidebar page={"Connections_List"} header={"Configuración"} content={menu} />


        <div style={{ 'overflowY': 'auto', backgroundColor: '#FFFFFF', width: '100%' }}>
        {allDataFetched ? (allData.length === 0 ?
          <div className={styles.buttons_container}>
            <div className={styles.button_row} >
              {/*!!googleApiAuthenticated ? (
                <div>AUTENTICADO</div>
              ) : (*/
                <div>
                 
                  <Button className="btn btn-primary" onClick={() => {
                      if (user.type === "retailer") {
                        authApis("admanager")
                      } else {
                        authApis("dv360")
                      }
                  }} style={{ backgroundColor: "#001834", color: "white" }}>
                    {user.type === "retailer" ? "Conectar AdManager" : "Conectar DV360"}
                  </Button>
                  
                </div>
              /*)*/
              }
            </div>
            {/* <div className={styles.button_row} >
              {!!googleApiAuthenticated ? (
                <div>AUTENTICADO</div>
              ) : (
                <div>
                  <Button className="btn btn-primary" onClick={() => authApis("dv360")} style={{ backgroundColor: "#001834", color: "white" }}>Conectar DV360</Button>
                </div>
              )
              }
            </div> */}
          </div> : 
          <div className="new_element">
          <div className="d-flex justify-content-left">
          <Form id="networkForm" onSubmit={handleSubmit} noValidate validated={validated}>
          <Form.Select aria-label="Default select example"
                  name={user.type === "advertiser" ? "retailer_id" : "brand_id"}
                  onChange={handleChange}
                  required
                  disabled={allData[0].network_partner_id !== "" && allData[0].network_partner_id !== null}
                  style={{"width": "204px"}}
                >
                  <option value="" selected disabled hidden>
                    {(allData[0].network_partner_id !== "" && allData[0].network_partner_id !== null) ? available_network_partner_ids.filter(item => item.id == allData[0].network_partner_id)[0]?.displayName : "Seleccione conexión..."}
                  </option>
                  {available_network_partner_ids.map((e, key) => {
                      return <option key={key} value={e.id}>{e.displayName}</option>;
                    })}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  Por favor seleccione una opción
                </Form.Control.Feedback>
            </Form>
          
              <Button
            type="submit"
            form="networkForm"
            disabled={allData[0].network_partner_id !== "" && allData[0].network_partner_id !== null}
            className={styles.button_stablish}
          >Establecer</Button>
          </div>
          </div>
          ) : <></>}
          <div className="new_element">
            <div className="d-flex justify-content-between">
              <Search
                allData={allData}
                setListData={setListData}
                fieldsToSearch={fieldsToSearch}
              />
              <></>
            </div>
          </div>
          <TableList
            loading={loading}
            rowsData={listData}
            rowHeader={rowHeader}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
            fetchList={fetchList}
          />
        </div>
      </div>


    </div>


  )
}

export default ListPage