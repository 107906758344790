import socketClient from "socket.io-client";
import { useDispatch, useSelector } from "react-redux";


export const socket = socketClient('https://smartretailmedia.es', {
        reconnectionDelay: 1000,
        reconnection: true,
        reconnectionAttemps: 10,
        transports: ['websocket'],
        agent: false,
        upgrade: false,
        rejectUnauthorized: false,
        autoConnect: false,
        path: '/api/v1/socket.io'
});