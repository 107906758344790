import { useDispatch, useSelector } from "react-redux";
import styles from "./showProfilesFrame.module.css";
import { postProfileSelector } from "services/services";
import { useNavigate } from "react-router-dom";
import jwtDecode from "jwt-decode";
import { setAuthUser, setSrmToken } from "state/appSlice";

const ShowProfilesFrame = ({ setProfile }) => {
  const user = useSelector(state => state.authUser);
  // const details = user.type === "retailer" ? ['retailer_name', 'retailer_logo'] : ['account_name', 'account_logo'];
  const profiles = user.profiles;
  // const profile = useSelector(state => state.userProfile)
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const handleClick = async (profile) => {
    // setProfile(profile)
    let body = {}
    if(user.type === "advertiser"){
      if(profile.account_id === user.activeProfile.account_id){
        body = {user_id: profile.user_id,
          role_id: profile.role_id,
          brand_id: profile.brand_id}
      }
      else{
        body = {user_id: profile.user_id,
          role_id: profile.role_id,
          brand_id: profile.brand_id,
          account_id: profile.account_id}
      }
    }
    else{
      body = {user_id: profile.user_id,
        role_id: profile.role_id,
        brand_id: profile.retailer_id}
    }
    const { data } = await postProfileSelector(body)
    
    const decoded = jwtDecode(data.token);
    
    localStorage.setItem("srmToken", data.token);

    dispatch(setSrmToken(data.token));

    let newAuthUser = {...user}
    newAuthUser['activeProfile'] = profile

    dispatch(setAuthUser(newAuthUser));
    //window.location.reload(true);
    
  }

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        {user.type === "retailer" ? 'Retailers' : 'Advertisers'}
      </div>
      <div className={styles.images}>
        {profiles.map(elem => {
          if (elem.brand_id && user.type === "advertiser") {
            return (
              <div key={elem.id} className={styles.item} onClick={() => handleClick(elem)}>
                <div>
                  <img className={(
                    elem.brand_id === user.activeProfile.brand_id && elem.retailer_id === user.activeProfile.retailer_id)
                    ? `${styles.item} ${styles.selected}` : styles.item} src={elem.logo} alt={elem.brand_name ? elem.brand_name : elem.retailer_name} />
                </div>
                <div>{elem.name}</div>
              </div>
            )
          } else if (elem.retailer_id && user.type === "retailer") {
            return (
              <div key={elem.id} className={styles.item} onClick={() => handleClick(elem)}>
                <div>
                  <img className={(
                    elem.brand_id === user.activeProfile.brand_id && elem.retailer_id === user.activeProfile.retailer_id)
                    ? `${styles.item} ${styles.selected}` : styles.item} src={elem.logo} alt={elem.brand_name ? elem.brand_name : elem.retailer_name} />
                </div>
                <div>{elem.name}</div>
              </div>
            )
          }
        }
        )}
      </div>
    </div>
  )
}

export default ShowProfilesFrame