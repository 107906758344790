import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Image from 'react-bootstrap/Image'
import { Dropdown, Form, Badge } from "react-bootstrap";
import NavDropdown from 'react-bootstrap/NavDropdown';
import { BsBell, BsImageFill, BsMenuApp, BsMenuButton, BsPerson, BsThreeDots } from "react-icons/bs";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import dataText from "../../config/DataText";
import DropdownButton from 'react-bootstrap/DropdownButton';
import logoCompany from "../../assets/images/logo-jakala.png";
import "./navbar.css";
import ShowProfilesFrame from "components/ShowProfilesFrame/ShowProfilesFrame";
import NotificationsFrame from "components/Notifications/NotificationsFrame";
import { useEffect, useState } from "react";
import { /* setNotifications, */ setUserProfile, setAuthUser, setSrmToken, setShowNotificationsAlert, setIsDv360Authenticated, setGoogleApiAuthenticated, setIsAdManagerAuthenticated, setChatMessages, setChatNegotiationFormData, setNegotiation } from "state/appSlice";
import { getNotifications, loginStatus } from "services/services";
import jwtDecode from "jwt-decode";
import styles from "./navbar.module.css";
import { verifySuperUserRole } from "utils/utils";
import { socket } from "services/socket"

const NavBar = () => {
  const user = useSelector(state => state.authUser);
  // const initialProfile = (!localStorage.getItem('userProfile')) ? user.profiles[0] : JSON.parse(localStorage.getItem('userProfile'));
  // if (!localStorage.getItem('userProfile')) localStorage.setItem('userProfile', JSON.stringify(user.profiles[0]));
  const initialProfile = user.activeProfile;
  const updateNotifications = useSelector(state => state.updateNotifications)

  const [profile, setProfile] = useState(initialProfile);
  const [render, setRender] = useState(false);
  // const notifications = useSelector(state => state.notifications);
  const [notifications, setNotifications] = useState([]);
  const showNotificationsAlert = useSelector(state => state.showNotificationsAlert);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [renderNotificationsFrame, setRenderNotificationsFrame] = useState(false);
  const [refresh, setRefresh] = useState(true);
  const [checkNotificationsLength, setCheckNotificationsFrame] = useState(true);
  const [showTriangle1, setShowTriangle1] = useState(true);
  // const [messgsQuantity, setMessgsQuantity] = useState(0);

  const toggleRefresh = () => setRefresh(!refresh);
  // const launchCheckNotificationsLength = async () => {
  //   try {
  //     const { data } = await getNotifications(user.id);
  //     if (data.length > notifications.length) {
  //       
  //       dispatch(setShowNotificationsAlert(true));
  //     }
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }

  const logout = () => {
    dispatch(setAuthUser(null));
    dispatch(setSrmToken(null));
    if (localStorage.getItem("srmToken")) localStorage.removeItem("srmToken");
    if (localStorage.getItem("persist:root")) localStorage.removeItem("persist:root");
    dispatch(setIsDv360Authenticated(false));
    dispatch(setIsAdManagerAuthenticated(false));
    dispatch(setGoogleApiAuthenticated(false));
    dispatch(setChatMessages(null));
    dispatch(setChatNegotiationFormData(null));
    dispatch(setNegotiation(null));
    // dispatch(setNotifications(null));
    dispatch(setShowNotificationsAlert(false));
    socket.disconnect();
    navigate("/login");
  }

  const logoutHandler = async () => {
  };

  const { pathname } = useLocation();

  const linkClasses = (isActive) =>
    pathname.includes(isActive)
      ? "navbar-light navbar-nav d-flex align-items-center connections_container nav-link activeLink"
      : "navbar-light navbar-nav d-flex align-items-center connections_container nav-link";

  const updateUserProfile = (profile) => {
    dispatch(setAuthUser({ ...user, activeProfile: profile }))
  }

  const fetchNotifications = async () => {
    const res = await getNotifications(user.id);
    const notifications = res.data || [];

    // dispatch(setNotifications(notifications));
    setNotifications(notifications);

    if (notifications.some(x => x.read === false)) {
      dispatch(setShowNotificationsAlert({ show: true, length: notifications.filter(x => x.read === false).length }));
    }
  }

  useEffect(() => {
    updateUserProfile(profile)
  }, [profile])

  useEffect(() => {
    // alert("navbar")
    fetchNotifications()
      .catch(console.error);
  }, [updateNotifications])

  useEffect(() => {
    const fetchData = async (message) => {
      console.log("Received notification: " + message)
      fetchNotifications()
        .catch(console.error);
    };

    socket.on('New notification', fetchData)

    return () => {
      socket.off('New notification', fetchData);
    };
  }, [])
  // useEffect(() => {
  //   launchCheckNotificationsLength()
  // }, [])

  useEffect(() => {
    // TODO maybe delete this
  }, [checkNotificationsLength])

  return (
    <>
      <Navbar className="navbar navbar-default">
        <Link
          className="navbar-brand text-primary font-weight-bold pl-5"
          to={!verifySuperUserRole(user) ? "/overview" : "/admin/cuentas/lista"}
        // onClick={fetchNotifications}
        >
          <img className="logo-jakala" src={logoCompany} alt="logo"></img>
        </Link>
        <div className="d-flex w-100 justify-content-end align-items-center">

          <Nav className>
            {!verifySuperUserRole(user) ? (
              <>
                <NavLink
                  className={() => linkClasses('overview')}
                  to="/overview"
                >
                  <span className="nav_span">{dataText.overView}</span>
                </NavLink>
                <NavLink
                  className={() => linkClasses('operacion')}
                  to="/operacion/retailers/conexiones"

                >
                  <span className="nav_span">{dataText.operacion}</span>
                </NavLink>
                <NavLink
                  className={() => linkClasses('reporting')}
                  to="/reporting/lista"

                >
                  <span className="nav_span">{dataText.reporting}</span>
                </NavLink>

                <NavLink
                  className={() => linkClasses('configuracion')}
                  to={user.type === "advertiser" ? "/configuracion/marcas/lista" : "/configuracion/conexiones/lista"}
                >
                  <span className="nav_span">{dataText.configuracion}</span>
                </NavLink>
                <NavLink
                  className={() => linkClasses('accesos')}
                  to="/accesos/usuarios/lista"
                >
                  <span className="nav_span">{dataText.accesos}</span>
                </NavLink>
              </>
            ) : (
              <>
                <NavLink
                  className={() => linkClasses('admin')}
                  to="/admin/cuentas/lista"
                >
                  <span className="nav_span">{dataText.administracion}</span>
                </NavLink>
              </>
            )
            }
            <NavDropdown /* onClick={() => dispatch(setShowNotificationsAlert({show: true, length: 2}))} */ className={styles.nav_dropdown} title={
              <>
                {!!showNotificationsAlert.show &&
                  <div className={styles.bubble}>
                    {/* <div className={styles.number_container}>
                      <div className={styles.number_display}>{messgsQuantity}</div>
                    </div> */}
                    {showNotificationsAlert.length}
                  </div>
                }
                <BsBell className="bell_" />
              </>
            } id="bell" align="center">
              {
                <>
                  <div className="pop_up_box">
                    <NotificationsFrame toggleRefresh={toggleRefresh} setShowTriangle1={setShowTriangle1} />
                    {!!showTriangle1 ? (
                      <div className="triangle1"></div>
                    ) : (
                      <div className="triangle1-b"></div>
                    )
                    }
                  </div>
                </>
              }
              <NavDropdown.Item eventKey="link" className={styles.link_container}>
                <Link to="/operacion/notificaciones/lista" className={styles.link}>
                  ver todas ({notifications?.length})
                </Link>
              </NavDropdown.Item>
            </NavDropdown>
              
            {(user.type === "retailer" || user.type === "advertiser") && (
              <NavDropdown title={<div className="profile_image_container">
                <Image src={user.activeProfile.logo} alt={user.activeProfile.brand_name ? user.activeProfile.brand_name : user.activeProfile.retailer_name} />
              </div>} disabled={verifySuperUserRole(user)}
              > 
                <NavDropdown.Item id="profil_box">
                  <div className="pop_up_box">
                    <ShowProfilesFrame setProfile={setProfile} />
                    <div className="triangle2"></div>
                  </div>
                </NavDropdown.Item>
                
              </NavDropdown>
            )
            }
            <NavDropdown className="dropdowm-fix"
              align="end"
              id="dropdown-menu-align-end"
              title={<BsPerson className={styles.three_dots} />}
              menuVariant="dark">
              <NavDropdown.Item className={styles.link_container} onClick={logout}>
                <div className="pop_up_box">
                  <div>Logout</div>
                  <div className="triangle3"></div>
                </div>
              </NavDropdown.Item>
              {/* <NavDropdown.Divider /> */}
            </NavDropdown>
          </Nav>
        </div>
      </Navbar>
    </>
  );
};
export default NavBar;
