import NavBar from "../../../components/NavBar/NavBar";
import Sidebar from "../../../components/SideBar/SideBar"
import FinalConfigForm from "./FinalConfigForm"
import HeaderNegotiation from "./HeaderConfNeg";
import { OperationsSideBar } from "../../../config/sidebar_types";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { getExternalConnectionUsers, getNegotiation } from "services/services";

const FinalConfigPage = () => {
  const { id } = useParams();
  const menu = OperationsSideBar
  const user = useSelector((state) => state.authUser);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [negotiation, setNegotiation] = useState({
    brand: {
      id: 0,
      name: ""
    },
    retailer: {
      id: 0,
      name: ""
    },
    externalConnectionUsers: []
  });
  const [approvedMessage, setApprovedMessage] = useState({
    version: {
      start_date: "oooooooooooooooooooooooooooooooo",
      end_date: "oooooooooooooooooooooooooooooooo",
      creative_sizes: [],
      inventory: []
    }
  });

  const fetchData = async () => {
    try {
      const res = await getNegotiation(id);
      const negotiation = res.data;
      const externalUsers = await getExternalConnectionUsers(negotiation.retailer_external_connection_id)
      // get approved version
      const approvedMessage = negotiation.messages.filter(x => x.version.status === "APPROVED")
      setApprovedMessage(approvedMessage[0]);

      negotiation.externalConnectionUsers = externalUsers.data
      delete negotiation.messages;
      setNegotiation(negotiation)
    } catch (err) {
      navigate("/operacion/negociaciones/lista");
    }
  }

  useEffect(() => {
    fetchData()
  }, [user])

  return (
    <div style={{ height: '100%' }}>
      <NavBar />

      <div style={{ display: 'flex', height: 'calc(100% - 98px)' }}>
        <Sidebar page={"Negotiations_List"} header={"Operación"} content={menu} />


        {/* <div style={{ 'overflowY': 'auto', backgroundColor: '#FFFFFF', width: '100%' }}> */}
        <div style={{ 'overflowY': 'auto', backgroundColor: '#FFFFFF', width: '100%' }}>
          <div style={{ 'display': 'flex', 'flexDirection': 'column', 'padding': '3rem 14rem 1rem 5.5rem', 'minHeight': '200px' }}>
            <HeaderNegotiation page="3" />

            <FinalConfigForm approvedMessage={approvedMessage} userType={user.type} userId={user.id} negotiation={negotiation} />
          </div>
        </div>
      </div>
      {/* </div> */}


    </div>
  )
}

export default FinalConfigPage
