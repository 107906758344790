import Swal from "sweetalert2";
import { patchCancelNegotiation, patchNegotiation } from "./services";

// for reset form in negotiation chat
export const initialFormState = {
  budget: "",
  start_date: "",
  end_date: "",
  creative_sizes: [],
  quantity: "",
  rate: "",
  inventory: [],
  message: "",
}

// export const cancelNegotiation = (id, userType) => {
//   Swal.fire({
//     title: 'Confirmación requerida',
//     icon: 'info',
//     html:
//       'Está cancelando la negociación</br>' +
//       'Este paso es irreversible.',
//     showCancelButton: true,
//     confirmButtonText: 'Cancelar negociación',
//     cancelButtonText: `Volver`,
//     backdrop: true,
//   }).then((result) => {
//     if (result.isConfirmed) {
//       const body = userType === "advertiser"
//         ? { brand_status: "CANCELLED", retailer_status: "ADVERTISER_CANCELLED" }
//         : { brand_status: "RETAILER_CANCELLED", retailer_status: "CANCELLED" }

//       patchCancelNegotiation(id, body)
//         .then(response => {

//           let timerInterval
//           Swal.fire({
//             icon: 'success',
//             html: 'La negociación ha sido cancelada!',
//             showConfirmButton: false,
//             timer: 1000,
//             backdrop: false,
//             willClose: () => {
//               clearInterval(timerInterval)
//             }
//           })

//           return true
//         })
//         .catch(error => console.log("error"))
//     }
//     return result
//   })
// }