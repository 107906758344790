import React from 'react'
import dataText from "../../config/DataText";
import { RxCross1 } from 'react-icons/rx'
const Connected = ({ items, updateStatus, userType }) => {

    return (
        <div className={userType === "advertiser" ? "pending" : "pending retailer"}>
            <div className="header_container">
                <p className="header">{dataText.conectados}</p>
            </div>
            {
                items && items.map(item => {
                    if (item && item.status === 'APPROVED')
                        // TODO update with retailer name or account name
                        return <div key={item.id} className="item_container"><p className="item" >{userType === "advertiser" ? item.retailer_name : item.account_name}</p><button className="move" onClick={() => { updateStatus(item.id, 'AVAILABLE', item.status) }}>{<RxCross1 />}</button> </div>
                })
            }

        </div>
    )
}
export default Connected