import styles from './negotiation_header.module.css'

const HeaderNegotiation = ({page}) => {
    return(
        <div className={styles.container}>
            <div className={styles.header_item}>
                <div className={page === "1" ? styles.label_selected : styles.label}>1</div>
                <div className={styles.text}>Configuración inicial</div>
            </div>
            <div className={styles.header_item}>
                <div className={page === "2" ? styles.label_selected : styles.label}>2</div>
                <div className={styles.text}>Negociación</div>
            </div>
            <div className={styles.header_item}>
                <div className={page === "3" ? styles.label_selected : styles.label}>3</div>
                <div className={styles.text}>Configuración final y aceptación</div>
            </div>
        </div>
    )
}

export default HeaderNegotiation