const dataText = {
  actions:"Actions",
  overView: "Overview",
  operacion: "Operación",
  reporting: "Reporting",
  configuracion: "Configuración",
  accesos: "Accesos",
  marcas:"Marcas",
  negocacionesCurso: "Negocaciones en curso",
  negocacionesDenegadas: "Negocaciones denegadas",
  propuestasAceptadas: "Propuestas aceptadas ",
  progress: "Progreso",
  campaingsOn: "Campañas activas",
  activactionRate: "Activation Rate", 
  administracion: "Administración",
  disponibles: "Disponibles",
  pendiente_aceptacion: "Pendiente aceptación",
  conectados: "Conectados",
  state:"Estado",
  email:"Email",
  brand:"Brand/Retailer",
  rol:"Rol",
  group:"Grupo",
  created_date:"Creado",
  last_access:"Último acceso",
  invitar_usuario:"Invitar usuario",
  editar_usuario:"Editar usuario",
  crear_grupo:"Crear grupo",
  editar_grupo:"Editar grupo",
  rol_grupo:"Rol de grupo",
  datos_fiscales:"Datos fiscales",
  usuarios:"Usuarios",
  placement_id:"Placement ID",
  placement_name:"Placement Name (GAM)",
  placement_alias:"Placement Alias",
  enable_disabled:"Habilitada/Deshabilitada",
  cargar_placements:"Cargar Placements",
  brand:"Marca",
  brand_DV:"Marca (DV Account)",
  negociaciones:"Negociaciones",
  proposals:"Proposals",
  new_brand:"Nueva marca",
  edit_brand:"Editar marca",
  brand_name:"Nombre de Marca",
  upload_logo:"Subir logo",
  responsable:"Responsable",
  tipo:"Tipo",
  marcas:"Marcas",
  created_by:"Creada por",
  creado:"Creado",
  crear_cuenta:"Crear cuenta",
  negociaciones_id:"Negociaciones ID",
  proposal_id:"Proposal ID",
  retailer:"Retailer",
  rfp_name:"RFP Name",
  status:"Status",
  volumen:"Volumen (Impr)",
  rate_cmp:"Rate(CMP)",
  last_modificacation:"Last Modification",
  new_negociacion:"Nueva Negociacion",
  marcar_leido:"Marca como leída",
  eliminar:"Eliminar",
  id:"ID",
  date:"Date",
  message:"Message",
  deal_id:"Deal ID",
  order:"Order",
  impressions:"Impressions",
  clicks:"Clicks",
  crt:"CRT",  
  guardar:"Guardar",
  submit:"Submit",
  platform:"Plataforma",
  account_name:"Account Name",
  connection_name: "Nombre de conexión",
  account_id:"Account ID",
  fecha_conexion:"Fecha Conexión",
  FECHA: "FECHA",
  MENSAJE: "MENSAJE",
  LEIDO: "LEÍDO"
};

export default dataText;
