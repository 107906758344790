import { useState, useEffect } from "react";
import styles from './negotiation.module.css';
import { Button, Form, Container } from 'react-bootstrap';
import Chat from "../../../components/Chat/Chat";
import NavBar from "../../../components/NavBar/NavBar";
import Sidebar from "../../../components/SideBar/SideBar";
import { OperationsSideBar } from "../../../config/sidebar_types";
import { useDispatch, useSelector } from "react-redux";
import { setChatMessages, setChatNegotiationFormData, setCompanyLogo, setNegotiation } from "state/appSlice";
import { getExternalConnectionInventory, getInventory, getNegotiation, patchCancelNegotiation, patchNegotiation, postNegotiationForm } from "../../../services/services";
import Swal from "sweetalert2";
import { initialFormState } from "services/negotiationServices";
import PageTitle from "components/PageTitle/PageTitle";
import sendMessage from "../../../assets/images/send-message.png";
import HeaderNegotiation from "./HeaderConfNeg";
import { useNavigate, useParams } from "react-router-dom";
import Select from 'react-select';
import { validateStartDate } from "utils/utils";
import { socket } from "services/socket"

const Negotiation = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const formData = useSelector((state) => state.chatNegotiationFormData);
  const navigate = useNavigate();
  const user = useSelector((state) => state.authUser);
  const ghosts = document.querySelectorAll('[class*="date_ghost"]');
  const [form, setForm] = useState(initialFormState);
  const [freezeForm, setFreezeForm] = useState(initialFormState);
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [acceptNegotiationButton, setAcceptNegotiationButton] = useState(true);
  // const dateInputs = document.querySelectorAll('input[type="date"]');
  // const textInputsToDate = document.querySelectorAll('input[class*="to_date"]');
  const menu = OperationsSideBar;
  const [validatedMultiCr, setValidatedMultiCr] = useState(false);
  const [inventorySelector, setInventorySelector] = useState([]);
  const [isValidStartDate, setIsValidStartDate] = useState(true);
  const [isValidEndDate, setIsValidEndDate] = useState(true);

  // multi selectors
  const [defaultInventories, setDefaultInventories] = useState([]);
  const [validatedMulti, setValidatedMulti] = useState(false);
  const [sizesSelector, setSizesSelector] = useState(null);
  const [defaultSizes, setDefaultSizes] = useState(null);
  // update negotiation after click in message buttons
  const [updateNegotiation, setUpdateNegotiation] = useState(true);
  const [negotiation, setNegotiation] = useState(null);

  const handleChange = e => {
    const index = e.target.name;
    const value = e.target.value;

    const elem = document.querySelector(`[class*=${index}]`);
    elem.classList.remove('d-none');

    if (index === "start_date" || index === "end_date") {
      const validated = validateStartDate(value);

      if (!validated) {
        (index === "start_date") ? setIsValidStartDate(false) : setIsValidEndDate(false);

      } else {
        setForm(prevState => ({
          ...prevState,
          [e.target.name]: value
        }))
        if (index === "start_date") {
          setIsValidStartDate(true)
        } else {
          setIsValidEndDate(true);
        }
      }
    } else {
      setForm(prevState => ({
        ...prevState,
        [e.target.name]: value
      }))
    }
  };

  const handleMultiSelectChange = (selectedOption) => {
    const elem = document.querySelector(`[class*=inventory]`);
    elem.classList.remove('d-none');

    const invs = selectedOption.map(x => x)
    setDefaultInventories(invs)

    let selected = []
    selectedOption.forEach(element => {
      selected.push({ id: element.value, inventory_name: element.label })
    });
    setForm(prevState => ({
      ...prevState,
      inventory: selected
    }))
    setValidatedMulti(false)
  }

  const handleMultiSelectCreative = (selectedOption) => {
    const elem = document.querySelector(`[class*=creatives]`);
    elem.classList.remove('d-none');

    const sizes = selectedOption.map(x => x)
    setDefaultSizes(sizes)

    let selected = []
    selectedOption.forEach(element => {
      selected.push(element.label)
    });
    setForm(prevState => ({
      ...prevState,
      creative_sizes: selected
    }))
    setValidatedMultiCr(false)
  }

  const fetchInventory = async (negotiation) => {
    getInventory(negotiation.retailer_id).then(response => {
      let inventory = []
      let sizes = [];

      response.data.forEach(item => {
        inventory.push({ "value": item.id, "label": item.alias });

        item.sizes.forEach(size => {
          const elems = size.split(",");
          elems.forEach(elem => sizes.push({ "value": elem, "label": elem }))
        })
      });
      setInventorySelector(inventory);
      setSizesSelector(sizes);

      setLoading(false);
    }).catch((error) => {
      console.log(error)
    })
  }

  const acceptDeal = (id, body) => {
    Swal.fire({
      title: 'Confirmación requerida',
      icon: 'info',
      html:
        'Usted accederá a la página de configuración final de la Negociación.',
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      cancelButtonText: `Cancelar`,
      backdrop: true,
    }).then((result) => {
      if (result.isConfirmed) {
        if (user.type === "advertiser") {
          // advertiser pass to state 4
          patchNegotiation(negotiation.id, { brand_status: "FINAL_CONFIGURATION_PENDING", retailer_status: "WAITING_FOR_ADVERTISER_FINAL_CONFIGURATION" })
            .then(response => {

              navigate(`/operacion/negociaciones/aceptacion/${negotiation.id}`)
            })
            .catch(error => console.log(error))
        } else {
          // retailer pass to state 5
          patchNegotiation(negotiation.id, { brand_status: "WAITING_FOR_RETAILER_FINAL_CONFIGURATION", retailer_status: "FINAL_CONFIGURATION_PENDING" })
            .then(response => {

              navigate(`/operacion/negociaciones/aceptacion/${negotiation.id}`)
            })
            .catch(error => console.log(error))
        }

      }
    })
  }

  const cancelNegotiation = () => {
    Swal.fire({
      title: 'Confirmación requerida',
      icon: 'info',
      html:
        'Está cancelando la negociación</br>' +
        'Este paso es irreversible.',
      showCancelButton: true,
      confirmButtonText: 'Cancelar negociación',
      cancelButtonText: `Volver`,
      backdrop: true,
    }).then((result) => {
      if (result.isConfirmed) {
        const body = user.type === "advertiser"
          ? { brand_status: "CANCELLED", retailer_status: "ADVERTISER_CANCELLED" }
          : { brand_status: "RETAILER_CANCELLED", retailer_status: "CANCELLED" }

        patchCancelNegotiation(id, body)
          .then(response => {

            let timerInterval
            Swal.fire({
              icon: 'success',
              html: 'La negociación ha sido cancelada!',
              showConfirmButton: false,
              timer: 1300,
              backdrop: false,
              willClose: () => {
                clearInterval(timerInterval)
              }
            }).then(result => {
              navigate("/operacion/negociaciones/lista")
            })

          })
          .catch(error => console.log("error"))
      }
      return result
    })
  }

  const handleSubmit = async e => {
    e.preventDefault();
    const htmlForm = e.currentTarget;

    // set negotiation just once 
    const sendNegotiation = () => {
      postNegotiationForm(id, form)
        .then((resp) => {
          getNegotiation(resp.data.negotiation_id)
            .then((resp) => {
              dispatch(setChatMessages(resp.data.messages))
              setForm(initialFormState);
              setDefaultInventories([]);
              setDefaultSizes([]);

              ghosts.forEach(x => x.classList.add('d-none'))
              // textInputsToDate.forEach(x => x.attributes.type.value = "date");
            })
        })
        .catch(error => {
          console.log(error)
        })
    }

    if (!htmlForm.checkValidity() || !isValidStartDate || !isValidEndDate /* || form?.version?.creative_sizes?.length === 0 */) {
      const formData = Object.fromEntries(new FormData(htmlForm))

      if (formData.message === "") {
        Swal.fire({
          icon: 'error',
          title: 'El campo Mensaje es obligatorio',
          confirmButtonText: 'Ok',
          backdrop: true,
        })
      } else if (!isValidStartDate) {
        Swal.fire({
          icon: 'error',
          title: 'La fecha de inicio no puede ser anterior a la actual',
          confirmButtonText: 'Ok',
          backdrop: true,
        })
      } else if (!isValidEndDate) {
        Swal.fire({
          icon: 'error',
          title: 'La fecha de fin no puede ser anterior a la actual',
          confirmButtonText: 'Ok',
          backdrop: true,
        })
      } else (
        Swal.fire({
          title: 'Todos los campos son obligatorios!',
          icon: 'info',
          html:
            'Si Confirma se enviará solamente el campo de mensaje de texto</b>, ' +
            'Si Cancela podrá terminar de completar el formulario',
          showCancelButton: true,
          confirmButtonText: 'Confirmar',
          cancelButtonText: `Cancelar`,
          backdrop: true,
        }).then((result) => {
          if (result.isConfirmed) {
            let timerInterval
            Swal.fire({
              icon: 'success',
              html: 'Mensaje enviado!',
              showConfirmButton: false,
              timer: 800,
              backdrop: false,
              willClose: () => {
                clearInterval(timerInterval)
              }
            })

            sendNegotiation()
          }
        })
      )
    } else {
      sendNegotiation()
    }
  }

  useEffect(() => {
    dispatch(setChatNegotiationFormData(null))
    const fetchData = async () => {
      setLoading(true)
      const res = await getNegotiation(id);
      const negotiation = res.data;

      // redirect to final configuration  
      if (
        // estado 4
        ((user.type === "advertiser" && negotiation.brand_status === "FINAL_CONFIGURATION_PENDING" && negotiation.retailer_status === "WAITING_FOR_ADVERTISER_FINAL_CONFIGURATION")
          ||
          // estado 5
          user.type === "advertiser" && (negotiation.brand_status === "WAITING_FOR_RETAILER_FINAL_ACCEPTANCE" && negotiation.retailer_status === "FINAL_ACCEPTANCE_PENDING")
          ||
          // estado 6
          (/* user.type === "retailer" &&  */negotiation.brand_status === "WAITING_FOR_RETAILER_FINAL_CONFIGURATION" && negotiation.retailer_status === "FINAL_CONFIGURATION_PENDING")
          ||
          // estado 6
          (user.type === "advertiser" && negotiation.brand_status === "FINAL_CONFIGURATION_PENDING" && negotiation.retailer_status === "WAITING_FOR_ADVERTISER_FINAL_CONFIGURATION")
        )
        ||
        // estado 7
        (negotiation.brand_status === "APPROVED" && negotiation.retailer_status === "APPROVED")) {
        navigate(`/operacion/negociaciones/aceptacion/${negotiation.id}`)
      }

      // activate button for advertiser
      if (negotiation.messages.length > 0) {
        const proposals = negotiation.messages.filter(x => x.type === "PROPOSAL");

        if (user.type === "advertiser" && proposals.some(x => x.version.status === "APPROVED")) setAcceptNegotiationButton(false);
      }
      // activate button for retailer
      if (user.type === "retailer" && negotiation.brand_status === "WAITING_FOR_RETAILER_FINAL_ACCEPTANCE" && negotiation.retailer_status === "FINAL_ACCEPTANCE_PENDING") {
        setAcceptNegotiationButton(false);
      }

      dispatch(setChatMessages(negotiation.messages));

      delete negotiation.messages;
      // dispatch(setNegotiation(negotiation))
      setNegotiation(negotiation)
      fetchInventory(negotiation)
      .catch(console.error);

      
    }

    fetchData()
      .catch(console.error);
  }, [updateNegotiation, user, id])

  // reset form
  useEffect(() => {
    // if (formData?.start_date !== "" || formData?.end_date !== "") {
    //   dateInputs.forEach(elem => {
    //     elem.attributes.type.value = "text"
    //   });
    ghosts.forEach(x => x.classList.add('d-none'))
    // }

    setFreezeForm(formData);

    if (formData?.inventory) {
      let freezeInventories = "";
      formData.inventory.forEach(x => freezeInventories += `${x.inventory_name}, `)
      const freezeInvs = freezeInventories.slice(0, -2);
      setFreezeForm(prevState => ({
        ...prevState,
        inventory: freezeInvs
      }))
    }

    if (formData?.creative_sizes) {
      let string = "";
      formData.creative_sizes.forEach(x => string += `${x}, `)
      const freezeCS = string.slice(0, -2);
      setFreezeForm(prevState => ({
        ...prevState,
        creative_sizes: freezeCS
      }))
    }

    setForm(formData)
    const inventories = formData?.inventory?.map(x => {
      return { value: x.id ? x.id : x.inventory_id, label: x.inventory_name }
    })

    setDefaultInventories(inventories)
    const sizes = formData?.creative_sizes?.map(size => {
      return { value: size, label: size }
    })
    setDefaultSizes(sizes)

    // update form
    let inventory = [];
    let creative_sizes = [];
    inventories?.forEach(element => {
      inventory.push({ id: element.value, inventory_name: element.label })
    });
    sizes?.forEach(element => {
      creative_sizes.push(element.label)
    });

    setForm(prevState => ({
      ...prevState,
      inventory: inventory,
      creative_sizes: creative_sizes
    }))
    
  }, [formData])
  useEffect(() => {

    const fetchData = async (message) => {
      console.log("Received new negotiation message")
      
      if(id === message){
        const res = await getNegotiation(id);
        const negotiation = res.data;

        // redirect to final configuration  
        if (
          // estado 4
          ((user.type === "advertiser" && negotiation.brand_status === "FINAL_CONFIGURATION_PENDING" && negotiation.retailer_status === "WAITING_FOR_ADVERTISER_FINAL_CONFIGURATION")
            ||
            // estado 5
            user.type === "advertiser" && (negotiation.brand_status === "WAITING_FOR_RETAILER_FINAL_ACCEPTANCE" && negotiation.retailer_status === "FINAL_ACCEPTANCE_PENDING")
            ||
            // estado 6
            (/* user.type === "retailer" &&  */negotiation.brand_status === "WAITING_FOR_RETAILER_FINAL_CONFIGURATION" && negotiation.retailer_status === "FINAL_CONFIGURATION_PENDING")
            ||
            // estado 6
            (user.type === "advertiser" && negotiation.brand_status === "FINAL_CONFIGURATION_PENDING" && negotiation.retailer_status === "WAITING_FOR_ADVERTISER_FINAL_CONFIGURATION")
          )
          ||
          // estado 7
          (negotiation.brand_status === "APPROVED" && negotiation.retailer_status === "APPROVED")) {
          navigate(`/operacion/negociaciones/aceptacion/${negotiation.id}`)
        }

        // activate button for advertiser
        if (negotiation.messages.length > 0) {
          const proposals = negotiation.messages.filter(x => x.type === "PROPOSAL");

          if (user.type === "advertiser" && proposals.some(x => x.version.status === "APPROVED")) setAcceptNegotiationButton(false);
        }
        // activate button for retailer
        if (user.type === "retailer" && negotiation.brand_status === "WAITING_FOR_RETAILER_FINAL_ACCEPTANCE" && negotiation.retailer_status === "FINAL_ACCEPTANCE_PENDING") {
          setAcceptNegotiationButton(false);
        }
        
        dispatch(setChatMessages(negotiation.messages));
    }
    }

    socket.on('New neg message', fetchData)

    return () => {
      socket.off('New neg message', fetchData);
    };
  }, [id])

  return (
    <>
      <div style={{ height: '100%' }}>
        <PageTitle title='Negociación' />
        <NavBar />

        <div style={{ display: 'flex', height: 'calc(100% - 98px)' }}>
          <Sidebar page={"Negotiations_List"} header={"Operación"} content={menu} />

          <div style={{ 'overflowY': 'auto', backgroundColor: '#FFFFFF', width: '100%' }}>
            <div className={styles.negotiation_container}>
              <div className={styles.headerContainer}>
                <HeaderNegotiation page="2" />
              </div>
              <div className={styles.main}>
                <div className={styles.form}>
                  <Container className={`d-flex align-item justify-content-center aligns-items-center mt-0 ${styles.custom_form}`}>
                    <Form onSubmit={handleSubmit} noValidate validated={validated} className={`${styles.in_form} form_box _login`}>
                      <div className={`fields _login ${styles.fields}`}>
                        <Form.Group className={styles.form_group} controlId="validationCustom01">
                          <Form.Label className="label _login" >Budget</Form.Label>
                          <div className={styles.form_control}>
                            <div >
                              <Form.Control
                                type="number"
                                name="budget"
                                value={form?.budget || ""}

                                onChange={handleChange}
                                required
                              />
                            </div>
                            <div className={`${styles.date_ghost} budget d-none`}>{freezeForm?.budget}</div>
                          </div>
                          <Form.Control.Feedback type="invalid">
                            Por favor introduzca el presupuesto
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className={styles.form_group} controlId="validationCustom02">
                          <Form.Label className="label _login">Start Date</Form.Label>
                          <div className={styles.form_control}>
                            <div >
                              <Form.Control className="to_date"
                                type="date"
                                name="start_date"
                                value={form?.start_date || ""}
                                onChange={handleChange}
                                // onClick={changeInputType}
                                required
                              ></Form.Control>
                            </div>
                            <div className={`${styles.date_ghost} start_date d-none`}>{freezeForm?.start_date}</div>
                          </div>
                          <Form.Control.Feedback type="invalid">
                            Introduzca la fecha de inicio
                          </Form.Control.Feedback>
                          {!isValidStartDate ? <div className={styles.invalid} styles={{ display: 'block' }}>La fecha de inicio no puede ser anterior a la actual</div> : ""}
                        </Form.Group>
                        <Form.Group className={styles.form_group} controlId="validationCustom03">
                          <Form.Label className="label _login">End Date</Form.Label>
                          <div className={styles.form_control}>
                            <div>
                              <Form.Control className="to_date"
                                type="date"
                                name="end_date"
                                value={form?.end_date || ""}
                                onChange={handleChange}
                                // onClick={changeInputType}
                                required
                              />
                            </div>
                            <div className={`${styles.date_ghost} end_date d-none`}>{freezeForm?.end_date}</div>
                          </div>
                          <Form.Control.Feedback type="invalid">
                            Introduzca la fecha de finalización
                          </Form.Control.Feedback>
                          {!isValidEndDate ? <div className={styles.invalid} styles={{ display: 'block' }}>La fecha de fin no puede ser anterior a la actual</div> : ""}
                        </Form.Group>
                        <Form.Group className={styles.form_group} controlId="validationCustom05">
                          <Form.Label className="label _login" >Quantity</Form.Label>
                          <div className={styles.form_control}>
                            <div >
                              <Form.Control
                                type="number"
                                name="quantity"
                                value={form?.quantity || ""}
                                onChange={handleChange}

                                required
                              />
                            </div>
                            <div className={`${styles.date_ghost} quantity d-none`}>{freezeForm?.quantity}</div>
                          </div>
                          <Form.Control.Feedback type="invalid">
                            Por favor introduzca la cantidad
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className={styles.form_group} controlId="validationCustom06">
                          <Form.Label className="label _login" >Rate (CPM)</Form.Label>
                          <div className={styles.form_control}>
                            <div >
                              <Form.Control
                                type="number"
                                name="rate"
                                value={form?.rate || ""}
                                onChange={handleChange}

                                required
                              />
                            </div>
                            <div className={`${styles.date_ghost} rate d-none`}>{freezeForm?.rate}</div>
                          </div>
                          <Form.Control.Feedback type="invalid">
                            Por favor introduzca el ratio
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className={styles.form_group} controlId="validationCustom07">
                          <Form.Label className="label _login" >Inventario</Form.Label>
                          <div className={styles.form_control}>
                            <div className={styles.custom_select} >
                              <Select
                                name="inventory"
                                value={defaultInventories}
                                onChange={handleMultiSelectChange}
                                options={inventorySelector}
                                isMulti
                                isClearable
                                placeholder="Seleccione"
                                required
                              />
                            </div>
                            <div className={`${styles.date_ghost} inventory d-none`}>{freezeForm?.inventory}</div>
                          </div>
                          {validatedMulti === true ? <div className={styles.invalid} styles={{ display: 'block' }}>Por favor seleccione una opción</div> : ""}
                        </Form.Group>
                        <Form.Group className={styles.form_group} controlId="validationCustom11" >
                          <Form.Label className="label _login">Creatives</Form.Label>
                          <div className={styles.form_control}>
                            <div className={styles.custom_select} >
                              <Select
                                isMulti
                                isClearable
                                onChange={handleMultiSelectCreative}
                                // 
                                name="creatives"
                                options={sizesSelector}
                                placeholder="Seleccione"
                                required
                                value={defaultSizes}
                                styles={{
                                  control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    borderColor: validatedMultiCr === true ? '#dc3545' : '#ced4da',
                                  }),
                                }}
                              />
                            </div>
                            <div className={`${styles.date_ghost} creatives d-none`}>{freezeForm?.creative_sizes}</div>
                          </div>
                          {validatedMultiCr === true ? <div className={styles.invalid} styles={{ display: 'block' }}>Por favor seleccione una opción (requiere inventario)</div> : ""}
                        </Form.Group>
                        <Form.Group className={`${styles.form_group} ${styles.t_area_group}`} controlId="validationCustom08">
                          <Form.Label className="label _login">Mensaje</Form.Label>
                          <Form.Control as="textarea" rows={5}
                            name="message"
                            value={form?.message || ""}
                            className={styles.t_area}
                            onChange={handleChange}
                            required
                          />
                          <Form.Control.Feedback type="invalid">
                            Por favor introduzca un mensaje
                          </Form.Control.Feedback>
                          <Button
                            type="submit"
                            style={{ cursor: 'pointer' }}
                            className={`${styles.send} button _login`}
                          >
                            <img src={sendMessage} alt="Enviar" />
                          </Button>
                        </Form.Group>
                      </div>
                    </Form>
                  </Container>
                </div>
                <Chat loading={loading}
                  negotiation={negotiation}
                  updateNegotiation={updateNegotiation}
                  setUpdateNegotiation={setUpdateNegotiation}
                />
              </div>
              <div className={styles.footer}>
                <Button onClick={acceptDeal} disabled={acceptNegotiationButton} className={`grey_button ${styles.button}`} variant={acceptNegotiationButton ? "secondary" : "primary"}>Aceptar acuerdo</Button>
                <Button onClick={cancelNegotiation} className={`red_button ${styles.button}`} variant="danger">Cancelar negociación</Button>{' '}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Negotiation