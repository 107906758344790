import NavBar from "../../../components/NavBar/NavBar";
import Sidebar from "../../../components/SideBar/SideBar"
import { ConfigurationSideBarRetailer, ConfigurationSideBarAdvertiser } from "../../../config/sidebar_types";
import Form from 'react-bootstrap/Form';
import dataText from "config/DataText";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getAccountOrRetailer } from "services/services";
import GoogleSpinner from "components/GoogleSpinner/GoogleSpinner";

const FDataPage = () => {
  const user = useSelector(state => state.authUser);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const menu = user.type === "advertiser" ? ConfigurationSideBarAdvertiser : ConfigurationSideBarRetailer;


  const fetchList = async () => {
    setLoading(true)
    const type = user.type === "retailer"
      ? 'retailer'
      : user.type === "advertiser"
        ? 'account'
        : null;

    const id = user.activeProfile.account_id ? user.activeProfile.account_id : user.activeProfile.retailer_id;
    try {
      const response = await getAccountOrRetailer(type, id)
      setData(response.data)
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchList();
  }, [/* not set user here because it makes an infinite loop */])
  return (
    <>
      {!loading ? (
        <div style={{ height: '100%' }}>
          <NavBar />

          <div style={{ display: 'flex', height: 'calc(100% - 98px)' }}>
            <Sidebar page={"Subscription_FData"} header={"Configuración"} content={menu} />

            <div style={{ 'overflowY': 'auto', backgroundColor: '#FFFFFF', width: '100%' }}>
              <div className='container-fluid' style={{ padding: "3.5rem 3.5rem" }}>
                <div className="row">
                  <div className="col-5">
                    <Form
                    >
                      <h4>{dataText.datos_fiscales}</h4>
                      <Form.Group className="mb-3">
                        <Form.Label>{user.type === "advertiser" ? "Nombre cuenta" : "Nombre retailer"}</Form.Label>
                        <Form.Control type="text" value={data?.name} disabled />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>Nombre persona de contacto</Form.Label>
                        <Form.Control type="text" value={data?.contact_person} disabled />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>E-mail</Form.Label>
                        <Form.Control type="email" value={data?.email} disabled />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>Teléfono de contacto</Form.Label>
                        <Form.Control type="number" value={data?.phone} disabled />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>CIF</Form.Label>
                        <Form.Control type="text" value={data?.cif} disabled />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>Dirección</Form.Label>
                        <Form.Control type="text" value={data?.address} disabled />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>Ciudad</Form.Label>
                        <Form.Control type="text" value={data?.city} disabled />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>CP</Form.Label>
                        <Form.Control type="text" value={data?.postal_code} disabled />
                      </Form.Group>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>


        </div>
      ) : (
        <GoogleSpinner />
      )}
    </>

  )
}

export default FDataPage