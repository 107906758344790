import React, { useState } from 'react'
import NavBar from "../../../components/NavBar/NavBar";
import Sidebar from "../../../components/SideBar/SideBar"
import { ConfigurationSideBarRetailer, ConfigurationSideBarAdvertiser } from "../../../config/sidebar_types";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Image from "react-bootstrap/Image";
import dataText from "config/DataText";
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { patchBrand, postBrand } from 'services/services';
import styles from "./newpage.module.css";
import { getBrand } from 'services/services';
import { useEffect } from 'react';
import { Spinner } from 'react-bootstrap';

const NewPage = () => {
  const [img, setImg] = useState('')
  const { id } = useParams();
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  

  const user = useSelector(state => state.authUser);

  const intialFormState = {
    name: "",
    contact_person: "",
    account_id: user.activeProfile.account_id,
    logo: "",
    cif: "",
    email: "",
    phone: "",
    address: "",
    city: "",
    postal_code: "",
    country: ""
  }
  const [form, setForm] = useState(intialFormState);

  const [validated, setValidated] = useState(false);


  const uploadFileHanlder = (e) => {
    if(e.target.files.length === 0 || e.target.files[0].type === ""){
      setImg("")
      setForm(prevState => ({
        ...prevState,
        ["logo"]: ""
      }))
      e.target.value = null
      return
    }
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      const image_result = reader.result
      setImg(image_result);
      setForm(prevState => ({
        ...prevState,
        ["logo"]: image_result
      }))
    });
    reader.readAsDataURL(e.target.files[0]);
  }
  const handleChange = e => {
    setForm(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value
    }))
  };

  const handleSubmit = async e => {
    e.preventDefault();
    const form_val = e.currentTarget;

    if (!form_val.checkValidity()) {
      e.stopPropagation();
      Swal.fire({
        text: "Hay campos incorrectos!",
        icon: "error",
        allowEscapeKey: true,
        allowOutsideClick: true,
        backdrop: false,
        showClass: {
            popup: 'animated fadeInDown'
        },
        hideClass: {
            popup: 'animated fadeOutUp',
        },
        backdrop: true
      }).then(() => {
        window.close();
      });
      setValidated(true)
    }
    else {
      if(id){
        patchBrand(id, form).then(response => {
          navigate(`/configuracion/marcas/lista`)
          }
        ).catch(err => {
          console.log(err);
        })
      }
      else{
        postBrand(form).then(response => {
          navigate(`/configuracion/marcas/lista`)
          }
        ).catch(err => {
          console.log(err);
        })
      }
    }
  }
  const fetchData = async e => {
    setLoading(true)
    try {
      const { data } = await getBrand(id);
      setImg(data.logo)
      const brand = {
          name: data.name,
          contact_person: data.contact_person,
          account_id: form.account_id,
          logo: data.logo,
          cif: data.cif,
          email: data.email,
          phone: data.phone,
          address: data.address,
          city: data.city,
          postal_code: data.postal_code === null ? "" : data.postal_code,
          country: data.country,
      }
      setForm(brand)
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    if(id){
      fetchData();
    }
  }, [user])

  const menu = user.type === "advertiser" ? ConfigurationSideBarAdvertiser : ConfigurationSideBarRetailer
  return (
    <div style={{ height: '100%' }}>
      <NavBar />

      <div style={{ display: 'flex', height: 'calc(100% - 98px)' }}>
        <Sidebar page={"Brands_New"} header={"Configuración"} content={menu} />


        <div style={{ 'overflowY': 'auto', backgroundColor: '#FFFFFF', width: '100%' }}>
          <div className='container-fluid' style={{ padding: "3.5rem 3.5rem" }}>
            <div className="row">
              <div className="col-6">
                <Form onSubmit={handleSubmit} noValidate validated={validated}
                >
                  <h4>{id ? dataText.edit_brand : dataText.new_brand}</h4>
                  {loading && (
                    <div className="d-flex justify-content-center">
                      <Spinner animation="border" style={{ color: "#222e73" }} />
                    </div>
                  )}
                  <Form.Group className="mb-3" >
                    <Form.Label>{dataText.brand_name}</Form.Label>
                    <Form.Control
                      type="text"
                      required
                      name="name"
                      value={form.name}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" >
                    <Form.Label>CIF</Form.Label>
                    <Form.Control
                      type="text"
                      required
                      name="cif"
                      value={form.cif}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group controlId="formFile" className="mb-3" >
                    <Form.Label>{dataText.upload_logo}</Form.Label>
                    <Form.Control
                      type="file"
                      onChange={uploadFileHanlder}
                      required={form.logo === ""}
                      accept=".png, .jpeg"
                    />
                    {img !== "" ? <Image className={styles.img_file} style={{marginTop: '0.25rem'}}src={img} roundedCircle /> : ""}
                  </Form.Group>
                  <Form.Group className="mb-3" >
                    <Form.Label>{dataText.responsable}</Form.Label>
                    <Form.Control
                      type="text"
                      required
                      name="contact_person"
                      value={form.contact_person}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" >
                    <Form.Label>Email del responsable</Form.Label>
                    <Form.Control
                      type="email"
                      required
                      name="email"
                      value={form.email}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" >
                    <Form.Label>Número de teléfono</Form.Label>
                    <Form.Control
                      type="text"
                      required
                      name="phone"
                      value={form.phone}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" >
                    <Form.Label>País</Form.Label>
                    <Form.Control
                      type="text"
                      required
                      name="country"
                      value={form.country}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" >
                    <Form.Label>Ciudad</Form.Label>
                    <Form.Control
                      type="text"
                      required
                      name="city"
                      value={form.city}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" >
                    <Form.Label>Código Postal</Form.Label>
                    <Form.Control
                      type="text"
                      required
                      name="postal_code"
                      value={form.postal_code}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" >
                    <Form.Label>Dirección</Form.Label>
                    <Form.Control
                      type="text"
                      required
                      name="address"
                      value={form.address}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <div className="d-flex justify-content-between">
                    <span></span>
                    <Button style={{ backgroundColor: "#001834", color: "white" }} type="submit">
                      {id ? dataText.guardar : dataText.new_brand}
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>


    </div>
  )
}

export default NewPage