import React from 'react'
import Available from './Available'
import Pending from './Pending'
import Connected from './Connected'
import Swal from "sweetalert2";
import { deleteConnection, getConnections, patchConnections, postConnections } from "../../services/services";
import "./table.css";
import { useSelector } from 'react-redux';
const Table = ({ userType, items, setItems, userId }) => {
    const user = useSelector(state => state.authUser);
    const accountId = user.activeProfile.account_id;

    //
    const reqType = user.type === "retailer"
        ? 'retailer'
        : user.type === "advertiser"
            ? 'account'
            : null;

    const accOrProId = user.activeProfile.account_id ? user.activeProfile.account_id : user.activeProfile.retailer_id;
    //

    // TODO plantear si dividir en 3 arrays para que se ordenen en cada fila por orden de llegada
    /*
        const updateStatus=(id, newStatus)=>{
            let allItems= items;
            let element_updated = {}
            allItems.forEach(element => {
                if(element.id===id){
                    element_updated = element
                }
            });
            element_updated.status=newStatus
            setItems((oldArray) => oldArray.filter(element => element.id !== id).concat(element_updated))
        }
    */
    const updateStatus = (id, newStatus, oldStatus, retId) => {
        let title_text = ""
        let text_text = ""
        let confButton_text = ""
        if (userType === "advertiser") {
            switch (newStatus) {
                case 'AVAILABLE':
                    if (oldStatus === 'PENDING') {
                        title_text = "Cancelar solicitud"
                        text_text = "Usted va a eliminar la solicitud de conexión con el Retailer. ¿Está seguro de querer eliminarla?"
                        confButton_text = "Eliminar"
                    }
                    else if (oldStatus === 'APPROVED') {
                        title_text = "Eliminar conexión"
                        text_text = "Usted va a eliminar la conexión existente con el Retailer. ¿Está seguro de querer eliminarla?"
                        confButton_text = "Eliminar"
                    }
                    break;
                case 'PENDING':
                    if (oldStatus === 'AVAILABLE') {
                        title_text = "Solicitar conexión"
                        text_text = "Su solicitud de conexión le llegará al retailer y la deberá aceptar en un periodo de 2 días.\n" + " Se le notificacá cuando se haya aceptado"
                        confButton_text = "Solicitar"
                    }
                    break;

            }

        }
        else {
            switch (newStatus) {
                case 'REJECTED':
                    if (oldStatus === 'PENDING') {
                        title_text = "Denegar solicitud"
                        text_text = "Usted va denegar la solicitud de conexión de la marca. ¿Está seguro de querer denegarla?"
                        confButton_text = "Degenar"
                    }
                    break;
                case 'AVAILABLE':
                    if (oldStatus === 'APPROVED') {
                        title_text = "Eliminar conexión"
                        text_text = "Usted va a eliminar la conexión existente con la marca. ¿Está seguro de querer eliminarla?"
                        confButton_text = "Eliminar"
                    }
                    if (oldStatus === 'PENDING') {
                        title_text = "Denegar solicitud"
                        text_text = "Usted va denegar la solicitud de conexión de la marca. ¿Está seguro de querer denegarla?"
                        confButton_text = "Degenar"
                    }
                    break;
                case 'APPROVED':
                    if (oldStatus === 'PENDING') {
                        title_text = "Aceptar conexión"
                        text_text = "Usted va a aceptar la conexión de la marca. ¿Está usted seguro?"
                        confButton_text = "Aceptar"
                    }
                    break;

            }
        }
        Swal.fire({
            title: title_text,
            text: text_text,
            icon: "",
            showCancelButton: true,
            allowEscapeKey: true,
            allowOutsideClick: false,

            cancelButtonColor: "#d20631",
            confirmButtonText: confButton_text,
            cancelButtonText: "Cancelar",
            showClass: {
                popup: 'animated fadeInDown'
            },
            hideClass: {
                popup: 'animated fadeOutUp',
            },
            backdrop: true
        }).then((result) => {
            if (result.isConfirmed) {
                if (newStatus === 'AVAILABLE') {
                    deleteConnection(id).then((response) => {
                        getConnections(reqType, accOrProId).then((connections) => {
                            let connections_data = connections.data
                            setItems(connections_data)
                        }).catch(error => {
                            console.log(error)
                        })
                    }).catch(error => {
                        console.log(error)
                    })
                }
                else if (newStatus === 'PENDING') {
                    // advertiser
                    let body = { "retailer_id": retId, "account_id": accountId, "status": newStatus }
                    postConnections(body).then(response => {
                        getConnections(reqType, accOrProId).then((connections) => {
                            let connections_data = connections.data
                            setItems(connections_data)
                        }).catch(error => {
                            console.log(error)
                        })
                    })
                } else {
                    patchConnections(id, newStatus).then((response) => {
                        getConnections(reqType, accOrProId).then((connections) => {
                            let connections_data = connections.data
                            setItems(connections_data)
                        }).catch(error => {
                            console.log(error)
                        })
                    }).catch(error => {
                        console.log(error)
                    })
                }
            }
            else {
                return
            }
        }).catch(error => {
            console.log(error)
        })

    }

    // TODO Modal sweet alert y button
    return (

        <div className={userType === "advertiser" ? "items" : "items retailer"}>
            {userType === "advertiser" ? <Available items={items} updateStatus={updateStatus} userType={userType} /> : <></>}
            <Pending items={items} updateStatus={updateStatus} userType={userType} />
            <Connected items={items} updateStatus={updateStatus} userType={userType} />
        </div>
    )
}

export default Table