import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import "./table.css";
import Pagination from "../Pagination/Pagination";
import Spinner from "react-bootstrap/Spinner";
import { ArrowDown, ArrowUp } from "react-bootstrap-icons";
import { RxCross1, RxArrowRight } from "react-icons/rx";
import dataText from "../../config/DataText";
import { useNavigate } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import { useDispatch, useSelector } from "react-redux";
import { setPaginationCurrentPage } from "state/appSlice";

function descendingComparator(a, b, orderBy) {
  const identificateA =
    typeof a[orderBy] === "string" ? a[orderBy].toLowerCase() : a[orderBy];
  const identificateB =
    typeof b[orderBy] === "string" ? b[orderBy].toLowerCase() : b[orderBy];

  if (identificateB < identificateA) {
    return -1;
  }
  if (identificateB > identificateA) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export default function TableList({
  rowHeader,
  rowsData,
  loading,
  handleEdit,
  perPage = 10,
  handleDelete = () => { },
  handleCheckbox = () => { },
  handleCheckedAll = () => { },
  fetchList = () => { },
  defaultOrderBy = "name",
}) {
  const [pagesrrr, setPage] = useState(1);
  const page = useSelector(state => state.paginationCurrentPage);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState(defaultOrderBy);

  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [modalData, setModalData] = useState(null);
  const dispatch = useDispatch();

  const handleShow = (id) => {
    setModalData(rowsData.find((data) => data.id === id));
  };

  const handleClose = () => {
    setModalData(null);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    setPage(1);
  };

  const progress_barStyle = (x) => {
    return { height: "25px", background: `linear-gradient(90deg, #2D8617 0%, #2D8617 ${x[0]}%, #E7C347 ${x[0]}%, #E7C347 ${x[0] + x[1]}%,#E07171 ${x[0] + x[1]}%,#E07171 100%)` }
  }

  useEffect(() => {

    if (modalData) setShow(true);
    else setShow(false);
    // rowsData.map((obj, index) => {
    //   return [
    //     (rowsData[index].created = new Date(obj.created)
    //       .toString()
    //       .slice(4, 24)),
    //     (rowsData[index].updated = new Date(obj.updated)
    //       .toString()
    //       .slice(4, 24)),
    //   ];
    // });

    // if (page !== 1) setPage(1);
    // if (page !== 1) dispatch(setPaginationCurrentPage(1))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalData, rowsData]);

  const max = rowsData.length / perPage;
  return (
    <>
      {loading && (
        <div className="d-flex justify-content-center">
          <Spinner animation="border" style={{ color: "#222e73" }} />
        </div>
      )}

      <div className="table_document text-center">
        <Table hover responsive>
          <thead>
            <tr>
              {rowHeader.map((col, idx) => (
                <th className={col.class} key={`${col.key}-${idx}`}>
                  {col.header}
                  {order === "asc" ? (
                    <ArrowUp
                      className={col.key !== orderBy ? "sort_arrow" : ""}
                      onClick={() => handleRequestSort(col.key)}
                    />
                  ) : (
                    <ArrowDown
                      className={col.key !== orderBy ? "sort_arrow" : ""}
                      onClick={() => handleRequestSort(col.key)}
                    />
                  )}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {rowsData
              .sort(getComparator(order, orderBy))
              .slice((page - 1) * perPage, (page - 1) * perPage + perPage)
              .map((row, idx) => (
                <tr key={`${row.name}-${idx}`}>
                  {rowHeader.map((col, index) => (
                    <React.Fragment key={index + 1000}>
                      {col.type === "text" && (
                        <td
                          style={{ width: col.width, padding: "14px" }}
                          className={col.class}
                        >
                          {row[col.key]}
                        </td>
                      )}
                      {col.type === "number" && (
                        <td style={{ width: col.width, padding: "14px" }} className={col.class}>
                          {row[col.key]}
                        </td>
                      )}
                      {col.type === "checkbox" && (
                        <td style={{ width: col.width, padding: "14px" }} className={col.class}>
                          <input
                            type="checkbox"
                            id={`${row[col.key]}`}
                            checked={row.checked}
                            onChange={handleCheckbox}
                          />
                        </td>
                      )}
                      {col.type === "progress" && (
                        <td style={{ width: col.width, padding: "14px" }} className={col.class}>
                          <div style={progress_barStyle(row.progress_bar)}>
                          </div>
                        </td>
                      )}
                      {col.type === "actions" && (
                        <td>
                          <div className="d-flex justify-content-center">
                            {col.actions.includes("delete") && (
                              // <Button 
                              //   className="btn btn-primary-outline" 
                              //   variant="light"

                              //   >
                              <RxCross1 size={'1.5rem'} className="mr-2" onClick={() => handleDelete(row.id)} />
                              // </Button>             
                            )}
                            {col.actions.includes("edit") && (
                              // <Button 
                              //   className="btn btn-primary-outline" 
                              //   variant="light"

                              //     >
                              <RxArrowRight size={'1.5rem'} className="mr-2" onClick={() => handleEdit(row.id)} />
                              // </Button>
                            )}
                          </div>
                        </td>
                      )}
                    </React.Fragment>
                  ))}
                </tr>
              ))}
          </tbody>
        </Table>
        <Pagination
          setPage={setPage}
          max={max}
        />
      </div>
      {/* <ModalWindow
        show={show}
        form={newPipelineForm}
        validation={newPipelineValidation}
        handleClose={handleClose}
        title={dataText.clone}
        submit={clonePipeline}
        data={modalData}
      /> */}
    </>
  );
}