import React from 'react'
import dataText from "../../config/DataText";
import { HiChevronDoubleRight } from 'react-icons/hi'
const Available = ({ items, updateStatus, userType }) => {

    return (
        <div className="available">
            <div className="header_container">
                <p className="header">{dataText.disponibles}</p>
            </div>
            {

                items && items.map(item => {
                    if (item && (item.status === 'AVAILABLE'))                                         // TODO update with retailer name or account name
                        return (
                            <div key={userType === "advertiser" ? item.retailer_id : item.account_id} className="item_container"><p className="item" >{userType === "advertiser" ? item.retailer_name : item.account_name}</p><button className="move" onClick={() => { updateStatus(item.id, 'PENDING', item.status, item.retailer_id) }}>{<HiChevronDoubleRight />}</button>
                            </div>
                        )
                })
            }

        </div>
    )
}
export default Available