import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setChatNegotiationFormData, setUpdateNotifications } from "state/appSlice";
import check1 from "../../assets/images/check1.png";
import check2 from "../../assets/images/check2.png";
import reload1 from "../../assets/images/reload1.png";
import reload2 from "../../assets/images/reload2.png";
import styles from "./message.module.css";
import { formatDateForNotificationsPage } from "utils/utils";
import { patchVersion } from "services/services";

const Message = ({ message, withButtons, updateNegotiation, setUpdateNegotiation }) => {
  const buttonsInitialState =
    (message?.version.status === "APPROVED") ? [[check2, true], [reload1, true]] : [[check1, false], [reload1, false]]
  const user = useSelector((state) => state.authUser);
  const dispatch = useDispatch();
  const [acceptProposalButton, setAcceptProposalButton] = useState(buttonsInitialState[0]);
  const [newProposalButton, setNewProposalButton] = useState(buttonsInitialState[1]);

  const acceptProposal = async () => {
    setAcceptProposalButton([check2, true]);
    setNewProposalButton([reload1, true]);

    try {
      await patchVersion(message.negotiation_id, message.version.version, "APPROVED")
      setUpdateNegotiation(!updateNegotiation)
    } catch (error) {
      console.log(error)
    }
  }

  const newProposal = () => {
    const newMsg = {
      budget: message?.version.budget,
      start_date: (message?.version.start_date).slice(0, 10),
      end_date: (message?.version.end_date).slice(0, 10),
      creative_sizes: message?.version.creative_sizes,
      quantity: message?.version.quantity,
      rate: message?.version.rate,
      // inventory_name: message?.version.inventory[0].inventory_name,
      inventory: message?.version.inventory,
      message: "",
    }
    dispatch(setChatNegotiationFormData(newMsg));
    setNewProposalButton([reload2, true]);
    setAcceptProposalButton([check1, true]);
  }

  return (
    <div className={
      (message?.type).toUpperCase() !== ("SYSTEM")
        ?
        ((message?.origin)?.toLowerCase()) !== (user?.type) ? `${styles.msg_container} ${styles.not_mine}` : `${styles.msg_container}`
        : `${styles.msg_container} ${styles.msg_container_system}`
    }>
      {(message?.type).toUpperCase() === "SYSTEM" ? (
        <div className={styles.tag}>
          <span>{message?.message}</span>
        </div>
      ) : (
        <div className={
          (message?.type).toUpperCase() === "PROPOSAL"
            ? ((message?.origin)?.toLowerCase()) !== (user.type) ? `${styles.msg_container_main} ${styles.not_mine}` : `${styles.msg_container_main}`
            : ((message?.origin)?.toLowerCase()) !== (user.type) ? `${styles.msg_container_main} ${styles.msg_container_main_2} ${styles.not_mine}` : `${styles.msg_container_main} ${styles.msg_container_main_2}`
        }>
          <div className={styles.msg_text}>
            <p>{message?.message}</p>
            <p>{message?.firstname} {' '} {message?.lastname} {'   '}{formatDateForNotificationsPage(message?.created_date)}</p>
          </div>
          {(message?.type).toUpperCase() !== "MESSAGE" && (
            <div className={styles.fields}>
              <div className={styles.fields_row}>
                <div className={styles.field}>
                  Budget: {message?.version.budget} €
                </div>
                <div className={styles.field}>
                  Volume (Qty): {message?.version.quantity} impr
                </div>
                <div className={styles.field}>
                  Fechas: {(message?.version.start_date).slice(0, 10)} {'>'} {(message?.version.end_date).slice(0, 10)}
                </div>
              </div>
              <div className={styles.fields_row}>
                <div className={styles.field}>
                  Rate (CPM): {message?.version.rate} €
                </div>
                <div className={styles.field}>
                  {/* Creatives: {JSON.stringify(Array.isArray(message?.version.creative_sizes) )} */}
                  Creatives: {message?.version?.creative_sizes?.map((x, i) =>
                    (i !== message?.version.creative_sizes.length - 1)
                      ? `${x}, `
                      : x
                  ) || ''}
                </div>
                <div className={styles.field}>
                  Placement: {message?.version?.inventory?.map((x, i) =>
                    (i !== message?.version.inventory.length - 1)
                      ? `${x.inventory_name}, `
                      : x.inventory_name
                  ) || ''}
                </div>
              </div>
            </div>
          )

          }
        </div>
      )

      }

      <div className={styles.buttons_container}>
        {((message?.type).toUpperCase() === "PROPOSAL" && message?.id === withButtons?.id && (message?.origin)?.toLowerCase() !== user.type) && (
          <>
            <button onClick={acceptProposal} disabled={acceptProposalButton[1]}><img src={acceptProposalButton[0]} alt="Aceptar" /></button>
            <button onClick={newProposal} disabled={newProposalButton[1]}><img src={newProposalButton[0]} alt="Contrapropuesta" /></button>
          </>
        )

        }
      </div>
    </div>
  )
}

export default Message