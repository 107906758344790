import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
// import authReducer from './state/index';
import appReducer from './state/appSlice';
import { configureStore } from '@reduxjs/toolkit';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { PersistGate } from 'redux-persist/integration/react';
import { store } from "./state/store";
import { HelmetProvider } from 'react-helmet-async';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistStore(store)}>
      <HelmetProvider>
        <App />
      </HelmetProvider>
    </PersistGate>
  </Provider>
  // </React.StrictMode>
);

