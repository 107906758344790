export const dateForChatHeader = (date) => {
  const year = date.substring(2, 4);
  const month = date.substring(5, 7);
  const day = date.substring(8, 10);

  return (month + day + year)
}

export const formatDate = (date) => {
  const year = date.slice(0, 4);
  const month = date.slice(5, 7);
  const day = date.slice(8, 10);
  const hour = date.slice(11, 13);
  const minute = date.slice(14, 16);
  const second = date.slice(17, 19);

  return `${day}-${month}-${year} ${hour}:${minute}:${second}`;
}

export const validateStartDate = (value) => {
  let dateValue = value.replace("-", "")
  dateValue = dateValue.replace("-", "")

  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();
  const today = (String(year) + String(("0" + String(month + 1)).slice(-2)) + String(("0" + String(day)).slice(-2)))

  if (parseInt(dateValue) < parseInt(today)) {
    return false
  } else {
    return true
  }
}

export const formatDateForNotificationsPage = (date) => {
  const year = date.slice(0, 4);
  const month = date.slice(5, 7);
  const day = date.slice(8, 10);
  const minutes = date.slice(11, 13);
  const seconds = date.slice(14, 16);
  const result = `${day}-${month}-${year} ${minutes}:${seconds}`;
  return result;
}

export const formatDateForNegotiationFinalConfigurationPage = (date) => {
  const year = date.slice(0, 4);
  const month = date.slice(5, 7);
  const day = date.slice(8, 10);
  const result = `${day}-${month}-${year}`;
  return result;
}

export const verifySuperUserRole = (user) => (/^su$/i).test(user?.roleName) ? true : false;