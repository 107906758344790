import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./sidebar.css";
import { useNavigate } from 'react-router-dom';
import ShowIcon from "./Showicon";
import HideIcon from "./Hideicon";
import DropdownIcon from "./Dropdownicon"
import Dropdown from "./Dropdown";
import HideupIcon from "./Hideupicon"
import { useSelector, useDispatch } from "react-redux";
import { setSidebarMenu } from "state/appSlice";

const SideBar = ({ page, header, content }) => {
  const dispatch = useDispatch()

  const [isNotActive, setNotActive] = useState(false);

  let intialMenuOpen = {}
  // TODO Check if redux store items equals content. If equals charge it. If not content map and store in redux
  const sidebar_content = useSelector(state => state.sidebarMenu);
  
  content.map((menu) => (
    intialMenuOpen[menu.title] = page.split("_")[0] === menu.title ? true : false
  ))
  var aKeys = Object.keys(sidebar_content).sort();
  var bKeys = Object.keys(intialMenuOpen).sort();
  if( JSON.stringify(aKeys) === JSON.stringify(bKeys)){
    intialMenuOpen = sidebar_content
  }
  else{
    dispatch(setSidebarMenu(intialMenuOpen))
  }
  const [menuOpen, setMenuOpen] = useState(intialMenuOpen)
  let navigate = useNavigate();
  /*
  const [retailersOpen, setRetailersOpen] = useState(page === 'Connections' ? true : false);
  
  const [negociacionesOpen, setNegociacionesOpen] = useState((page === 'List' || page === 'Create') ? true : false);
  const [propuestasOpen, setPropuestasOpen] = useState(false);
  const [tagsOpen, setTagsOpen] = useState(false);
 
  
  const handleOpenRetailers = () => {
    setRetailersOpen(!retailersOpen);
  };
  const handleOpenNegociaciones = () => {
    setNegociacionesOpen(!negociacionesOpen);
  };
  const handlePropuestas = () => {
    setPropuestasOpen(!propuestasOpen);
  };
  const handleTags = () => {
    setTagsOpen(!tagsOpen);
  };
*/
  const handleOpen = (title, value) => {
    let newMenuOpen = {
      ...menuOpen,
      [title]: !value
    }
    setMenuOpen(prevState => ({
      ...prevState,
      [title]: !value
    }))
    // TODO update redux
    dispatch(setSidebarMenu(newMenuOpen))
  }


  return (

    <div className="wrapper" >

      <nav id="sidebar" className={isNotActive ? "nonactive" : ""}>

        <div className="sidebar-header">
          <h3 className="sidebar-header-text">{header}</h3>
        </div>
        <hr className="sidebar-separator" style={{ margin: 0 }}></hr>
        <div className="sidebar-menu-container">
          <div className="sidebar-menu">
            {
              content.map((menu) => (
                <Dropdown key={menu.title}
                  open={menuOpen[menu.title]}
                  trigger={
                    <div className="sidebar-menu-item" onClick={() => handleOpen(menu.title, menuOpen[menu.title])}><p className="sidebar-menu-item-text" >{menu.title_text}</p><div style={{ 'float': 'right' }}> {!menuOpen[menu.title] ? <DropdownIcon /> : <HideupIcon />}</div></div>
                  }
                  menu={menu.items.map((item) => ([
                    <p key={item.text} className="sidebar-submenu-item" style={{ textDecoration: item.page === page ? 'underline' : 'none' }} onClick={() => navigate(`${item.navigation}`)}>{item.text}</p>,
                  ]))}
                />
              ))
            }
            {/* 
            <Dropdown
              open={retailersOpen}
              trigger={
                <div className="sidebar-menu-item" onClick={handleOpenRetailers}><p className="sidebar-menu-item-text" >Retailers</p><div style={{ 'float': 'right'}}> {!retailersOpen ? <DropdownIcon/> : <HideupIcon/>}</div></div>
              }
              menu={[
                <p className="sidebar-submenu-item" style={{textDecoration: page === "Connections" ? 'underline' : 'none' }} onClick={() => navigate('/operacion/retailers/conexiones')}>Conexiones</p>,
              ]}
            
            <Dropdown
              open={negociacionesOpen}
              trigger={
                <div className="sidebar-menu-item" onClick={handleOpenNegociaciones}><p className="sidebar-menu-item-text" >Negociaciones</p><div style={{ 'float': 'right'}}>{!negociacionesOpen ? <DropdownIcon/> : <HideupIcon/>}</div></div>
              }
              menu={[
                <p className="sidebar-submenu-item" style={{textDecoration: page === "List" ? 'underline' : 'none' }} onClick={() => navigate('/operacion/negociaciones/lista')}>Lista</p>,
                <p className="sidebar-submenu-item" style={{textDecoration: page === "Create" ? 'underline' : 'none' }} onClick={() => navigate('/operacion/negociaciones/crear')}>Crear</p>,
              ]}
            />
            <Dropdown
              open={propuestasOpen}
              trigger={
                <div className="sidebar-menu-item" onClick={handlePropuestas} ><p className="sidebar-menu-item-text" >Propuestas</p><div style={{ 'float': 'right'}}>{!propuestasOpen ? <DropdownIcon/> : <HideupIcon/>}</div></div>
              }
              menu={[]}
            />
            <Dropdown
              open={tagsOpen}
              trigger={
                <div className="sidebar-menu-item" onClick={handleTags}><p className="sidebar-menu-item-text" onClick={handleTags}>Tags</p><div style={{ 'float': 'right'}}>{!tagsOpen ? <DropdownIcon/> : <HideupIcon/>}</div></div>
              }
              menu={[]}
            />
            */}
          </div>
        </div>
        <hr className="sidebar-separator bottom" style={{ margin: 0 }}></hr>



        <button
          type="button"
          id="sidebarCollapse"
          onClick={() => setNotActive(!isNotActive)}
          className="btn btn-custom"
        >
          <span className={isNotActive ? '' : 'hidden'} >{<ShowIcon />}</span>
          <span className={isNotActive ? 'hidden' : ''}>{<HideIcon />}</span>
        </button>
      </nav>
    </div>

  );
};
export default SideBar;