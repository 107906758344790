import { useEffect, useState, version } from "react";
import { useSelector } from "react-redux";
import styles from "./messages.module.css";
import Message from "../Message/Message";

const Messages = ({ updateNegotiation, setUpdateNegotiation, loading }) => {
  const user = useSelector((state) => state.authUser);
  const newMessages = useSelector((state) => state.chatMessages);
  const [withButtons, setWithButtons] = useState({ id: 0, activateButtons: false });
  const [orderedMessages, setOrderedMessages] = useState(newMessages);
  const [updateScroll, setUpdateScroll] = useState(true);

  useEffect(() => {
    const orderedMessages = newMessages?.length > 0 ? [...newMessages] : []
    orderedMessages.sort((a, b) => a.id - b.id)
    setOrderedMessages(orderedMessages)
    // setOrderedMessages([...orderedMessages, ...orderedMessages]) // duplicate messages for test scroll
    const proposals = orderedMessages.filter(x => (x.type).toUpperCase() === "PROPOSAL" && x.origin !== user.type);
    const allProposals = orderedMessages.filter(x => (x.type).toUpperCase() === "PROPOSAL");

    //
    if (proposals.length > 0 && allProposals.length > 0) {
      const activateButtons = proposals[proposals.length - 1].id === allProposals[allProposals.length - 1].id ? true : false;
      const elem2 = { ...proposals[proposals.length - 1] }
      setWithButtons({ id: elem2.id, activateButtons });
    }

    setUpdateScroll(!updateScroll)
  }, [newMessages])

  useEffect(() => {
    // scroll to top for messages
    const elem = document.querySelector('#messages-container');
    if (elem) {
      elem.scrollTop = elem.scrollHeight;
    }
  }, [updateScroll, loading])

  return (
    <div id="messages-container" className={`${styles.messages_container}`}>
      {!loading && (
        <>
          {orderedMessages?.map((message) => (
            <Message key={message.id} message={message} withButtons={withButtons} updateNegotiation={updateNegotiation} setUpdateNegotiation={setUpdateNegotiation} />
          ))}
        </>
      )

      }
    </div>
  )
}

export default Messages