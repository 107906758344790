import { useState, useEffect } from "react";
import styles from './negotiation_create.module.css'
import { Button, Form, Row, Col, Dropdown } from 'react-bootstrap';
import Select from 'react-select'
import { useNavigate } from "react-router-dom";
import { getAllBrands, getBrandsByAccount, getExternalConnections, getInventory } from "../../../services/services";
import { useSelector } from 'react-redux'
import { postNegotiation } from "services/services";
import { getConnections } from "../../../services/services";
import { validateStartDate } from "utils/utils";
import Swal from "sweetalert2";

const CreateNegotiation = () => {
  let navigate = useNavigate();
  const user = useSelector((state) => state.authUser);
  const [isValidStartDate, setIsValidStartDate] = useState(true);
  const [isValidEndDate, setIsValidEndDate] = useState(true);

  const initialForm = {
    brand_title: "",
    retailer_title: "",
    brand_internal_notes: "",
    retailer_internal_notes: "",
    retailer_id: (user.type === "retailer" ? user.activeProfile.retailer_id : 0),
    brand_id: (user.type === "advertiser" ? user.activeProfile.brand_id : 0),
    url: "",
    tags: [
    ],
    version: {
      status: "PENDING",
      start_date: "",
      end_date: "",
      rate: 0,
      quantity: 0,
      budget: 0,
      creative_sizes: "",
      inventory: [
      ]
    }
  }

  const [brands, setBrands] = useState([])
  const [retailers, setRetailers] = useState([])
  const [creatives, setCreatives] = useState([])
  const [inventorySelector, setInventorySelector] = useState([])
  const [form, setForm] = useState(initialForm);
  const [validated, setValidated] = useState(false);
  const [validatedMulti, setValidatedMulti] = useState(false);
  const [validatedMultiCr, setValidatedMultiCr] = useState(false);
  const [selectedRetailer, setSelectedRetailer] = useState(false);
  const [selectedInventory, setSelectedInventory] = useState(false);
  const [defaultSizes, setDefaultSizes] = useState([]);

  const handleChange = e => {
    let value = e.target.value
    let index = e.target.name

    if (index.includes("version")) {
      index = index.split(".")[1]
      let newVersion = {}

      if (index === "rate" || index === "quantity") {
        value = parseInt(value);
        newVersion = { ...form.version, [index]: value }

        setForm(prevState => ({
          ...prevState,
          version: newVersion
        }))
      }
      if (index === "budget") {
        value = parseFloat(value);
        newVersion = { ...form.version, [index]: value }

        setForm(prevState => ({
          ...prevState,
          version: newVersion
        }))
      }

      if (index === "start_date" || index === "end_date") {
        const validated = validateStartDate(value);

        if (!validated) {
          (index === "start_date") ? setIsValidStartDate(false) : setIsValidEndDate(false);

        } else {
          newVersion = { ...form.version, [index]: value }

          setForm(prevState => ({
            ...prevState,
            version: newVersion
          }))
          if (index === "start_date") {
            setIsValidStartDate(true)
          } else {
            setIsValidEndDate(true);
          }
        }
      }
    }
    else {
      if (index === "retailer_id" || index === "brand_id") {

        value = parseInt(value)
      }
      setForm(prevState => ({
        ...prevState,
        [index]: value
      }))
      if (index === "retailer_id") {
        setSelectedRetailer(true)
      }
    }
  };

  const handleMultiSelectChange = (selectedOption) => {
    let selected = []
    let sizes = []
    selectedOption.forEach(element => {
      selected.push({ id: element.value, inventory_name: element.label })
      element.sizes.forEach(size => {
        const elems = size.split(",");
        elems.forEach(elem => sizes.push({ "value": elem, "label": elem }))
      })
    });
    setForm(prevState => ({
      ...prevState,
      version: { ...prevState.version, inventory: selected }
    }))
    setValidatedMulti(false)
    setSelectedInventory((selected.length > 0))

    if ((selected.length > 0)) {
      setCreatives(sizes)
    }
    else {
      setDefaultSizes([])
      setCreatives([])
    }
  }

  const handleMultiSelectCreative = (selectedOption) => {
    (selectedOption.length > 0) ? setDefaultSizes(selectedOption) : setDefaultSizes([])

    let selected = []
    selectedOption.forEach(element => {
      selected.push(element.label)
    });
    setForm(prevState => ({
      ...prevState,
      version: { ...prevState.version, creative_sizes: selected }
    }))
    setValidatedMultiCr(false)
  }

  const handleSubmit = async e => {
    e.preventDefault();
    const form_val = e.currentTarget;

    if (!isValidStartDate || !isValidEndDate || !form_val.checkValidity() || form.version.inventory.length === 0 || form.version.creative_sizes.length === 0) {
      e.stopPropagation();
      Swal.fire({
        text: "Hay campos incorrectos!",
        icon: "error",
        allowEscapeKey: true,
        allowOutsideClick: true,
        backdrop: true
      }).then(() => {
        window.close();
      });

      if (form.version.inventory.length === 0) {
        setValidatedMulti(true)
      }
      if (form.version.creative_sizes.length === 0) {
        setValidatedMultiCr(true)
      }
      setValidated(true)
    }
    else {
      postNegotiation(form).then(response => {
        navigate(`/operacion/negociaciones/chat/${response.data.id}`)
      }).catch(err => {
        Swal.fire({
          text: "Se ha producido un error interno. Por favor vuelva a intentarlo más tarde.",
          icon: "error",
          allowEscapeKey: true,
          allowOutsideClick: true,
          backdrop: true,
          showClass: {
            popup: 'animated fadeInDown'
          },
          hideClass: {
            popup: 'animated fadeOutUp',
          },
        })
      })
    }
  }

  const fetchData = async () => {
    const type = user.type === "retailer"
      ? 'retailer'
      : user.type === "advertiser"
        ? 'account'
        : null;

    const id = user.activeProfile.account_id ? user.activeProfile.account_id : user.activeProfile.retailer_id;

    const response = await getConnections(type, id);
    const conns = response.data.filter(x => x.status === "APPROVED")

    if (user.type === "retailer") {
      let brands = [];
      for (let i = 0; i < conns.length; i++) {
        const brandsResponse = await getBrandsByAccount(conns[i].account_id);
        brands = [...brands, ...brandsResponse.data]
      }
      setBrands(brands)
    } else {
      setRetailers(conns)
    }
  }

  const fetchInventory = async (retailerId) => {
    if (selectedRetailer === true || user.type === "retailer") {
      const response = await getInventory(retailerId);
      const inventorySelectorData = response.data.map(x => {
        return {
          "value": x.id,
          "label": x.alias ? x.alias : x.external_name,
          "sizes": x.sizes
        }
      })
      setInventorySelector(inventorySelectorData)
    }
  }

  useEffect(() => {
    try {
      fetchData();
    } catch (error) {
      Swal.fire({
        text: "Se ha producido un error interno. Por favor vuelva a intentarlo más tarde.",
        icon: "error",
        allowEscapeKey: true,
        allowOutsideClick: true,
        backdrop: true,
        showClass: {
          popup: 'animated fadeInDown'
        },
        hideClass: {
          popup: 'animated fadeOutUp',
        },
      })
    }
  }, [user])

  useEffect(() => {
    try {
      if (user.type === "retailer") {
        fetchInventory(user.activeProfile.retailer_id)
      } else {
        fetchInventory(form.retailer_id)
      }
    } catch (error) {
      Swal.fire({
        text: "Se ha producido un error interno. Por favor vuelva a intentarlo más tarde.",
        icon: "error",
        allowEscapeKey: true,
        allowOutsideClick: true,
        backdrop: true,
        showClass: {
          popup: 'animated fadeInDown'
        },
        hideClass: {
          popup: 'animated fadeOutUp',
        },
      })
    }
  }, [selectedRetailer])

  return (

    <>
      <h1 className={styles.header_create}>Nueva negociación</h1>
      <Form className={styles.form} onSubmit={handleSubmit} noValidate validated={validated}>
        <Row className="mb-3 mr-3">
          <Col className={styles.item} md={6}>
            <Form.Group controlId="validationCustom01">
              <Form.Label className={styles.label_create} >RFP Name</Form.Label>
              <Form.Control
                type="text"
                name={user.type === "advertiser" ? "brand_title" : "retailer_title"}
                onChange={handleChange}
                required
              />
              <Form.Control.Feedback type="invalid">
                Por favor introduzca un valor
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col className={styles.item_small} md={3} >

            <Form.Group controlId="validationCustom02">
              <Form.Label className={styles.label_create} >Start Date</Form.Label>
              <Form.Control
                type="date"
                name="version.start_date"
                onChange={handleChange}
                required
              />
              <Form.Control.Feedback type="invalid">
                Por favor introduzca una fecha
              </Form.Control.Feedback>
              {!isValidStartDate ? <div className={styles.invalid} styles={{ display: 'block' }}>La fecha de inicio no puede ser anterior a la actual</div> : ""}
            </Form.Group>
          </Col>
          <Col className={styles.item_small} md={3} >
            <Form.Group controlId="validationCustom03">
              <Form.Label className={styles.label_create} >End Date</Form.Label>
              <Form.Control
                type="date"
                name="version.end_date"
                onChange={handleChange}
                required
              />
              <Form.Control.Feedback type="invalid">
                Por favor introduzca una fecha
              </Form.Control.Feedback>
              {!isValidEndDate ? <div className={styles.invalid} styles={{ display: 'block' }}>La fecha de fin no puede ser anterior a la actual</div> : ""}
            </Form.Group>
          </Col>

        </Row>
        <Row className="mb-3 mr-3">
          <Col className={styles.item} md={6}>

            <>
              <Form.Group controlId="validationCustom04">
                <Form.Label className={styles.label_create} >{user.type === "advertiser" ? "Retailer" : "Advertiser (Brand)"}</Form.Label>
                <Form.Select aria-label="Default select example"
                  name={user.type === "advertiser" ? "retailer_id" : "brand_id"}
                  onChange={handleChange}
                  required
                >
                  <option value="" selected disabled hidden></option>
                  {user.type === "advertiser" ?
                    retailers.map((e, key) => {
                      return <option key={key} value={e.retailer_id}>{e.retailer_name}</option>;
                    })
                    : brands.map((e, key) => {
                      return <option key={key} value={e.id}>{e.name}</option>;
                    })}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  Por favor seleccione una opción
                </Form.Control.Feedback>
              </Form.Group>
            </>

          </Col>

          <Col className={styles.item} md={6}>
            <Form.Group controlId="validationCustom05" >
              <Form.Label className={styles.label_create}>Inventario</Form.Label>
              <Select
                isDisabled={user.type === "advertiser" && !selectedRetailer}
                isMulti
                isClearable
                onChange={handleMultiSelectChange}
                name="inventory"
                options={inventorySelector}
                placeholder=""
                required
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: validatedMulti === true ? '#dc3545' : '#ced4da',
                  }),
                }}
              />
              {validatedMulti === true ? <div className={styles.invalid} styles={{ display: 'block' }}>Por favor seleccione una opción</div> : ""}
            </Form.Group>
          </Col>

        </Row>
        <Row className="mb-3 mr-3">
          <Col className={styles.item} md={6}>

          </Col>
        </Row>
        <Row className="mb-3 mr-3">
          <Col className={styles.item} md={6}>
          </Col>
          <Col className={styles.item_small} md={3}>
            <Form.Group controlId="validationCustom07">
              <Form.Label className={styles.label_create} >Budget</Form.Label>
              <Form.Control
                type="text"
                name="version.budget"
                onChange={handleChange}
                required
              />
              <Form.Control.Feedback type="invalid">
                Por favor introduzca un valor
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col className={styles.item_small} md={3}>
            <Form.Group controlId="validationCustom08">
              <Form.Label className={styles.label_create} >Rate(CPM - opcional)</Form.Label>
              <Form.Control
                type="number"
                name="version.rate"
                onChange={handleChange}

              />
              <Form.Control.Feedback type="invalid">
                Por favor introduzca un valor
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

        </Row>
        <Row className="mb-3 mr-3">
          {/*Tags*/}
          <Col className={styles.item} md={6}>

          </Col>
          <Col className={styles.item_small} md={3}>
            <Form.Group controlId="validationCustom10">
              <Form.Label className={styles.label_create} >Quantity</Form.Label>
              <Form.Control
                type="number"
                name="version.quantity"
                onChange={handleChange}
                required
              />
              <Form.Control.Feedback type="invalid">
                Por favor introduzca un valor
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col className={styles.item_small} md={3}>
            <Form.Group controlId="validationCustom11" >
              <Form.Label className={styles.label_create}>Creatives</Form.Label>
              <Select
                isDisabled={!selectedInventory}
                isMulti
                isClearable
                onChange={handleMultiSelectCreative}
                name="creatives"
                options={creatives}
                placeholder=""
                required
                value={defaultSizes}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: validatedMultiCr === true ? '#dc3545' : '#ced4da',
                  }),
                }}
              />
              {validatedMultiCr === true ? <div className={styles.invalid} styles={{ display: 'block' }}>Por favor seleccione una opción (requiere inventario)</div> : ""}
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3 mr-3">
          <Col md={6}></Col>
          <Col className={styles.notes} md={6}>
            <Form.Group controlId="validationCustom12">
              <Form.Label className={styles.label_create} >Notas internas (opcional)</Form.Label>
              <textarea
                className="form-control"
                rows="6"
                name={user.type === "advertiser" ? "brand_internal_notes" : "retailer_internal_notes"}
                onChange={handleChange}></textarea>
            </Form.Group>
          </Col>
        </Row>
        <div className={styles.buttons}>
          <Button
            type="submit"
            className={styles.button_start}
          >Comenzar</Button>
        </div>
      </Form>



    </>


  )
}

export default CreateNegotiation