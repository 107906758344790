import NavBar from 'components/NavBar/NavBar';
import { ConfigurationSideBarRetailer, ConfigurationSideBarAdvertiser } from 'config/sidebar_types';
import { Button, Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import Sidebar from "../../../components/SideBar/SideBar"
import { useEffect, useState } from 'react';
import Spinner from "react-bootstrap/Spinner";
import { formatDate } from 'utils/utils';
import { getExternalConnections, patchExternalConnections } from 'services/services';
import { setShowNotificationsAlert } from 'state/appSlice';

const EditConnection = () => {
  const { id } = useParams();
  const user = useSelector(state => state.authUser);
  const [loading, setLoading] = useState(false);
  const menu = user.type === "advertiser" ? ConfigurationSideBarAdvertiser : ConfigurationSideBarRetailer
  const navigate = useNavigate();
  const { profiles, type } = useSelector(state => state.authUser);
  const [account, setAccount] = useState({})
  let options = [];

  profiles.forEach(elem => {
    const item = {
      value: elem.id,
      label: elem.name
    }

    options = [...options, item]
  });

  const handleSubmit = async e => {
    e.preventDefault();
    const ext_conn_id = account.ext_conn_id
    const body = [{active: account.active}]
    patchExternalConnections(ext_conn_id, body).then(response => {
      navigate('/configuracion/conexiones/lista')
    }).catch(err => {
      console.log(err);
    })
  };
  const fetchData = async e => {
    setLoading(true)
    const type = user.type === "retailer"
      ? 'retailer'
      : user.type === "advertiser"
        ? 'account'
        : null;
    const user_id = user.activeProfile.account_id ? user.activeProfile.account_id : user.activeProfile.retailer_id;
    try {
      const { data } = await getExternalConnections(type, user_id)
      const data_adapted = data.map(x => {
        const connection = {
          ext_conn_id: x.id,
          platform: x.platform_name,
          account_id: type === "retailer" ? x.retailer_id : x.account_id,
          name: type === "retailer" ? x.retailer_name : x.account_name,
          created_date: x.created_date ? formatDate(x.created_date) : "",
          active: x.active
        };
        return connection
      })
      setAccount(data_adapted[0])
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    fetchData();
  }, [user])

  return (
    <div style={{ height: '100%' }}>
      <NavBar />

      <div style={{ display: 'flex', height: 'calc(100% - 98px)' }}>
        <Sidebar page={"Connections_List"} header={"Configuración"} content={menu} />


        <div style={{ 'overflowY': 'auto', backgroundColor: '#FFFFFF', width: '100%' }}>
          <div className='container-fluid' style={{ padding: "3.5rem 3.5rem" }}>
            <div className="row">
              <div className="col-3">
                <Form
                  onSubmit={handleSubmit}
                >
                  <h4>Conexión: </h4>
                  {loading && (
                    <div className="d-flex justify-content-center">
                      <Spinner animation="border" style={{ color: "#222e73" }} />
                    </div>
                  )}
                  <Form.Group className="mb-3">
                    <Form.Label>Pataforma</Form.Label>
                    <Form.Control type="text" disabled placeholder={account.platform} />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Account Name</Form.Label>
                    <Form.Control type="text" disabled placeholder={account.name} />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Account ID</Form.Label>
                    <Form.Control type="text" disabled placeholder={account.account_id} />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Fecha de conexión</Form.Label>
                    <Form.Control type="text" disabled placeholder={account.created_date} />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Habilitar / Deshabilitar</Form.Label>
                    <Form.Check
                      type="checkbox"
                      name="enable"
                      label="Esto habilitará la conexión en esta plataforma"
                      checked={account.active}
                      onChange={()=>setAccount( (prevState) => (
                        {
                        ...prevState,
                        active: (!account.active)
                        }))
                      }
                    />
                  </Form.Group>


                  <div className="d-flex justify-content-between">
                    <span></span>
                    <Button style={{ backgroundColor: "#001834", color: "white" }} type="submit">
                      Actualizar conexión
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EditConnection