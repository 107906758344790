import { ConfigurationSideBarRetailer, ConfigurationSideBarAdvertiser } from "../../../config/sidebar_types";
import React, { useState, useEffect } from "react";
import TableList from "components/Table/TableList";
import NavBar from "../../../components/NavBar/NavBar";
import Sidebar from "../../../components/SideBar/SideBar"
import dataText from "../../../config/DataText";
import Search from "../../../components/Search/Search";
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';
import Swal from "sweetalert2";
import { getInventory } from "services/services";
import { useSelector } from "react-redux";
import { postInventory } from "services/services";


const ListPage = () => {
  const [listData, setListData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [loading, setLoading] = useState(false);
  const user = useSelector(state => state.authUser);

  let navigate = useNavigate();

  const menu = user.type === "advertiser" ? ConfigurationSideBarAdvertiser : ConfigurationSideBarRetailer
  const fieldsToSearch = ["placement_id", "placement_name", "alias", "active"];

  const rowHeader = [
    {
      header: dataText.placement_id,
      key: "placement_id",
      type: "text",
    },
    {
      header: dataText.placement_name,
      key: "placement_name",
      type: "text",
    },
    {
      header: dataText.placement_alias,
      key: "alias",
      type: "text",
    },
    {
      header: dataText.enable_disabled,
      key: "active",
      type: "text",
    },
    {
      header: "",
      key: "actions",
      type: "actions",
      actions: ["edit"],
    },
  ];

  const loadPlacements = async () => {
    console.log("loading...")
    setLoading(true)
    postInventory(user.activeProfile.retailer_id).then((response) => {
      fetchList()
    }).catch(err => {
      console.log(err);
    })

  }

  const fetchList = async () => {
    setLoading(true)
    try {

      const response = await getInventory(user.activeProfile.retailer_id);
      const data = response.data.map(x => {
        const inventory = {
          id: x.id,
          placement_id: x.external_id,
          placement_name: x.external_name,
          alias: x.alias,
          active: x.active ? "Habilitada" : "Deshabilitada",
        }

        return inventory
      })

      setListData(data);
      setAllData(data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchList();
  }, [user])

  const handleEdit = (id) => {
    navigate(`/configuracion/inventario/lista/placement/${id}`);
  };

  const handleDelete = (id) => {
    Swal.fire({
      title: "Eliminar grupo",
      text: "Esto eliminará el grupo, no los usaurios. Tendrá que definir especificamente los roles para cada usuario",
      icon: "",
      showCancelButton: true,
      allowEscapeKey: true,
      allowOutsideClick: true,

      cancelButtonColor: "#d20631",
      backdrop: true
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: `Usuario ${id} borrado`,
          text: "Your data has been deleted.",
          icon: "success",
        });
      } else {
        return
      }
    });
  };

  return (
    <div style={{ height: '100%' }}>
      <NavBar />

      <div style={{ display: 'flex', height: 'calc(100% - 98px)' }}>
        <Sidebar page={"Inventory_List"} header={"Configuración"} content={menu} />


        <div style={{ 'overflowY': 'auto', backgroundColor: '#FFFFFF', width: '100%' }}>
          <div className="new_element">
            <div className="d-flex justify-content-between">
              <Search
                allData={allData}
                setListData={setListData}
                fieldsToSearch={fieldsToSearch}
              />
              <Button onClick={loadPlacements} style={{ background: "#001834", color: "white" }}>{dataText.cargar_placements}</Button>
            </div>
          </div>
          <TableList
            loading={loading}
            rowsData={listData}
            rowHeader={rowHeader}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
            fetchList={fetchList}
          />
        </div>
      </div>


    </div>
  )
}

export default ListPage