import NavBar from "../../../components/NavBar/NavBar";
import Sidebar from "../../../components/SideBar/SideBar"
import CreateForm from "./CreateForm"
import HeaderNegotiation from "./HeaderConfNeg";
import { OperationsSideBar } from "../../../config/sidebar_types";
import { useSelector } from "react-redux";


const CreatePage = () => {
  const menu = OperationsSideBar
  const user = useSelector((state) => state.authUser);
  return (
    <div style={{ height: '100%' }}>
      <NavBar />

      <div style={{ display: 'flex', height: 'calc(100% - 98px)' }}>
        <Sidebar page={"Negotiations_Create"} header={"Operación"} content={menu} />

        <div style={{ 'overflowY': 'auto', backgroundColor: '#FFFFFF', width: '100%' }}>
          <div style={{ 'overflowY': 'auto', backgroundColor: '#FFFFFF', width: '100%' }}>
            <div style={{ 'display': 'flex', 'flexDirection': 'column', 'padding': '3rem 14rem 1rem 5.5rem', 'minHeight': '200px' }}>
              <HeaderNegotiation page="1" />

              <CreateForm />

            </div>
          </div>
        </div>
      </div>


    </div>
  )
}

export default CreatePage
