import { useDispatch, useSelector } from "react-redux";
import styles from "./notificationsFrame.module.css";
import { getNotifications, patchNotification } from "services/services";
import { setShowNotificationsAlert, setUpdateNotifications } from "state/appSlice";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { socket } from "services/socket"
const AlertsFrame = ({ toggleRefresh, setShowTriangle1 }) => {
  const dispatch = useDispatch();
  const user = (useSelector(state => state.authUser));
  // if (initialFrameNotifications.length >= 5) initialFrameNotifications.length = 5;
  const [frameNotifications, setFrameNotifications] = useState([]);
  const [loading, setLoading] = useState(false);
  const updateNotifications = useSelector(state => state.updateNotifications);

  const setTriangles = (notifications = []) => {
    if (notifications.some(x => x.read === false)) {
      dispatch(setShowNotificationsAlert(true));
      setShowTriangle1(true);
    } else {
      dispatch(setShowNotificationsAlert(false));
      setShowTriangle1(false);
    }
  }

  const handleCheck = async (e, notification) => {
    const elem = e.target;
    elem.classList.toggle(`${styles.checked}`)

    try {
      await patchNotification(user.id, [
        {
          notification_id: notification.id,
          read: true
        }
      ]);

      const notifications = await getNotifications(user.id);

      setTriangles(notifications.data)
      dispatch(setUpdateNotifications(!updateNotifications))
    } catch (error) {
      console.log(error);
      elem.classList.toggle(`${styles.checked}`)
    }
  }

  const fetchNotifications = async () => {
    setLoading(true)
    try {
      const response = await getNotifications(user.id, "?read=false");
      const nonRead = response.data.filter(x => x.read === false);

      if (nonRead.length >= 5) nonRead.length = 5;

      setFrameNotifications(nonRead)
      setTriangles(response.data)

      if (response.data.some(x => x.read === false)) {
        dispatch(setShowNotificationsAlert({ show: true, length: response.data.filter(x => x.read === false).length }));
      }

    } catch (error) {
      console.log(error);
      // TODO display warning
      return [];
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchNotifications();

  }, [updateNotifications]);

  return (
    <div className={styles.container1}>
      {!!loading ? (
        <div className={`${styles.row} ${styles.row_loading}`}>
          <Spinner/>
        </div>
      ) : (
        <>
          {frameNotifications.length > 0 && (
            <div className={styles.container}>
              {frameNotifications.map(x => (
                <div key={x.id} className={styles.row}>
                  <div className={styles.left_check}>
                    <div className={styles.check} onClick={(e) => handleCheck(e, x)}></div>
                  </div>
                  <div>
                    {x.message}&nbsp;&nbsp;&nbsp;&nbsp;<Link onClick={(e) => handleCheck(e, x)} className={styles.link} to={x.url}>Ir</Link>
                  </div>
                </div>
              ))

              }
            </div>
          )
          }
        </>
      )

      }
    </div>
  )
}

export default AlertsFrame